import React, { useEffect, useRef, useCallback } from 'react'
import { Placeholder } from '@sitecore-jss/sitecore-jss-react'
import { useInView } from 'react-intersection-observer'

import withMinimumRequirements from '../../../withMinimumRequirements'

import {
  log,
  efo,
  getModulePosition,
  pushGlEvent,
  getDataSourceName,
} from '../../../helpers'

import './styles.scss'

const ArticleBodyDownloadPanel = (props) => {
  const { rendering } = props
  const ref = useRef()
  const [inViewRefTrack, inViewTrack] = useInView({
    threshold: 0.3,
  })

  const setRefs = useCallback(
    (node) => {
      ref.current = node
      inViewRefTrack(node)
    },
    [inViewRefTrack]
  )

  useEffect(() => {
    log('Article Body Download Panel', { ...rendering })
  }, [rendering])

  // Tracking load
  useEffect(() => {
    if (typeof window !== 'undefined' && window.GDL) {
      pushGlEvent(
        false,
        'DownloadPanel',
        [],
        'DownloadPanel',
        getModulePosition('article-body-download-panel-container', ref),
        'DownloadPanel',
        efo(props, 'rendering.uid'),
        'DownloadPanel'
      )
    }
  }, [props])

  // Tracking in view
  useEffect(() => {
    if (inViewTrack && typeof window !== 'undefined' && window.GDL) {
      pushGlEvent(
        true,
        'DownloadPanel',
        [],
        'DownloadPanel',
        getModulePosition('article-body-download-panel-container', ref),
        'DownloadPanel',
        efo(props, 'rendering.uid'),
        'DownloadPanel'
      )
    }
  }, [inViewTrack, props])

  return (
    <div
      className="article-body-download-panel-container"
      id={getDataSourceName(efo(props, 'rendering.dataSource'))}
      ref={setRefs}
    >
      <Placeholder
        name="jss-motorsport-article-body-download-panel-items"
        rendering={rendering}
      />
    </div>
  )
}

export default withMinimumRequirements(ArticleBodyDownloadPanel, [
  'rendering.placeholders.jss-motorsport-article-body-download-panel-items.0',
])
