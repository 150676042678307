import React, { useEffect, useState } from 'react'

import fetch from 'isomorphic-unfetch'

import Image from '../Image'
import Video from '../Video'
import Icon from '../Icon'

import { efo, getConfig } from '../../../helpers'

import './styles.scss'

const getType = (post) => {
  let type = efo(post, 'content_items.0.feed_type')

  switch (type) {
    case 'instagram-user':
      type = 'Instagram'
      break
    default:
  }

  return type
}

const getImage = (post) =>
  efo(post, 'content_items.0.images.0')
    ? {
        value: {
          ...efo(post, 'content_items.0.images.0.sizes.original'),
          src: efo(post, 'content_items.0.images.0.original'),
        },
      }
    : undefined

const getVideo = (post) => efo(post, 'content_items.0.videos.0.url')

const getText = (post) =>
  (efo(post, 'content_items.0.body') || '').replace(
    /(<a [^>]+>pic.twitter.com[^<]+<\/a>)/gm,
    ''
  )

const getIcon = (post) => {
  let name = 'share'

  switch (getType(post)) {
    case 'Twitter':
      name = 'logoTwitter'
      break
    default:
  }

  return name
}

const getName = (post) => {
  let name

  switch (getType(post)) {
    default:
      name = getType(post)
      break
  }

  return name
}

const getDate = (post) =>
  new Date(efo(post, 'content_items.0.publish_date'))
    .toLocaleDateString(undefined, {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    })
    .replace(/\//gm, '.')

const getUser = (post) => `@${efo(post, 'content_items.0.source')}`

const getURL = (options) => {
  const { id, post, token } = options

  let url = getConfig('website.storyStreamEndpoint')

  if (post) {
    url = getConfig('website.storyStreamPostEndpoint').replace(
      '%POST_ID%',
      post
    )
  }

  return url.replace('%STORY%', id).replace('%ACCESS_TOKEN%', token)
}

const SocialMedia = (props) => {
  const { title, subtitle, storyStream, number = 3 } = props

  const [allPosts, setAllPosts] = useState(undefined)
  const [viewPosts, setViewPosts] = useState(undefined)

  useEffect(() => {
    if (allPosts === undefined) {
      fetch(getURL(storyStream), {
        method: 'GET',
      })
        .then((response) => response.json())
        .then((result) => {
          const posts = result.blocks

          setAllPosts(storyStream.post ? [posts[0]] : posts)
          setViewPosts(storyStream.post ? [posts[0]] : posts.slice(0, number))
        })
        .catch(() => {
          setAllPosts([])
          setViewPosts([])
        })
    }
  }, [allPosts, storyStream, number])

  return (
    <div className="general-social-media-container">
      {title || subtitle ? (
        <div className="general-social-media-header-container">
          {title ? (
            <span className="general-social-media-header-title">{title}</span>
          ) : undefined}
          {subtitle ? (
            <span className="general-social-media-header-subtitle">
              {subtitle}
            </span>
          ) : undefined}
        </div>
      ) : undefined}
      {viewPosts === undefined ? (
        <div className="general-social-media-lazy-image">
          <svg width="160" height="90" />
        </div>
      ) : (
        <div className="general-social-media-posts-container">
          {viewPosts.map((post, i) => (
            <div
              key={i}
              className="general-social-media-post-container"
              data-type={getType(post)}
            >
              <div className="general-social-media-post-indicator" />
              <div className="general-social-media-post-subcontainer">
                {getVideo(post) ? (
                  <Video
                    UC_READY={props.UC_READY}
                    UC_PROCESSOR={props.UC_PROCESSOR}
                    url={getVideo(post)}
                    image={efo(getImage(post), 'value')}
                  />
                ) : getImage(post) ? (
                  <Image fields={{ image: getImage(post) }} />
                ) : undefined}
                <div className="general-social-media-post-data-container">
                  <span
                    className="general-social-media-post-data-body"
                    dangerouslySetInnerHTML={{ __html: getText(post) }}
                  />
                  <div className="general-social-media-post-data-subcontainer">
                    <Icon name={getIcon(post)} />
                    <span>{getName(post)}</span>
                    <span>{getDate(post)}</span>
                    <span>{getUser(post)}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      <div
        className="general-social-media-load-more-container"
        data-show={
          allPosts &&
          viewPosts &&
          allPosts.length &&
          viewPosts.length &&
          allPosts.length !== viewPosts.length
            ? ''
            : undefined
        }
      >
        <div
          className="general-social-media-load-more-subcontainer"
          onClick={() =>
            setViewPosts(allPosts.slice(0, viewPosts.length + number - 1))
          }
        >
          <Icon name="refresh" size={30} />
        </div>
      </div>
    </div>
  )
}

export default SocialMedia
