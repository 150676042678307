import { isEditorActive } from '@sitecore-jss/sitecore-jss'
import {
  DEFAULT_ID,
  LOCALSTORAGE_COUNTRY_KEY,
  BREAKPOINT_MOBILE,
  BREAKPOINT_PORTRAIT,
  LOGGER_ALLOWED_SITES,
  TEASER_ASPECT_RATIO,
} from './constants'
import langData from './LanguageData/data.json'
import config from './temp/config'

export const getConfig = (key) => efo(config, key)

export const getKey = (id, index) => {
  if (id && id !== DEFAULT_ID) {
    return id
  }
  return index
}

export const efo = (obj, path, fallback) => {
  if (obj && path) {
    const keys = path.split('.')
    const key = keys[0]

    if (keys.length === 1) {
      return obj[key]
    }
    return efo(obj[key], path.substring(path.indexOf('.') + 1)) || fallback
  }
}

export const getDefaultCountry = (countryList) => {
  return countryList
    ? countryList.find((c) => c.isDefaultCountry.value)
    : undefined
}

export const setActiveCountry = (country) => {
  if (typeof window !== 'undefined') {
    const localStorage = efo(window, 'localStorage')
    if (localStorage) {
      try {
        localStorage.setItem(LOCALSTORAGE_COUNTRY_KEY, JSON.stringify(country))
      } catch (error) {
        // Nothing to do here
      }
    }
  }
}

export const isFullURLStructure = () => {
  if (typeof window !== 'undefined') {
    return (efo(window, 'location.pathname') || '/').startsWith('/motorsport/')
  }

  return null
}

export const getActiveCountry = (countryList) => {
  const isProd = !!getConfig('website.isProdIntQa')
  const environment = getConfig('website.pagDataEnviroment')

  if (countryList) {
    const def = getDefaultCountry(countryList)

    if (typeof window !== 'undefined') {
      const localStorage = efo(window, 'localStorage')
      const pathname = efo(window, 'location.pathname') || '/'
      const pathnameRegex = /^[/motorsport]*\/([a-z]+\/[a-z]{2})/gm
      let routePath

      if (pathname) {
        const [, data] = pathnameRegex.exec(pathname) || []

        if (data) {
          routePath = data
        }
      }

      if (localStorage) {
        let localCountry = {}

        try {
          localCountry = JSON.parse(
            localStorage.getItem(LOCALSTORAGE_COUNTRY_KEY)
          )
        } catch (error) {}

        if (localCountry && localCountry.id) {
          const selectedCountry = countryList.find(
            (c) => c.id === localCountry.id
          )

          if (selectedCountry) {
            if (!routePath) {
              const finalRoutePath = efo(
                selectedCountry,
                'targetSite.fields.sitePath.value'
              )

              if (finalRoutePath && !isEditorActive()) {
                // PROD
                if (isProd && environment === 'P') {
                  window.location.href = `${finalRoutePath.replace(
                    /^\/motorsport/gm,
                    ''
                  )}${efo(window, 'location.pathname')}`
                }
                // QA & INT
                if (isProd && environment === 'PP') {
                  window.location.href = `${finalRoutePath.replace(
                    /^\/motorsport/gm,
                    ''
                  )}${efo(window, 'location.pathname')}`
                }
                // DEV & TEST & LOCAL
                if (!isProd && environment === 'K') {
                  window.location.href = `${finalRoutePath}`
                }
              }
            } else {
              const possibleRouterCountries = countryList.filter((c) =>
                efo(c, 'targetSite.fields.sitePath.value').endsWith(routePath)
              )
              const routerCountry =
                possibleRouterCountries.length > 1
                  ? possibleRouterCountries.find((c) =>
                      efo(c, 'isDefaultCountry.value')
                    )
                  : undefined || possibleRouterCountries[0]
              const selectedCountryPath = efo(
                selectedCountry,
                'targetSite.fields.sitePath.value'
              )

              if (
                routerCountry &&
                selectedCountry &&
                efo(routerCountry, 'targetSite.fields.sitePath.value') !==
                  selectedCountryPath
              ) {
                setActiveCountry(routerCountry)
                return routerCountry
              } else if (
                !routerCountry &&
                selectedCountry &&
                selectedCountryPath
              ) {
                setActiveCountry(routerCountry)

                const targetURLData = /^[/motorsport]*\/[a-z]+\/[a-z]{2}(\/[\w\W]+)/gm.exec(
                  window.location.pathname
                )
                const targetURL = targetURLData && targetURLData[1]

                window.location.href = `${
                  isProd
                    ? selectedCountryPath.replace(/^\/motorsport/gm, '')
                    : selectedCountryPath
                }${targetURL || ''}`
              }
            }

            return selectedCountry
          }
        }
      }

      if (routePath) {
        if (routePath.includes('international/en')) {
          return (
            countryList.find((c) => efo(c, 'isDefaultCountry.value')) ||
            countryList.find((c) =>
              efo(c, 'targetSite.fields.sitePath.value').endsWith(routePath)
            )
          )
        }
        return countryList.find((c) =>
          efo(c, 'targetSite.fields.sitePath.value').endsWith(routePath)
        )
      } else {
        const browserLanguage = navigator.language || navigator.userLanguage
        let browserCountry = def

        if (browserLanguage === 'en-US') {
          browserCountry = countryList.find(
            (c) => efo(c, 'pool.value') === 'PCNA'
          )
        } else if (browserLanguage === 'de-DE') {
          browserCountry = countryList.find(
            (c) => efo(c, 'pool.value') === 'PD'
          )
        }

        const browserCountryPath = efo(
          browserCountry,
          'targetSite.fields.sitePath.value'
        )

        if (browserCountryPath && !isEditorActive()) {
          // PROD
          if (isProd && environment === 'P') {
            window.location.href = `${browserCountryPath.replace(
              /^\/motorsport/gm,
              ''
            )}${efo(window, 'location.pathname')}`
          }

          // INT & QA
          if (isProd && environment === 'PP') {
            window.location.href = `${browserCountryPath.replace(
              /^\/motorsport/gm,
              ''
            )}${efo(window, 'location.pathname')}`
          }

          // DEV & TEST & LOCAL
          if (!isProd && environment === 'K') {
            window.location.href = `${browserCountryPath}`
          }
        }
      }
    }

    return def
  }
}

export const shuffle = (orderedArray) => {
  let array = [...orderedArray]
  let currentIndex = array.length
  let temporaryValue
  let randomIndex

  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1

    temporaryValue = array[currentIndex]
    array[currentIndex] = array[randomIndex]
    array[randomIndex] = temporaryValue
  }

  return array
}

export const isMobile = () =>
  typeof window !== 'undefined' &&
  window.matchMedia(`(max-width: ${BREAKPOINT_MOBILE}px)`).matches

export const isPortrait = () =>
  typeof window !== 'undefined' &&
  window.matchMedia(`(max-width: ${BREAKPOINT_PORTRAIT}px)`).matches

export const getCorrectURL = (url) => {
  if (url.startsWith('/')) {
    const result = /^[/motorsport]*\/[a-z]+\/[a-z]{2}(\/[\w\W]+)$/gm.exec(url)

    if (result && result[1]) {
      return result[1]
    }
  }

  return url
}

export const getFullCorrectURL = (url, mounted) => {
  if (typeof window !== 'undefined' && mounted && url.startsWith('/')) {
    const result = /^([/motorsport]*\/[a-z]+\/[a-z]{2})/gm.exec(
      efo(window, 'location.pathname')
    )

    if (result && result[1]) {
      return `${result[1]}${getCorrectURL(url)}`
    }
  }

  return url
}

export const navigate = (url) => {
  if (url) {
    if (url.startsWith('http')) {
      const anchor = document.createElement('a')
      anchor.href = url
      anchor.target = '_blank'

      anchor.click()
    } else {
      let location = url

      if (!isFullURLStructure() && location.startsWith('/motorsport/')) {
        location = location.replace('/motorsport', '')
      }

      window.location.href = location
    }
  }
}

export const isDebug = () =>
  typeof window !== 'undefined' &&
  (LOGGER_ALLOWED_SITES.includes(window.location.origin) ||
    document.cookie.includes('debug=true'))

export const log = (componentName, data) => {
  if (isDebug()) {
    console.groupCollapsed(`Component "${componentName}" at ${Date.now()}`)
    console.log(data)
    console.groupEnd()
  }
}

export const errorLog = (componentName, data) => {
  if (
    typeof window !== 'undefined' &&
    (LOGGER_ALLOWED_SITES.includes(window.location.origin) ||
      document.cookie.includes('debug=true'))
  ) {
    console.groupCollapsed(
      `%cAn error happened in "${componentName}" at at ${Date.now()}`,
      'color: red'
    )
    console.log(data)
    console.groupEnd()
  }
}

export const getTable = (table) => {
  const tableData = []
  const rawTableData = decodeURIComponent(table.value).split('&')

  for (let raw of rawTableData) {
    const values = raw.split('=')

    tableData.push({
      description: {
        value: values[0],
      },
      text: {
        value: values[1],
      },
    })
  }

  return tableData
}

export const getSitecoreImage = (src, width, height, rest = {}) => ({
  value: {
    ...rest,
    src,
    width: Number(width),
    height: Number(height),
  },
})

const getTargetURL = (rawTargetURL, mounted) => {
  const isProd = !!getConfig('website.isProdIntQa')
  let targetURL = rawTargetURL

  if (
    typeof window !== 'undefined' &&
    mounted &&
    !rawTargetURL.startsWith('http')
  ) {
    targetURL = `${window.origin}${
      targetURL.startsWith('/') ? targetURL : `/${targetURL}`
    }`
  }

  if (isProd && targetURL.includes('/motorsport/')) {
    targetURL = targetURL.replace('/motorsport/', '/')
  }

  return targetURL
}

export const getShareTwitter = (targetURL, mounted, title, tags = []) => {
  let url = `https://twitter.com/share?url=${getTargetURL(
    targetURL,
    mounted
  )}&text=${title.value || title}`

  try {
    if (tags.length) {
      url += '&hashtags='
    }

    for (let tag of tags) {
      let tagContent = tag
      if (tag.fields) {
        tagContent = {
          name: efo(tag, 'fields.name.value'),
          isCategory: efo(tag, 'fields.isCategory.value'),
        }
      }
      if (!tagContent.isCategory && tagContent.name) {
        url += `${tagContent.name.replace(/[^a-zA-Z0-9]+/gm, '')}${
          tags.indexOf(tag) === tags.length - 1 ? '' : ','
        }`
      }
    }
  } catch (error) {}

  return url
}

export const getShareFacebook = (targetURL, mounted) =>
  `https://www.facebook.com/sharer/sharer.php?u=${getTargetURL(
    targetURL,
    mounted
  )}`

export const getSitecoreRatio = (aspectRatio) => {
  if (
    efo(aspectRatio, 'fields.X value.value') &&
    efo(aspectRatio, 'fields.Y value.value')
  ) {
    return `${efo(aspectRatio, 'fields.X value.value')}:${efo(
      aspectRatio,
      'fields.Y value.value'
    )}`
  }

  return TEASER_ASPECT_RATIO
}

export const getRatio = (ratio) => {
  const values = (ratio || TEASER_ASPECT_RATIO).split(':')

  if (values && values[1] !== undefined && values[0] !== undefined) {
    return Number(values[1]) / Number(values[0])
  }

  return 9 / 16
}

export const getMediaList = (
  mobile,
  tabletPortrait,
  tabletLandscape,
  laptop,
  desktop
) => [
  {
    query: 759,
    value: mobile || 760,
  },
  {
    query: 999,
    value: tabletPortrait || mobile || 1000,
  },
  {
    query: 1299,
    value: tabletLandscape || mobile || 1300,
  },
  {
    query: 1759,
    value: laptop || mobile || 1760,
  },
  {
    query: 1920,
    value: desktop || mobile || 1920,
  },
]

export const getLanguage = (language, country) => {
  let languageCode = language
  langData.forEach((item) => {
    if (language === item.language && country === item.counrty) {
      languageCode = item.languageCode
    }
  })
  return languageCode
}

export const getLanguageFromLocation = () => {
  if (typeof window !== 'undefined') {
    const [, country, language] =
      /^[/motorsport]*\/([a-z]+)\/([a-z]{2})/gm.exec(
        efo(window, 'location.pathname')
      ) || []

    if (country && language) {
      return getLanguage(language, country)
    }
  }
}

export const getSiteName = (language, country) => {
  let siteName = 'motorsport-international'
  langData.forEach((item) => {
    if (language === item.language && country === item.counrty) {
      siteName = item.siteName
    }
  })
  return siteName
}

export const getSiteNameFromLocation = () => {
  if (typeof window !== 'undefined') {
    const [, country, language] =
      /^[/motorsport]*\/([a-z]+)\/([a-z]{2})/gm.exec(
        efo(window, 'location.pathname')
      ) || []

    if (country && language) {
      return getSiteName(language, country)
    }
  }
}

export const waitForElementInDom = (selector) => {
  return new Promise((resolve) => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector))
    }

    const observer = new MutationObserver((mutations) => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector))
        observer.disconnect()
      }
    })

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    })
  })
}

export const getModulePosition = (moduleClass, ref) => {
  const modules = document.getElementsByClassName(moduleClass)
  const index = [].indexOf.call(modules, ref.current)

  return index < 10 ? `0${index}` : index.toString()
}

export const getDataSourceName = (dataSource) => {
  if (dataSource === '' || typeof dataSource === 'undefined') {
    return ''
  } else {
    const dataSourceName = dataSource.split('/').pop()
    return dataSourceName
      .toString() // Cast to string
      .toLowerCase() // Convert the string to lowercase letters
      .trim() // Remove whitespace from both sides of a string
      .replace(/\s+/g, '-') // Replace spaces with -
  }
}

export const getTeaserAnchor = (anchor) => {
  if (anchor === '_article') {
    return false
  } else {
    return anchor
  }
}

export const pushGlEvent = (
  inView,
  moduleIdEvent,
  contentTags,
  moduleId,
  modulePosition,
  displayElementType,
  displayElementId,
  displayElementName
) => {
  const eventName = inView
    ? `PAGMotorsports_${moduleIdEvent}EnterViewport_Load`
    : `PAGMotorsports_${moduleIdEvent}_Load`
  window.GDL.push(eventName, {
    pageExperience: {
      contentTags: contentTags,
    },
    context: {
      moduleId: moduleId,
      modulePosition: modulePosition,
    },
    componentDisplay: {
      displayElementType: displayElementType,
      displayElementId: displayElementId,
      displayElementName: displayElementName,
    },
  })
}

export const sanitizeInput = (s) => {
  return s
    .replace(/(<([^>]+)>)/gi, '')
    .replace(/(&.+;)/gi, '')
    .replace(/[*+?=^${}()|[\]\\<>]/g, '')
}

export const iOS = () => {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  )
}
