import React from 'react'
import { SitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { Route, Switch } from 'react-router-dom'
import { LastLocationProvider } from 'react-router-last-location'
import componentFactory from './temp/componentFactory'
import RouteHandler from './RouteHandler'
import { StoreProvider } from './store'
import { efo, getConfig } from './helpers'

// This is the main JSX entry point of the app invoked by the renderer (server or client rendering).
// By default the app's normal rendering is delegated to <RouteHandler> that handles the loading of JSS route data.

// support languages in the URL prefix
// e.g. /da-DK/path, or /en/path, or /path
export const routePatterns = [
  '/motorsport/:country/:lang([a-z]{2})/:sitecoreRoute*',
  '/:country/:lang([a-z]{2})/:sitecoreRoute*',
  '/:sitecoreRoute*',
  '/',
]

// wrap the app with:
// SitecoreContext: provides component resolution and context services via withSitecoreContext
// Router: provides a basic routing setup that will resolve Sitecore item routes and allow for language URL prefixes.
class AppRoot extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      ssrRenderComplete: false,
    }

    if (props.ssrState) {
      this.sitecoreContext =
        props.ssrState.sitecore && props.ssrState.sitecore.route
          ? {
              route: props.ssrState.sitecore.route,
              itemId: props.ssrState.sitecore.route.itemId,
              ...props.ssrState.sitecore.context,
            }
          : props.ssrState.sitecore.context
    } else {
      this.sitecoreContext = null
    }
  }

  setSsrRenderComplete = (ssrRenderComplete) => {
    this.setState({
      ssrRenderComplete,
    })
  }

  getBaseURL = (p, context) => {
    let baseURL
    let path = p

    if (typeof window !== 'undefined') {
      path = efo(window, 'location.pathname') || path || '/'
    }

    if (!efo(context, 'pageEditing')) {
      const result = /^([/motorsport]*\/[a-z]+\/[a-z]{2})/gm.exec(path || '/')

      if (result && result[1]) {
        baseURL = result[1]
      }

      if (baseURL && getConfig('website.isProdIntQa')) {
        baseURL = baseURL.replace('/motorsport', '')
      }
    }

    return baseURL
  }

  render() {
    const { path, Router } = this.props

    const routeRenderFunction = (props) => (
      <RouteHandler
        route={props}
        ssrRenderComplete={this.state.ssrRenderComplete}
        setSsrRenderComplete={this.setSsrRenderComplete}
      />
    )

    if (typeof window !== 'undefined') {
      if (window.debug === undefined) {
        window.debug = () => {
          document.cookie = `debug=true; expires=${new Date(
            '2050-01-01'
          ).toUTCString()}; path=/`
        }
      }
    }

    return (
      <SitecoreContext
        componentFactory={componentFactory}
        context={this.sitecoreContext}
        scrollToTop={false}
      >
        <StoreProvider>
          <Router
            basename={this.getBaseURL(path, this.sitecoreContext)}
            location={path}
            context={{}}
          >
            <LastLocationProvider>
              <Switch>
                {routePatterns.map((routePattern) => (
                  <Route
                    key={routePattern}
                    path={routePattern}
                    render={routeRenderFunction}
                  />
                ))}
              </Switch>
            </LastLocationProvider>
          </Router>
        </StoreProvider>
      </SitecoreContext>
    )
  }
}

export default AppRoot
