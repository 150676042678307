import React from 'react'

import { Text, RichText } from '@sitecore-jss/sitecore-jss-react'
import Button from '../Button'

import { efo } from '../../../helpers'

import './styles.scss'

const FeaturedBox = (props) => {
  const { headline, text, link } = props
  return (
    <div className="mospo-feature-box-text">
      <Text className="mospo-feature-box-tit" tag="h1" field={headline} />
      {efo(text, 'value') && (
        <RichText className="mospo-feature-box-rich-text" field={text} />
      )}
      {efo(link, 'value.text') && efo(link, 'value.href') && (
        <div className="mospo-feature-box-button-container">
          <Button
            text={efo(link, 'value.text')}
            linkHref={efo(link, 'value.href')}
            linkAnchor={efo(link, 'value.anchor')}
            linkId={efo(link, 'value.id')}
            iconName="buttonarrow"
            iconWidth={8}
            iconHeight={16}
          />
        </div>
      )}
      <span className="mospo-feature-box-border top horizontal"></span>
      <span className="mospo-feature-box-border top vertical"></span>
      <span className="mospo-feature-box-border bottom vertical"></span>
      <span className="mospo-feature-box-border bottom horizontal"></span>
    </div>
  )
}

export default FeaturedBox
