import React, { useState, useRef, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import { useLocation } from 'react-router-dom'
import Image from '../../../shared/Image'
import Icon from '../../../shared/Icon'
import { useInView } from 'react-intersection-observer'

import './styles.scss'

import {
  getKey,
  getCorrectURL,
  getMediaList,
  efo,
  isMobile,
  pushGlEvent,
} from '../../../../helpers'
import { useLayoutEffect } from 'react'

const Menu = (props) => {
  const {
    t,
    navigationLinks,
    menuMainImage,
    menuHeadline,
    setHeaderMenuStatus,
    menuVisible,
    handleMenu,
    id,
  } = props

  const [imageActive, setImageActive] = useState(null)
  const [imageDefault, setImageDefault] = useState(false)
  const [clusterActive, setClusterActive] = useState(null)
  const [clusterSlide, setClusterSlide] = useState(false)
  const [withAnimation, setWithAnimation] = useState(false)
  const [pathName, setPathName] = useState('')
  const location = useLocation()

  useEffect(() => {
    setWithAnimation(true)
  }, [])

  useLayoutEffect(() => {
    setPathName(location.pathname)
  }, [location.pathname])

  const checkCurrentPath = (url) => {
    if (pathName !== '' && pathName.endsWith(url)) {
      return true
    } else {
      return false
    }
  }

  const menuContainerRef = useRef()

  const [menuRef, inView] = useInView({
    threshold: 0.3,
  })

  const handleNavClick = (id) => {
    setImageDefault(`item-${id}`)
    setClusterActive(null)
    setClusterSlide(false)
    handleMenu(false)
    setHeaderMenuStatus(false)
  }

  const handleSubNavClick = (id, subId) => {
    setImageDefault(`item-${id}-${subId}`)
    handleMenu(false)
    setHeaderMenuStatus(false)
  }

  const handleCluster = (id) => {
    if (id === clusterActive) {
      setClusterActive(null)
    } else {
      setClusterActive(id)
      setClusterSlide(true)
      setImageDefault(`item-${id}`)
    }
  }

  const handleClusterLeave = (id) => {
    if (id === clusterActive) {
      return
    } else {
      setImageActive(null)
    }
  }

  const handleClusterBack = () => {
    setClusterSlide(false)
    setTimeout(() => {
      setClusterActive(null)
    }, 400)
  }

  const checkImageActiveNoFallback = (id) => {
    if (imageActive && `item-${id}` !== imageActive) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    if (menuVisible) {
    } else {
      if (!isMobile()) {
        setClusterSlide(false)
        setClusterActive(null)
      }
    }
  }, [menuVisible])

  // Tracking load
  useEffect(() => {
    let checkGDL = setInterval(() => {
      if (typeof window !== 'undefined' && !window.GDL) return
      clearInterval(checkGDL)
      if (typeof window !== 'undefined' && window.GDL) {
        pushGlEvent(
          false,
          'HeaderNavigation',
          [],
          'HeaderNavigation',
          '00',
          'HeaderNavigation',
          id,
          'HeaderNavigation'
        )
      }
    }, 100)
  }, [id])

  // Tracking in view
  useEffect(() => {
    if (inView && typeof window !== 'undefined' && window.GDL) {
      pushGlEvent(
        true,
        'HeaderNavigation',
        [],
        'HeaderNavigation',
        '00',
        'HeaderNavigation',
        id,
        'HeaderNavigation'
      )
    }
  }, [inView, id])

  return (
    <>
      <div
        ref={menuContainerRef}
        className={`menu-container ${withAnimation ? 'menu-transitions' : ''} ${
          menuVisible ? 'menu-container-visible' : ''
        }`}
      >
        <div
          className={`menu-subcontainer ${
            clusterSlide ? 'menu-subcontainer-cluster' : ''
          }`}
        >
          <div className="menu-images">
            <div
              className={`menu-image-container menu-image-container-main ${
                imageActive ? 'menu-image-container-main-out' : ''
              }`}
            >
              <Image
                className="menu-image"
                fields={{ image: menuMainImage }}
                mediaList={getMediaList()}
                disableLazyLoad
                cover={true}
              />
            </div>
            {navigationLinks
              ? navigationLinks.map((value, i) => (
                  <React.Fragment key={getKey(value.id, i)}>
                    <div
                      className={`menu-image-container ${
                        clusterActive === i
                          ? 'menu-image-container-cluster'
                          : ''
                      } ${
                        imageDefault === `item-${i}` ? 'menu-image-default' : ''
                      } ${
                        checkImageActiveNoFallback(i) ? 'image-default-out' : ''
                      }`}
                      key={getKey(value.id, i)}
                      style={
                        `item-${i}` === imageActive
                          ? { zIndex: 4, opacity: 1 }
                          : imageDefault === `item-${i}`
                          ? { zIndex: 3, opacity: 1 }
                          : { zIndex: 1, opacity: 0 }
                      }
                    >
                      <Image
                        className="menu-image"
                        fields={{ image: efo(value, 'fields.menuImage') }}
                        mediaList={getMediaList()}
                        disableLazyLoad
                        cover={true}
                      />
                    </div>
                    {efo(value, 'fields.submenuItems')
                      ? efo(value, 'fields.submenuItems').map(
                          (valueSubitem, y) => (
                            <div
                              className="menu-image-container"
                              key={getKey(valueSubitem.id, y)}
                              style={
                                `item-${i}-${y}` === imageActive
                                  ? { zIndex: 4, opacity: 1 }
                                  : imageDefault === `item-${i}-${y}`
                                  ? { zIndex: 3, opacity: 1 }
                                  : { zIndex: 1, opacity: 0 }
                              }
                            >
                              <Image
                                className="menu-image"
                                fields={{
                                  image: efo(valueSubitem, 'fields.menuImage'),
                                }}
                                mediaList={getMediaList()}
                                disableLazyLoad
                                cover={true}
                              />
                            </div>
                          )
                        )
                      : undefined}
                  </React.Fragment>
                ))
              : undefined}
          </div>
          <div ref={menuRef} className="menu-links">
            <div className="menu-links-title">
              <Text tag="span" field={menuHeadline} />
            </div>
            <nav>
              {navigationLinks
                ? navigationLinks.map((value, i) => {
                    return efo(value, 'fields.submenuItems') ? (
                      <div className="menu-cluster" key={getKey(value.id, i)}>
                        <div
                          className={`menu-link ${
                            clusterActive === i
                              ? 'menu-link-cluster-active'
                              : ''
                          }`}
                        >
                          <div
                            className="menu-cluster-link-container"
                            onClick={() => handleCluster(i)}
                            onMouseEnter={() => setImageActive(`item-${i}`)}
                            onMouseLeave={() => handleClusterLeave(i)}
                          >
                            <span>
                              {efo(value, 'fields.clusterTitle.value')}
                            </span>
                            <Icon
                              name={'buttonarrow'}
                              size={(8, 16)}
                              color={'#000'}
                            />
                          </div>
                          <div
                            className={`menu-cluster-items ${
                              clusterActive === i
                                ? 'menu-cluster-items-visible'
                                : ''
                            }`}
                          >
                            <div className="menu-cluster-back">
                              <button
                                className="menu-cluster-back-button"
                                onClick={handleClusterBack}
                              >
                                <Icon
                                  name={'buttonarrow'}
                                  size={(8, 16)}
                                  color={'#000'}
                                />
                                <span>{t('header-back-to-menu')}</span>
                              </button>
                            </div>
                            <div className="menu-cluster-title">
                              <span>
                                {efo(value, 'fields.clusterTitle.value')}
                              </span>
                            </div>
                            {efo(value, 'fields.submenuItems').map(
                              (valueSubitem, y) => (
                                <div
                                  className="menu-link"
                                  key={getKey(valueSubitem.id, y)}
                                >
                                  <NavLink
                                    to={{
                                      pathname: getCorrectURL(
                                        efo(
                                          valueSubitem,
                                          'fields.link.value.href'
                                        ) || '/'
                                      ),
                                      state: {
                                        layoutID: efo(
                                          valueSubitem,
                                          'fields.link.value.id'
                                        ),
                                      },
                                    }}
                                    className={`${
                                      checkCurrentPath(
                                        getCorrectURL(
                                          efo(
                                            valueSubitem,
                                            'fields.link.value.href'
                                          ) || '/'
                                        )
                                      )
                                        ? 'current-path'
                                        : ''
                                    }`}
                                    onMouseEnter={() =>
                                      setImageActive(`item-${i}-${y}`)
                                    }
                                    onMouseLeave={() => setImageActive(null)}
                                    onClick={() => handleSubNavClick(i, y)}
                                  >
                                    <span>
                                      {efo(
                                        valueSubitem,
                                        'fields.link.value.text'
                                      )}
                                    </span>
                                  </NavLink>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="menu-link" key={getKey(value.id, i)}>
                        <NavLink
                          to={{
                            pathname: getCorrectURL(
                              efo(value, 'fields.link.value.href') || '/'
                            ),
                            state: {
                              layoutID: efo(value, 'fields.link.value.id'),
                            },
                          }}
                          className={`${
                            checkCurrentPath(
                              getCorrectURL(
                                efo(value, 'fields.link.value.href') || '/'
                              )
                            )
                              ? 'current-path'
                              : ''
                          }`}
                          onMouseEnter={() => setImageActive(`item-${i}`)}
                          onMouseLeave={() => setImageActive(null)}
                          onClick={() => {
                            handleNavClick(i)
                          }}
                        >
                          <span>{efo(value, 'fields.link.value.text')}</span>
                          <Icon
                            name={'buttonarrow'}
                            size={(8, 16)}
                            color={'#000'}
                          />
                        </NavLink>
                      </div>
                    )
                  })
                : undefined}
            </nav>
          </div>
        </div>
      </div>
    </>
  )
}

export default withTranslation()(Menu)
