import React, { useEffect, useRef, useCallback } from 'react'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import { useInView } from 'react-intersection-observer'

import withMinimumRequirements from '../../../withMinimumRequirements'
import Image from '../../shared/Image'
import Icon from '../../shared/Icon'

import {
  getKey,
  log,
  getTable,
  efo,
  getMediaList,
  getModulePosition,
  pushGlEvent,
  getDataSourceName,
} from '../../../helpers'

import './styles.scss'

const ArticleBodyVehicleData = (props) => {
  const { fields } = props
  const {
    headline,
    subline,
    tableTitle,
    image,
    buttonDownloadText,
    downloadFile,
    specs,
  } = fields

  const ref = useRef()
  const [inViewRefTrack, inViewTrack] = useInView({
    threshold: 0.3,
  })

  const setRefs = useCallback(
    (node) => {
      ref.current = node
      inViewRefTrack(node)
    },
    [inViewRefTrack]
  )

  useEffect(() => {
    log('Article Body Vehicle Data', { ...fields })
  }, [fields])

  // Tracking load
  useEffect(() => {
    if (typeof window !== 'undefined' && window.GDL) {
      pushGlEvent(
        false,
        'VehicleData',
        [],
        'VehicleData',
        getModulePosition('article-body-vehicle-data-container', ref),
        'VehicleData',
        efo(props, 'rendering.uid'),
        'VehicleData'
      )
    }
  }, [props])

  // Tracking in view
  useEffect(() => {
    if (inViewTrack && typeof window !== 'undefined' && window.GDL) {
      pushGlEvent(
        true,
        'VehicleData',
        [],
        'VehicleData',
        getModulePosition('article-body-vehicle-data-container', ref),
        'VehicleData',
        efo(props, 'rendering.uid'),
        'VehicleData'
      )
    }
  }, [inViewTrack, props])

  return (
    <div
      className="article-body-vehicle-data-container"
      id={getDataSourceName(efo(props, 'rendering.dataSource'))}
      ref={setRefs}
    >
      <div className="article-body-vehicle-data-subcontainer">
        {headline && <Text tag="h2" field={headline} />}
        {subline && <Text tag="h3" field={subline} />}
        <div className="article-body-vehicle-data-body-container">
          <div className="article-body-vehicle-data-spec-container">
            <Text
              className="article-body-vehicle-data-spec-container-title"
              tag="span"
              field={tableTitle}
            />
            <table className="article-body-vehicle-data-spec-container-table">
              <tbody>
                {getTable(specs).map((spec, index) => (
                  <tr key={getKey(spec.id, index)}>
                    <td>
                      <Text
                        className="article-body-vehicle-data-spec-text"
                        tag="span"
                        field={spec.description}
                      />
                    </td>
                    <td>
                      <Text
                        className="article-body-vehicle-data-spec-description"
                        tag="span"
                        field={spec.text}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {buttonDownloadText && downloadFile && (
              <a
                className="article-body-vehicle-data-download-link"
                href={efo(downloadFile, 'value.src')}
                rel="nofollow noopener noreferrer"
                target={
                  efo(downloadFile, 'value.src') !== '#' ? '_blank' : '_self'
                }
              >
                <div className="article-body-vehicle-data-download-link-icon-wrapper">
                  <Icon
                    className="article-body-vehicle-data-download-link-icon"
                    name="download"
                  />
                </div>
                <Text
                  className="article-body-vehicle-data-download-link-text"
                  tag="span"
                  field={buttonDownloadText}
                />
              </a>
            )}
          </div>
          <div className="article-body-vehicle-data-image-container">
            <div className="article-body-vehicle-data-image-subcontainer">
              <Image
                fields={{
                  image,
                }}
                mediaList={getMediaList()}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withMinimumRequirements(ArticleBodyVehicleData, [
  'fields.tableTitle.value',
  'fields.specs.value',
  'fields.image.value',
])
