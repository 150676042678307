import React, { useContext, useEffect, useState } from 'react'
import {
  isEditorActive,
  Placeholder,
  //  VisitorIdentification,
} from '@sitecore-jss/sitecore-jss-react'

import Modal from './components/shared/Modal'

import { Store } from './store'
import { log, getConfig, efo } from './helpers'
import { TIME_EVENT } from './constants'

import GlobalDataLayer, {
  GlobalDataLayerExtension,
} from './GlobalDataLayerConfig'

import './assets/app.scss'

/*
  APP LAYOUT
  This is where the app's HTML structure and root placeholders should be defined.

  All routes share this root layout by default (this could be customized in RouteHandler),
  but components added to inner placeholders are route-specific.
*/

const Main = (props) => {
  const { route, context } = props

  const { state } = useContext(Store)
  const { countryListStatus, menuStatus, searchStatus } = state.header

  const [showShadow, setShowShadow] = useState(false)

  const pagData =
    (typeof window !== 'undefined' ? window.pagData : undefined) || []

  const dataLayerData = {
    context: {
      environment: getConfig('website.pagDataEnviroment'),
      applicationId: 'motorsport',
      applicationName: 'sitecore',
      server: getConfig('website.path'),
    },
    visitor: {
      loginStatus: isEditorActive(),
    },
  }

  const getTags = (route) => {
    let tags = []
    if (efo(route, 'fields.masterCategory')) {
      tags.push(efo(route, 'fields.masterCategory.fields.name.value'))
    }
    ;(efo(route, 'fields.tags') || []).forEach((tag) => {
      tags.push(efo(tag, 'fields.name.value'))
    })
    return tags
  }

  useEffect(() => {
    log('Main', {
      route: { ...route },
      context: { ...context },
      pagData: { ...pagData },
    })
  }, [route, context, pagData])

  useEffect(() => {
    const interval = setInterval(() => {
      const event = new CustomEvent('Event', { detail: new Date().getTime() })
      event.initEvent(TIME_EVENT, true, true)
      document.dispatchEvent(event)
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (showShadow && !countryListStatus && !menuStatus && !searchStatus) {
      setTimeout(() => setShowShadow(false), 200)
    } else if (
      !showShadow &&
      (countryListStatus || menuStatus || searchStatus)
    ) {
      setShowShadow(true)
    }
  }, [showShadow, countryListStatus, menuStatus, searchStatus])

  useEffect(() => {
    if (typeof window !== 'undefined' && !window.GDL) {
      window.GDL = new GlobalDataLayer()
      window.GDL.registerExtension(
        new GlobalDataLayerExtension.DefaultWebProperties(window.GDL, {
          breakpoints: [760, 1000, 1300, 1760],
          pageIdAlwaysUpToDate: false,
        })
      )
      window.GDL.setDefaultProperties(
        /^PAGMotorsports_.*/,
        window.GDL.ext.DefaultWebProperties.with(
          {
            context: {
              language: route.itemLanguage.split('-')[0],
              country: () => {
                const country = route.itemLanguage.split('-')[1]
                if (country === 'GB') {
                  return 'international'
                } else {
                  return country
                }
              },
            },
            pageExperience: {
              pageName: () => {
                if (
                  window.location.pathname.includes('/article/') ||
                  window.location.pathname.includes('/category/') ||
                  window.location.pathname.includes('/categories/')
                ) {
                  return window.location.pathname
                } else if (
                  window.location.pathname.endsWith('/en') ||
                  window.location.pathname.endsWith('/de')
                ) {
                  return 'Home'
                } else {
                  return window.location.pathname
                }
              },
            },
          },
          dataLayerData
        )
      )

      window.oneGa = window.oneGa || []
      ;(function (w, d, s, l, i) {
        w[l] = w[l] || []
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
        const f = d.getElementsByTagName(s)[0]
        const j = d.createElement(s)
        const dl = l !== 'dataLayer' ? '&l=' + l : ''
        j.async = true
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
        f.parentNode.insertBefore(j, f)
      })(window, document, 'script', 'oneGa', 'GTM-W2NKNJZ')
    }
  })

  useEffect(() => {
    if (typeof window !== 'undefined' && window.GDL) {
      window.GDL.push('PAGMotorsports_General_Pageload', {
        pageExperience: {
          contentTags: getTags(route),
        },
      })
    }
  }, [route])
  const filteredObjects = route.placeholders['jss-motorsport-header'].filter(
    (obj) => obj.componentName === 'UserCentrics'
  )
  const UC_PROCESSOR = filteredObjects[0]?.fields?.processor || {}

  const [userCentricsReady, setUserCentricsReady] = useState(false)
  useEffect(() => {
    window.addEventListener('ucInit', (event) => {
      setUserCentricsReady(true)
      console.log('Layout::UcInit_______')
    })
  }, [])

  return (
    <>
      <main
        data-is-exp-editor={isEditorActive() ? '' : undefined}
        data-shadow={showShadow ? '' : undefined}
      >
        <div className="main-shadow" />
        <div>
          <Placeholder
            name="jss-motorsport-main-top"
            rendering={route}
            UC_READY={userCentricsReady}
            UC_PROCESSOR={UC_PROCESSOR}
          />
        </div>
        <Placeholder
          name="jss-motorsport-main"
          rendering={route}
          UC_READY={userCentricsReady}
          UC_PROCESSOR={UC_PROCESSOR}
        />
      </main>
      <Modal />
    </>
  )
}

const Layout = ({ route }) => {
  let categoryIdForFooter
  const partnersForFooter = efo(
    route.placeholders['jss-motorsport-footer'][0],
    'fields.partners'
  )
    ? efo(route.placeholders['jss-motorsport-footer'][0], 'fields.partners')
    : []

  if (route.fields.Category) {
    categoryIdForFooter = route.fields.Category?.value.id.slice(1, -1)
  } else if (route.fields.masterCategory) {
    categoryIdForFooter = route.fields.masterCategory?.id
  }

  return (
    <React.Fragment>
      {/*
    VisitorIdentification is necessary for Sitecore Analytics to determine if the visitor is a robot.
    If Sitecore XP (with xConnect/xDB) is used, this is required or else analytics will not be collected for the JSS app.
    For XM (CMS-only) apps, this should be removed.

    VI detection only runs once for a given analytics ID, so this is not a recurring operation once cookies are established.<VisitorIdentification />
  */}

      <Placeholder name="jss-motorsport-head" rendering={route} />
      <Placeholder name="jss-motorsport-header" rendering={route} />
      <Main route={route} />
      <Placeholder
        name="jss-motorsport-footer"
        rendering={route}
        categoryId={categoryIdForFooter}
        partnersForFooter={partnersForFooter}
      />
    </React.Fragment>
  )
}

export default Layout
