import React, { createContext, useReducer } from 'react'

import { LOGGER_ALLOWED_SITES } from '../constants'

import header from './header'
import tag from './tag'
import teaser from './teaser'
import grid from './grid'
import modal from './modal'
import search from './search'

const stores = {
  header,
  tag,
  teaser,
  grid,
  modal,
  search,
}

let initialState = {}
for (let key in stores) {
  initialState[key] = {
    ...stores[key].initialState,
  }
}

const getActions = () => {
  let _actions = {}
  for (let key in stores) {
    _actions = {
      ..._actions,
      ...stores[key].actions,
    }
  }
  return _actions
}

export const actions = getActions()

export const reducer = (state = initialState, action = {}) => {
  const previousState = {
    ...state,
  }
  const nextState = {
    ...state,
  }
  for (let key in stores) {
    nextState[key] = {
      ...nextState[key],
      ...stores[key].reducer(nextState[key], action),
    }
  }
  if (
    typeof window !== 'undefined' &&
    LOGGER_ALLOWED_SITES.includes(window.location.origin)
  ) {
    console.groupCollapsed(
      `State change "${action.type}" emitted at ${Date.now()}`
    )
    console.log('This is the %cprevious state:', 'font-weight: bold')
    console.log(previousState)
    console.log('This is the %ccurrent state:', 'font-weight: bold')
    console.log(nextState)
    console.groupEnd()
  }
  return {
    ...nextState,
  }
}

export const Store = createContext()

export const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <Store.Provider value={{ state, dispatch }}>
      {React.cloneElement(children, {
        store: {
          state,
          dispatch,
        },
      })}
    </Store.Provider>
  )
}
