import React, { useEffect, useState } from 'react'

import Icon from '../Icon'

import { getShareTwitter, getShareFacebook, efo } from '../../../helpers'

const BottomShareLike = (props) => {
  const {
    // likes,
    loading,
    // liked,
    // setLiked,
    omitPadding,
    targetURL,
    title,
    tags,
  } = props

  const [mount, setMount] = useState(false)

  useEffect(() => {
    if (!mount) {
      setMount(true)
    }
  }, [mount])

  const finalURL = efo(targetURL, 'href')

  return (
    <div
      className="teaser-bottom-share-container"
      data-omit-padding={omitPadding ? '' : undefined}
    >
      {loading || (!loading && finalURL) ? (
        <div className="teaser-share-container">
          {!loading && (
            <>
              <div className="teaser-share-options">
                <a
                  className="teaser-share-options--facebook"
                  href={getShareFacebook(finalURL, mount)}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(event) => {
                    event.stopPropagation()
                  }}
                >
                  <Icon name="logoFacebook" />
                </a>
                <a
                  className="teaser-share-options--twitter"
                  href={getShareTwitter(finalURL, mount, title, tags)}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(event) => {
                    event.stopPropagation()
                  }}
                >
                  <Icon name="logoTwitter" />
                </a>
              </div>
            </>
          )}
          <div
            className={
              loading ? 'teaser-lazy-share-button' : 'teaser-share-button'
            }
            onClick={(event) => {
              event.stopPropagation()
            }}
          >
            {!loading && <Icon name="share" />}
          </div>
        </div>
      ) : undefined}
      {!loading ? undefined : (
        // <div
        //   className="teaser-like-container"
        //   onClick={(event) => {
        //     event.preventDefault()
        //     event.stopPropagation()
        //     // TODO: Add like of this teaser
        //     setLiked(!liked)
        //   }}
        //   data-liked={liked ? '' : undefined}
        // >
        //   <svg viewBox="0 0 24 20">
        //     <g transform="translate(1, 1)">
        //       <path d="M16.40625,0.307309739 C14.896875,0.307309739 13.51875,1.0210502 12.665625,2.25387462 C11.8125,3.48669905 10.5,5.23860745 10.5,5.23860745 C10.5,5.23860745 9.253125,3.42181356 8.334375,2.18898913 C7.48125,0.9561647 6.0375,0.242424242 4.59375,0.242424242 C2.034375,0.242424242 0,2.25387462 0,4.78440898 C0,6.0172334 0.525,7.25005783 1.44375,8.09356928 C2.49375,9.06685172 7.678125,14.3225769 10.5,17.2424242 C13.321875,14.3225769 18.50625,9.06685172 19.55625,8.09356928 C20.475,7.25005783 21,6.0821189 21,4.78440898 C21,2.31876012 18.965625,0.307309739 16.40625,0.307309739 Z" />
        //     </g>
        //   </svg>
        //   <span>
        //     {Number(likes && likes.value ? likes.value : 0) + (liked ? 1 : 0)}
        //   </span>
        // </div>
        <div className="teaser-lazy-like-container" />
      )}
    </div>
  )
}

export default BottomShareLike
