import React from 'react'

import Quiz from '../Quiz'

import { efo } from '../../../helpers'

const getImageQuiz = (props) => {
  const questions = efo(props, 'fields.questions')

  return !!(questions && questions.find((q) => q.image))
}

const BottomQuiz = (props) => (
  <div
    className="teaser-bottom-quiz-container"
    data-is-image-quiz={getImageQuiz(props) ? '' : undefined}
  >
    <Quiz {...props} isTeaser />
  </div>
)

export default BottomQuiz
