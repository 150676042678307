const types = {
  SET: {
    MODAL: {
      SHOW: 'SET.MODAL.SHOW',
      HIDE: 'SET.MODAL.HIDE',
      TOGGLE: 'SET.MODAL.TOGGLE',
    },
  },
}

const initialState = {
  show: false,
  data: undefined,
}

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.SET.MODAL.SHOW:
      return {
        ...state,
        show: action.id,
        data: action.data,
      }
    case types.SET.MODAL.HIDE:
      return {
        ...state,
        show: false,
        data: undefined,
      }
    case types.SET.MODAL.TOGGLE:
      return {
        ...state,
        show: !state.show,
        data: state.show ? action.data : undefined,
      }
    default:
      return state
  }
}

const setModalShow = (dispatch, values = {}, id = {}) => {
  dispatch({
    type: types.SET.MODAL.SHOW,
    data: { ...values },
    id: { ...id },
  })
}

const setModalHide = (dispatch) =>
  dispatch({
    type: types.SET.MODAL.HIDE,
  })

const setModalToggle = (dispatch, values = {}) =>
  dispatch({
    type: types.SET.MODAL.TOGGLE,
    data: { ...values },
  })

export default {
  initialState,
  reducer,
  actions: {
    setModalShow,
    setModalHide,
    setModalToggle,
  },
}
