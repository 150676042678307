import React, { useEffect, useMemo, useState, useRef, useCallback } from 'react'
import { Text, File } from '@sitecore-jss/sitecore-jss-react'
import decodeUriComponent from 'decode-uri-component'
import { useInView } from 'react-intersection-observer'

import withMinimumRequirements from '../../../withMinimumRequirements'
import Icon from '../../shared/Icon'

import {
  efo,
  getKey,
  log,
  getModulePosition,
  pushGlEvent,
  getDataSourceName,
} from '../../../helpers'

import './styles.scss'

const getShortableElements = (column, columnIndex, rows) => {
  const elements = [efo(column, 'fields.name').value]

  for (let row of rows) {
    const element = row[columnIndex]

    if (!elements.includes(element)) {
      elements.push(element)
    }
  }

  return elements
}

const getRows = (rawRows) => {
  const rows = []

  for (let row of efo(rawRows, 'value').split(/&/gm)) {
    rows.push((row.split(/=/gm) || []).map((v) => decodeUriComponent(v)))
  }

  return rows
}

const getShortedRows = (rows, shortableValues) => {
  if (shortableValues && Object.keys(shortableValues).length) {
    let finalRows = [...rows]

    for (let key in shortableValues) {
      const index = Number(key)
      const value = shortableValues[key]

      finalRows = finalRows.filter((row) => row[index] === value)
    }

    return finalRows
  }

  return rows
}

const ArticleBodyMightyTable = (props) => {
  const { fields, params, rendering } = props
  const { subelement, active } = params
  const {
    title,
    crossHover,
    textCentered,
    downloadFileText,
    downloadFile,
    columns,
    rows: rawRows,
  } = fields

  const [hoveringTable, setHoveringTable] = useState(false)
  const [hoveringRow, setHoveringRow] = useState(-1)
  const [shortableStatus, setShortableStatus] = useState({})
  const [shortableValues, setShortableValues] = useState({})
  const [virtualRowWidth, setVirtualRowWidth] = useState('0px')
  const [mount, setMount] = useState(false)
  const [trackLoad, setTrackLoad] = useState(false)

  const tableContainer = useRef(null)

  const rows = useMemo(() => getRows(rawRows), [rawRows])

  const handleVirtualRowResize = () => {
    if (tableContainer.current) {
      if (
        tableContainer.current.scrollWidth > tableContainer.current.offsetWidth
      ) {
        setVirtualRowWidth(tableContainer.current.scrollWidth - 2 + 'px')
      } else {
        setVirtualRowWidth('100%')
      }
    } else {
      setVirtualRowWidth('0px')
    }
  }

  const ref = useRef()
  const [inViewRefTrack, inViewTrack] = useInView({
    threshold: 0.3,
  })

  const setRefs = useCallback(
    (node) => {
      ref.current = node
      inViewRefTrack(node)
    },
    [inViewRefTrack]
  )

  useEffect(() => {
    if (!mount) {
      setMount(true)
    }
  }, [mount])

  useEffect(() => {
    typeof window !== 'undefined' &&
      window.addEventListener('resize', handleVirtualRowResize)
    return () => {
      typeof window !== 'undefined' &&
        window.removeEventListener('resize', handleVirtualRowResize)
    }
  }, [])

  useEffect(() => {
    handleVirtualRowResize()
  }, [hoveringRow])

  // Tracking load
  useEffect(() => {
    if (typeof window !== 'undefined' && window.GDL && !trackLoad) {
      pushGlEvent(
        false,
        'MightyTable',
        [],
        'MightyTable',
        getModulePosition('article-body-mighty-table-container', ref),
        'MightyTable',
        efo(props, 'rendering.uid'),
        'MightyTable'
      )
      setTrackLoad(true)
    }
  }, [props, trackLoad])

  // Tracking in view
  useEffect(() => {
    if (inViewTrack && typeof window !== 'undefined' && window.GDL) {
      if (
        typeof efo(params, 'active') === 'undefined' &&
        typeof efo(params, 'subelement') === 'undefined'
      ) {
        pushGlEvent(
          true,
          'MightyTable',
          [],
          'MightyTable',
          getModulePosition('article-body-mighty-table-container', ref),
          'MightyTable',
          efo(props, 'rendering.uid'),
          'MightyTable'
        )
      }
      if (
        efo(params, 'active') === efo(props, 'rendering.uid') &&
        efo(params, 'subelement') === true
      ) {
        pushGlEvent(
          true,
          'MightyTable',
          [],
          'MightyTable',
          getModulePosition('article-body-mighty-table-container', ref),
          'MightyTable',
          efo(props, 'rendering.uid'),
          'MightyTable'
        )
      }
    }
  }, [inViewTrack, props, params])

  useEffect(() => {
    log('Article Body Mighty Table', { ...fields })
  }, [fields])

  return (
    <div
      className="article-body-mighty-table-container"
      ref={setRefs}
      id={getDataSourceName(efo(props, 'rendering.dataSource'))}
      data-subelement={subelement ? '' : undefined}
      data-cross-hover={crossHover && crossHover.value ? '' : undefined}
      data-text-centered={textCentered && textCentered.value ? '' : undefined}
      data-hide={active && active !== efo(rendering, 'uid') ? '' : undefined}
      onMouseEnter={() => setHoveringTable(true)}
      onMouseLeave={() => setHoveringTable(false)}
    >
      <Text tag="h3" field={title} />
      <div
        ref={tableContainer}
        className="article-body-mighty-table-subcontainer"
      >
        {columns.map((column = {}, columnIndex) => (
          <div
            key={getKey(column.id, columnIndex)}
            className="article-body-mighty-table-column"
          >
            <div
              className="article-body-mighty-table-column-row"
              data-header
              data-shortable={
                (efo(column, 'fields.shortable') || {}).value ? '' : undefined
              }
            >
              {(efo(column, 'fields.shortable') || {}).value ? (
                <>
                  <div
                    className="article-body-mighty-table-column-row"
                    onClick={() => {
                      setShortableStatus((values) => ({
                        ...values,
                        [columnIndex]: !values[columnIndex],
                      }))
                    }}
                  >
                    <Text
                      tag="span"
                      field={
                        shortableValues[columnIndex]
                          ? { value: shortableValues[columnIndex] }
                          : efo(column, 'fields.name')
                      }
                    />
                    <div className="article-body-mighty-table-column-short-icon-container">
                      <Icon name="arrowHeadDown" />
                    </div>
                  </div>
                  <div
                    className="article-body-mighty-table-column-short-list-container"
                    data-show={shortableStatus[columnIndex] ? '' : undefined}
                  >
                    {getShortableElements(column, columnIndex, rows).map(
                      (element, index) => (
                        <span
                          key={index}
                          onClick={() => {
                            if (element === efo(column, 'fields.name').value) {
                              setShortableValues((values) => {
                                delete values[columnIndex]

                                return values
                              })
                            } else {
                              setShortableValues((values) => ({
                                ...values,
                                [columnIndex]: element,
                              }))
                            }
                            setShortableStatus((values) => ({
                              ...values,
                              [columnIndex]: false,
                            }))
                          }}
                        >
                          {element}
                        </span>
                      )
                    )}
                  </div>
                </>
              ) : (
                <Text tag="span" field={efo(column, 'fields.name')} />
              )}
            </div>
            {getShortedRows(rows, shortableValues).map((row, rowIndex) => (
              <>
                <div
                  key={rowIndex}
                  className="article-body-mighty-table-column-row"
                  onMouseEnter={() => setHoveringRow(rowIndex)}
                >
                  <span>{row[columnIndex]}</span>
                </div>
                {crossHover && columnIndex === 0 ? (
                  <div
                    className="article-body-mighty-table-column-row-virtual"
                    style={{
                      top: `${30 * rowIndex + 50}px`,

                      width: virtualRowWidth,
                    }}
                    data-hover={
                      crossHover &&
                      crossHover.value &&
                      hoveringTable &&
                      hoveringRow === rowIndex
                        ? ''
                        : undefined
                    }
                  />
                ) : undefined}
              </>
            ))}
          </div>
        ))}
      </div>
      <File
        className="article-body-mighty-table-file-container"
        field={downloadFile}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="article-body-mighty-table-file-icon-container">
          <Icon name="download" size={25} />
        </div>
        <Text tag="span" field={downloadFileText} />
      </File>
    </div>
  )
}

export default withMinimumRequirements(ArticleBodyMightyTable, [
  'fields.title.value',
  'fields.columns',
  'fields.columns.0',
  'fields.columns.0.fields',
  'fields.columns.0.fields.name',
  'fields.columns.0.fields.name.value',
  'fields.rows.value',
])
