import React from 'react'

import Countdown from '../Countdown'

import { efo, getSitecoreImage } from '../../../helpers'

const MediaCountdown = (props) => (
  <Countdown
    {...props}
    poster={getSitecoreImage(
      efo(props, 'image.src'),
      efo(props, 'image.width'),
      efo(props, 'image.height'),
      { ...(efo(props, 'image') || {}) }
    )}
    countdownPartner={
      props.countdownPartner
        ? getSitecoreImage(
            efo(props, 'countdownPartner.src'),
            efo(props, 'countdownPartner.width'),
            efo(props, 'countdownPartner.height'),
            { ...(efo(props, 'countdownPartner') || {}) }
          )
        : undefined
    }
    isStaticTeaser
  />
)

export default MediaCountdown
