import React, { useEffect, useContext, useCallback } from 'react'
import { useInView } from 'react-intersection-observer'
import { useLocation } from 'react-router-dom'
import Masonry from 'react-masonry-css'
import fetch from 'isomorphic-unfetch'
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { useTranslation } from 'react-i18next'

import Icon from '../Icon'
import AbstractTeaser from '../AbstractTeaser'
import StaticTeaserGrid from '../StaticTeaserGrid'

import { actions, Store } from '../../../store'
import { efo, getConfig, log } from '../../../helpers'
import {
  GRID_BREAKPOINTS,
  STATIC_TEASER_NUMBER,
  TEASER_NUMBER,
  TEASER_ANIMATION_DELAY,
  TEASER_ANIMATION_MAX_DELAY,
} from '../../../constants'

import './styles.scss'

const getDelay = (index = 0) => {
  const delay = (index + 1) * TEASER_ANIMATION_DELAY
  if (delay > TEASER_ANIMATION_MAX_DELAY) {
    return TEASER_ANIMATION_MAX_DELAY
  }
  return delay
}

const getEmptyContent = (num) => {
  const content = []
  for (let i = 0; i < num; i++) {
    content.push({
      teaserComponentName: 'LoadingTeaser',
      loading: true,
      delay: getDelay(i),
    })
  }
  return content
}

const getEmptyGrid = () => ({
  teasers: getEmptyContent(TEASER_NUMBER),
  staticTeasers: getEmptyContent(STATIC_TEASER_NUMBER),
  page: 0,
  loading: false,
  firsLoaded: false,
  infiniteLoaded: false,
  noMoreContent: false,
  teaserAnimationIndex: -1,
  staticTeaserAnimationIndex: -1,
  UC_READY: false,
  UC_PROCESSOR: [],
})

const fetchContent = async (
  sitename,
  database,
  language,
  categorization,
  grid,
  setGrid,
  targetURL,
  num = TEASER_NUMBER
) => {
  let { staticTeasers, teasers, page, noMoreContent } = grid

  const response = await fetch(targetURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      SiteName: sitename,
      Database: database,
      Language: language,
      CurrentPage: page,
      PageSize: num,
      TagId: categorization.id,
    }),
  })

  const data = await response.json()

  if (data && data.teaserItems) {
    for (let item of data.teaserItems) {
      const loadingContent = teasers.find((c) => c.loading)
      if (loadingContent) {
        teasers[teasers.indexOf(loadingContent)] = {
          ...item,
          delay: loadingContent.delay,
        }
      } else {
        teasers.push({
          ...item,
          delay: getDelay(teasers.length),
        })
      }
    }
    if (teasers.find((c) => c.loading)) {
      noMoreContent = true
    }
    page += 1
  } else {
    noMoreContent = true
  }

  if (!grid.firsLoaded && data && data.staticTeasers) {
    for (let item of data.staticTeasers) {
      const loadingContent = staticTeasers.find((c) => c.loading)
      if (loadingContent) {
        staticTeasers[staticTeasers.indexOf(loadingContent)] = {
          ...item,
          delay: loadingContent.delay,
        }
      } else {
        staticTeasers.push({
          ...item,
          delay: getDelay(staticTeasers.length),
        })
      }
    }
  }

  teasers = teasers.filter((c) => !c.loading)
  staticTeasers = staticTeasers.filter((c) => !c.loading)

  setGrid({
    ...grid,
    staticTeasers,
    teasers,
    page,
    noMoreContent,
    firsLoaded: true,
    teaserAnimationIndex: teasers.length,
    staticTeaserAnimationIndex: staticTeasers.length,
  })
}

const getCategorization = (tag, route, id) => {
  if (tag) {
    return {
      ...tag,
      id: tag.id || tag.template,
    }
  }

  if (route && route.fields && route.fields.Category) {
    return route.fields.Category.value
  }

  if (id) {
    return {
      id,
    }
  }

  return {}
}

const getDefaultCategorization = (defaultData) => ({
  teasers: defaultData.teaserItems || [],
  staticTeasers: defaultData.staticTeasers || [],
  page: 1,
  loading: false,
  firsLoaded: true,
  infiniteLoaded: true,
  noMoreContent: true,
  teaserAnimationIndex: defaultData.teaserItems
    ? defaultData.teaserItems.length
    : -1,
  staticTeaserAnimationIndex: defaultData.staticTeasers
    ? defaultData.staticTeasers.length
    : -1,
})

const Grid = (props) => {
  //log('Grid___', props)

  const { targetURL, sitecoreContext: context, defaultData } = props
  const { route, itemId: id } = context
  const [t] = useTranslation()

  const { search } = useLocation()
  const { state, dispatch } = useContext(Store)
  const { grids } = state.grid
  const { tagSelected: tag } = state.tag

  const [ref, inView] = useInView({
    triggerOnce: true,
  })

  const categorization = useCallback(getCategorization(tag, route, id), [
    tag,
    route,
    id,
  ])

  const grid = useCallback(
    defaultData
      ? getDefaultCategorization(defaultData)
      : grids[categorization.id] || getEmptyGrid(),
    [grids, categorization, defaultData]
  )

  const sitename = useCallback(efo(context, 'site.name'), [context])
  const database = useCallback(efo(route, 'databaseName'), [route])
  const language = useCallback(efo(context, 'language'), [context])

  const headline = efo(route, 'fields.teaserGridHeadline')

  const {
    teasers,
    staticTeasers,
    loading,
    firsLoaded,
    infiniteLoaded,
    noMoreContent,
    teaserAnimationIndex,
    staticTeaserAnimationIndex,
    UC_READY,
    UC_PROCESSOR,
  } = grid

  const setGrid = useCallback(
    (values) =>
      actions.setGrid(dispatch, {
        id: categorization.id,
        values: {
          ...grid,
          ...values,
        },
      }),
    [dispatch, categorization, grid]
  )

  const fetchMore = useCallback(() => {
    if (!loading && firsLoaded && !noMoreContent) {
      setGrid({
        infiniteLoaded: true,
        teasers: [...teasers, ...getEmptyContent(TEASER_NUMBER)],
      })
    }
  }, [loading, firsLoaded, noMoreContent, teasers, setGrid])

  // for loadmore in the Buttom
  useEffect(() => {
    if (
      !loading &&
      teasers.find((c) => c.loading) &&
      !(search.includes('filter') && !tag)
    ) {
      setGrid({ loading: true })
      fetchContent(
        sitename,
        database,
        language,
        categorization,
        grid,
        setGrid,
        targetURL
      )
    }
  }, [
    loading,
    teasers,
    search,
    tag,
    sitename,
    database,
    language,
    categorization,
    grid,
    setGrid,
    targetURL,
  ])

  useEffect(() => {
    if (inView && !infiniteLoaded) {
      fetchMore()
    }
  }, [inView, infiniteLoaded, fetchMore])

  useEffect(() => {
    log('Abstract Teaser Grid', {
      categorization: { ...categorization },
      grid: { ...grid },
      context: { ...context },
    })
  }, [categorization, grid, context])

  return (
    <>
      {headline && (
        <div className="teaser-grid-headline">
          <Text
            className="teaser-grid-headline-title"
            tag="h2"
            field={headline}
          />
        </div>
      )}
      <div>
        <StaticTeaserGrid
          staticTeasers={staticTeasers}
          staticTeaserAnimationIndex={staticTeaserAnimationIndex}
        />
      </div>
      <div className="teaser-grid-container">
        <Masonry
          breakpointCols={GRID_BREAKPOINTS}
          className="teaser-grid-subcontainer"
          columnClassName="teaser-grid-content-container"
        >
          {teasers &&
            teasers.map((c, i) => (
              <AbstractTeaser
                UC_READY={props.UC_READY}
                UC_PROCESSOR={props.UC_PROCESSOR}
                key={i}
                index={i}
                id={`${categorization.id}-${i}`}
                delay={c.delay}
                loading={c.loading}
                fields={c}
                language={language}
                stopAnimation={
                  teaserAnimationIndex !== -1 && teaserAnimationIndex > i
                }
              />
            ))}
        </Masonry>
        <div className="teaser-grid-load-more-container">
          <div
            ref={ref}
            className="teaser-grid-load-more-subcontainer"
            data-loading={loading ? '' : undefined}
            data-no-more-content={noMoreContent ? '' : undefined}
            onClick={() => {
              if (infiniteLoaded) {
                fetchMore()
              }
            }}
          >
            {context.route.fields.Category &&
              (loading ? (
                <></>
              ) : (
                <>
                  <Icon name="add" size={40} />
                  <p className="load-more-text">{t('load-more')}</p>
                </>
              ))}
          </div>
        </div>
      </div>
    </>
  )
}

Grid.defaultProps = {
  targetURL: getConfig('website.teaserEndpoint'),
}

export default withSitecoreContext()(Grid)
