import React, { useEffect, useState, useRef, useCallback } from 'react'
import { Placeholder, Text } from '@sitecore-jss/sitecore-jss-react'
import { useInView } from 'react-intersection-observer'

import withMinimumRequirements from '../../../withMinimumRequirements'
import {
  efo,
  log,
  getKey,
  getModulePosition,
  pushGlEvent,
  getDataSourceName,
} from '../../../helpers'

import './styles.scss'

const getActiveUID = (rendering, active) => {
  const elements = efo(
    rendering,
    'placeholders.jss-motorsport-article-body-table-collection-tables'
  )
  const activeElement = elements && elements[active]

  if (activeElement) {
    return activeElement.uid
  }

  return undefined
}

const ArticleBodyTableCollectionRouter = (props) => {
  const { rendering, active, setActive } = props

  const elements = efo(
    rendering,
    'placeholders.jss-motorsport-article-body-table-collection-tables'
  )

  useEffect(() => {
    log('Article Body Table Collection > Router', { ...rendering })
  }, [rendering])

  if (!elements) {
    return null
  }

  return (
    <div className="article-body-table-collection-router-container">
      {elements.map((element, index) => (
        <div
          key={getKey(element.uid, index)}
          className="article-body-table-collection-router-element"
          data-active={active === index ? '' : undefined}
          onClick={() => setActive(index)}
        >
          <Text tag="span" field={efo(element, 'fields.title')} />
        </div>
      ))}
    </div>
  )
}

const ArticleBodyTableCollection = (props) => {
  const { fields, rendering } = props
  const { subtitle, title } = fields || {}

  const [active, setActive] = useState(0)

  const ref = useRef()
  const [inViewRefTrack, inViewTrack] = useInView({
    threshold: 0.3,
  })

  const setRefs = useCallback(
    (node) => {
      ref.current = node
      inViewRefTrack(node)
    },
    [inViewRefTrack]
  )

  useEffect(() => {
    log('Article Body Table Collection', {
      fields: { ...(fields || {}) },
      rendering: { ...rendering },
    })
  }, [fields, rendering])

  // Tracking load
  useEffect(() => {
    if (typeof window !== 'undefined' && window.GDL) {
      pushGlEvent(
        false,
        'TableCollection',
        [],
        'TableCollection',
        getModulePosition('article-body-table-collection-container', ref),
        'TableCollection',
        efo(props, 'rendering.uid'),
        'TableCollection'
      )
    }
  }, [props])

  // Tracking in view
  useEffect(() => {
    if (inViewTrack && typeof window !== 'undefined' && window.GDL) {
      pushGlEvent(
        true,
        'TableCollection',
        [],
        'TableCollection',
        getModulePosition('article-body-table-collection-container', ref),
        'TableCollection',
        efo(props, 'rendering.uid'),
        'TableCollection'
      )
    }
  }, [inViewTrack, props])

  return (
    <div
      className="article-body-table-collection-container"
      id={getDataSourceName(efo(props, 'rendering.dataSource'))}
      ref={setRefs}
    >
      {subtitle ? <Text tag="span" field={subtitle} /> : undefined}
      {title ? <Text tag="h3" field={title} /> : undefined}
      <ArticleBodyTableCollectionRouter
        rendering={rendering}
        active={active}
        setActive={setActive}
      />
      <div className="article-body-table-collection-subcontainer">
        <Placeholder
          name="jss-motorsport-article-body-table-collection-tables"
          rendering={rendering}
          params={{
            subelement: true,
            active: getActiveUID(rendering, active),
          }}
        />
      </div>
    </div>
  )
}

export default withMinimumRequirements(ArticleBodyTableCollection, [
  'rendering.placeholders.jss-motorsport-article-body-table-collection-tables.0',
])
