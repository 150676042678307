import React, { useState, useEffect } from 'react'

import './styles.scss'

const Burger = (props) => {
  const { handleMenu, setHeaderMenuStatus, menuVisible } = props
  const [burgerActive, setBurgerActive] = useState(false)

  useEffect(() => {
    if (menuVisible === false) {
      setBurgerActive(false)
    }
  }, [menuVisible])

  return (
    <div className={`menu-button-container ${burgerActive ? 'is-active' : ''}`}>
      <input type="checkbox" id="menu-toggle" checked={burgerActive} readOnly />
      <label
        htmlFor="menu-toggle"
        onClick={() => {
          setBurgerActive(!burgerActive)
          handleMenu(!burgerActive)
          setHeaderMenuStatus(!burgerActive)
        }}
      >
        <i></i>
        <span></span>
      </label>
      <em></em>
    </div>
  )
}

export default Burger
