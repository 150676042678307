import React, { useState, useEffect } from 'react'

import Image from '../../shared/Image'
import FeaturedBox from '../../shared/FeaturedBox'

import withMinimumRequirements from '../../../withMinimumRequirements'

import { efo, log, getMediaList, isMobile } from '../../../helpers'

import './styles.scss'

const CategoryPageHeader = (props) => {
  const { fields } = props
  const { alignment, headline, text, link, image, imageMobile } = fields

  const [mobile, setMobile] = useState(isMobile())

  const setCustomVh = () => {
    if (typeof window !== 'undefined') {
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }
  }

  useEffect(() => {
    log('Category Page Header', { ...fields })
  }, [fields])

  useEffect(() => {
    const handleResize = () => {
      setMobile(isMobile())
    }
    setCustomVh()
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', setCustomVh)
      window.addEventListener('resize', handleResize)
    }
    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', handleResize)
        window.removeEventListener('resize', setCustomVh)
      }
    }
  }, [])

  return (
    <section className="category-page-header">
      <div className="category-page-header-image-container">
        <Image
          className="category-page-header-image"
          fields={{
            image: mobile ? imageMobile : image,
          }}
          mediaList={getMediaList(760, 1000, 1300, 1760, 1920)}
          cover={true}
        />
        <div
          className={`category-page-header-image-overlay category-page-header-image-overlay-${efo(
            alignment,
            'value'
          )}`}
        />
      </div>
      <div
        className={`category-page-header-text-container category-page-header-text-container-${efo(
          alignment,
          'value'
        )}`}
      >
        <FeaturedBox
          headline={headline}
          text={text}
          link={link}
          iconName="buttonarrow"
          iconWidth={8}
          iconHeight={16}
        />
      </div>
    </section>
  )
}

export default withMinimumRequirements(CategoryPageHeader, [
  'fields.alignment',
  'fields.headline',
  'fields.image',
  'fields.imageMobile',
])
