import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useInView } from 'react-intersection-observer'
import { useTranslation } from 'react-i18next'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'

import withMinimumRequirements from '../../../withMinimumRequirements'
import useInterval from '../../../hooks/useInterval'

import {
  log,
  efo,
  getModulePosition,
  pushGlEvent,
  getDataSourceName,
} from '../../../helpers'

import Icon from '../../shared/Icon'

import './styles.scss'

const getApproval = (enableConsentTool, userCentricsId) => {
  const uc = window.usercentrics
  let approval
  if (uc) {
    if (enableConsentTool) {
      approval = uc.getConsents().find((a) => a.templateId === userCentricsId)
    }
  }
  return approval
}

const getApprovalConsent = (enableConsentTool, approval, language) => {
  if (typeof window !== 'undefined') {
    const uc = window.usercentrics
    if (uc) {
      if (enableConsentTool) {
        if (approval) {
          return efo(approval, 'consentStatus')
        } else {
          return false
        }
      } else {
        return true
      }
    } else {
      if (language === 'en-US') {
        return true
      } else {
        return false
      }
    }
  }
}

const udpateApproval = (approval, setApproval) => {
  window.usercentrics.updateConsents([
    {
      templateId: approval.templateId,
      status: true,
    },
  ])
  setApproval(undefined)
}

const ArticleBodyExternal = (props) => {
  const { fields } = props
  const {
    title,
    width,
    height,
    reloadTime,
    path,
    enableConsentTool,
    userCentricsId,
  } = fields

  const [t] = useTranslation()
  const { sitecoreContext: context } = props
  const { language } = context
  const iframeUrl = efo(path, 'value.url') || efo(path, 'value.href') || ''

  const [key, setKey] = useState(0)
  const [isSportResult, setIsSportResult] = useState(
    iframeUrl.includes('static.sportresult.com')
  )
  const [iframeScriptLoaded, setIframeScriptLoaded] = useState(false)
  const [approval, setApproval] = useState(undefined)
  const [approvalConsent, setApprovalConsent] = useState(false)

  useEffect(() => {
    setApprovalConsent(
      getApprovalConsent(efo(enableConsentTool, 'value'), approval, language)
    )
  }, [approval, enableConsentTool, language])

  useInterval(() => {
    if (typeof window !== 'undefined') {
      const consentToolCheck = efo(enableConsentTool, 'value')
      if (window.usercentrics && window.usercentrics.isInitialized) {
        if (consentToolCheck) {
          setApproval(
            getApproval(consentToolCheck, efo(userCentricsId, 'value'))
          )
        } else {
          setApproval([])
        }
      } else {
        setApproval(false)
      }
    }
  }, 1000)

  useInterval(() => {
    if ((efo(reloadTime, 'value') || 0) > 0) {
      setKey((value) => value + 1)
    }
  }, (efo(reloadTime, 'value') || 0) * 1000)

  const ref = useRef()
  const [inViewRefTrack, inViewTrack] = useInView({
    threshold: 0.3,
  })

  const setRefs = useCallback(
    (node) => {
      ref.current = node
      inViewRefTrack(node)
    },
    [inViewRefTrack]
  )

  useEffect(() => {
    log('Article Body External', { ...fields })
  }, [fields])

  // Tracking load
  useEffect(() => {
    if (typeof window !== 'undefined' && window.GDL) {
      pushGlEvent(
        false,
        'ExternalModule',
        [],
        'ExternalModule',
        getModulePosition('article-body-external-container', ref),
        'ExternalModule',
        efo(props, 'rendering.uid'),
        'ExternalModule'
      )
    }
  }, [props])

  // Tracking in view
  useEffect(() => {
    if (inViewTrack && typeof window !== 'undefined' && window.GDL) {
      pushGlEvent(
        true,
        'ExternalModule',
        [],
        'ExternalModule',
        getModulePosition('article-body-external-container', ref),
        'ExternalModule',
        efo(props, 'rendering.uid'),
        'ExternalModule'
      )
    }
  }, [inViewTrack, props])

  useEffect(() => {
    const scriptSport = document.createElement('script')
    scriptSport.type = 'text/javascript'
    scriptSport.async = true
    scriptSport.src =
      'https://static.sportresult.com/federations/fia/liveresults/porsche/liveResults/iframe_listener.js'
    scriptSport.onload = () => {
      setIframeScriptLoaded(true)
    }
    if (isSportResult) {
      document.body.appendChild(scriptSport)
    }
    return () => {
      if (isSportResult) {
        document.body.removeChild(scriptSport)
      }
    }
  }, [isSportResult])

  return (
    <div
      className="article-body-external-container"
      id={getDataSourceName(efo(props, 'rendering.dataSource'))}
      ref={setRefs}
    >
      <div className="article-body-external-subcontainer">
        {isSportResult ? (
          <iframe
            key={key}
            id="liveStats"
            title={efo(title, 'value')}
            width={efo(width, 'value')}
            height={efo(height, 'value')}
            onLoad={() => {
              registerDomain() // eslint-disable-line no-undef
            }}
            scrolling="no"
            src={
              approvalConsent
                ? iframeScriptLoaded
                  ? iframeUrl
                  : undefined
                : undefined
            }
          />
        ) : (
          <iframe
            key={key}
            title={efo(title, 'value')}
            width={efo(width, 'value')}
            height={efo(height, 'value')}
            src={approvalConsent ? iframeUrl : undefined}
          />
        )}
        {approval && approval.length !== 0 && !approvalConsent ? (
          <div className="general-external-uc-overlay">
            <div className="general-external-uc-overlay-subcontainer">
              <h3>{t('uc-title')}</h3>
              <p>{approval.description || approval.dataPurposesList[0]}</p>
              <div
                className="general-external-uc-overlay-button"
                onClick={(event) => {
                  event.preventDefault()
                  event.stopPropagation()

                  udpateApproval(approval, setApproval)
                }}
              >
                <Icon name="arrowHeadRight" />
                <span>{t('uc-button-agree')}</span>
              </div>
            </div>
          </div>
        ) : undefined}
      </div>
    </div>
  )
}

export default withSitecoreContext()(
  withMinimumRequirements(ArticleBodyExternal, [
    'fields.width.value',
    'fields.height.value',
    {
      mode: 'one-of',
      structure: ['fields.path.value.url', 'fields.path.value.href'],
    },
  ])
)
