import React, { useEffect, useRef, useCallback, useState } from 'react'
import { Placeholder, Text } from '@sitecore-jss/sitecore-jss-react'
import { useInView } from 'react-intersection-observer'

import withMinimumRequirements from '../../../withMinimumRequirements'

import {
  efo,
  log,
  getModulePosition,
  pushGlEvent,
  getDataSourceName,
} from '../../../helpers'

import './styles.scss'

const ArticleBodyTable = (props) => {
  const { fields, rendering, params } = props
  const { title } = fields || {}
  const { subelement, active } = params || {}
  const [mount, setMount] = useState(false)
  const [trackLoad, setTrackLoad] = useState(false)

  const ref = useRef()
  const [inViewRefTrack, inViewTrack] = useInView({
    threshold: 0.3,
  })

  const setRefs = useCallback(
    (node) => {
      ref.current = node
      inViewRefTrack(node)
    },
    [inViewRefTrack]
  )

  useEffect(() => {
    log('Article Body Table', {
      fields: { ...(fields || {}) },
      rendering: { ...rendering },
    })
  }, [fields, rendering])

  useEffect(() => {
    if (!mount) {
      setMount(true)
    }
  }, [mount])

  // Tracking load
  useEffect(() => {
    if (typeof window !== 'undefined' && window.GDL && !trackLoad) {
      pushGlEvent(
        false,
        'Table',
        [],
        'Table',
        getModulePosition('article-body-table-container', ref),
        'Table',
        efo(props, 'rendering.uid'),
        'Table'
      )
      setTrackLoad(true)
    }
  }, [props, trackLoad])

  // Tracking in view
  useEffect(() => {
    if (inViewTrack && typeof window !== 'undefined' && window.GDL) {
      if (
        typeof efo(params, 'active') === 'undefined' &&
        typeof efo(params, 'subelement') === 'undefined'
      ) {
        pushGlEvent(
          true,
          'Table',
          [],
          'Table',
          getModulePosition('article-body-table-container', ref),
          'Table',
          efo(props, 'rendering.uid'),
          'Table'
        )
      }
      if (
        efo(params, 'active') === efo(props, 'rendering.uid') &&
        efo(params, 'subelement') === true
      ) {
        pushGlEvent(
          true,
          'Table',
          [],
          'Table',
          getModulePosition('article-body-table-container', ref),
          'Table',
          efo(props, 'rendering.uid'),
          'Table'
        )
      }
    }
  }, [inViewTrack, props, params])

  return (
    <div
      className="article-body-table-container"
      ref={setRefs}
      id={getDataSourceName(efo(props, 'rendering.dataSource'))}
      data-hide={active && active !== efo(rendering, 'uid') ? '' : undefined}
      data-subelement={subelement ? '' : undefined}
    >
      <Text tag="h3" field={title} />
      <div className="article-body-table-subcontainer">
        <Placeholder
          name="jss-motorsport-article-body-table-sections"
          rendering={rendering}
        />
      </div>
    </div>
  )
}

export default withMinimumRequirements(ArticleBodyTable, [
  'fields.title.value',
  'rendering.placeholders.jss-motorsport-article-body-table-sections.0',
])
