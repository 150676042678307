import React, { useEffect, useContext, useState, useCallback } from 'react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { useTranslation } from 'react-i18next'
import { useLocation, useHistory } from 'react-router-dom'
import decodeUriComponent from 'decode-uri-component'

import SearchResult from '../../shared/SearchResult'
import SearchInput from '../../shared/SearchInput'
import Pagination from '../../shared/Pagination'

import { actions, Store } from '../../../store'

import {
  SEARCH_ANIMATION_DELAY,
  SEARCH_ANIMATION_MAX_DELAY,
  SEARCH_RESULTS_PAGE,
  SEARCH_TEMPLATE_ID,
  SEARCH_MAX_CHARACTERS,
} from '../../../constants'

import { efo, getConfig, sanitizeInput, iOS } from '../../../helpers'

import './styles.scss'

const getEmptySearchResults = () => ({
  results: getEmptyContent(SEARCH_RESULTS_PAGE),
  query: null,
  page: null,
  loading: true,
  totalNumberOfSearchResults: null,
  firstTimeSearch: true,
})

const fetchContent = async (
  targetURL,
  sitename,
  database,
  language,
  page,
  query,
  setSearch
) => {
  fetch(targetURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      siteName: sitename,
      database: database,
      language: language,
      currentPage: page,
      pageSize: SEARCH_RESULTS_PAGE,
      query: query,
    }),
  })
    .then((response) => {
      if (response.ok) {
        return response.json()
      }
      throw new Error('Something went wrong')
    })
    .then((data) => {
      if (data && data.results) {
        setSearch({
          results: data.results,
          totalNumberOfSearchResults: data.totalNumberOfSearchResults,
          loading: false,
          firstTimeSearch: false,
        })
      }
    })
    .catch((error) => {
      console.log(error)
      setSearch({
        results: [],
        totalNumberOfSearchResults: -1,
      })
    })
}

const getDelay = (index = 0) => {
  const delay = (index + 1) * SEARCH_ANIMATION_DELAY
  if (delay > SEARCH_ANIMATION_MAX_DELAY) {
    return SEARCH_ANIMATION_MAX_DELAY
  }
  return delay
}

const getEmptyContent = (num) => {
  const content = []
  for (let i = 0; i < num; i++) {
    content.push({
      loading: true,
      delay: getDelay(i),
    })
  }
  return content
}

const SearchResults = (props) => {
  const { sitecoreContext: context, targetURL } = props
  const { route } = context

  const { t } = useTranslation()

  const { state, dispatch } = useContext(Store)
  const { search } = state.search
  const location = useLocation()
  const history = useHistory()
  const [currentPage, setCurrentPage] = useState(0)
  const [queryValue, setQueryValue] = useState(null)
  const [totalResults, setTotalResults] = useState(-2)

  const sitename = useCallback(efo(context, 'site.name'), [context])
  const database = useCallback(efo(route, 'databaseName'), [route])
  const language = useCallback(efo(context, 'language'), [context])
  const templateId = useCallback(efo(context, 'route.templateId'), [context])

  const searchResults = useCallback(
    search.results ? search : getEmptySearchResults(),
    [search]
  )

  const { loading, totalNumberOfSearchResults, firstTimeSearch } = searchResults

  const setSearch = useCallback(
    (value) => actions.setSearch(dispatch, { value }),
    [dispatch]
  )

  const setQuery = useCallback(
    (value) => actions.setQuery(dispatch, { value }),
    [dispatch]
  )

  const searchQueryURL = useCallback(
    (term = false) => {
      const sanitizedQuery = sanitizeInput(term)
      if (
        sanitizedQuery &&
        sanitizedQuery !== queryValue &&
        sanitizedQuery.trim().length !== 0
      ) {
        history.push({
          search: `?q=${sanitizedQuery}`,
        })
      }
    },
    [history, queryValue]
  )

  useEffect(() => {
    if (
      totalNumberOfSearchResults !== null &&
      typeof totalNumberOfSearchResults !== 'undefined'
    ) {
      setTotalResults(totalNumberOfSearchResults)
    }
  }, [totalNumberOfSearchResults])

  useEffect(() => {
    if (iOS() && typeof window !== 'undefined') {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [])

  useEffect(() => {
    if (location.search && location.search.includes('?q=')) {
      const q = location.search.split('=').pop()
      const sanitizedQ = sanitizeInput(decodeUriComponent(q))
      if (
        sanitizedQ &&
        sanitizedQ.trim().length !== 0 &&
        sanitizedQ.length < SEARCH_MAX_CHARACTERS
      ) {
        setQueryValue(sanitizedQ)
        setQuery(sanitizedQ)
        setCurrentPage(1)
        setTotalResults(-2)
      } else {
        setSearch({
          results: [],
          totalNumberOfSearchResults: -1,
        })
      }
    }
  }, [location.search, setQuery, setSearch])

  useEffect(() => {
    if (
      templateId === SEARCH_TEMPLATE_ID &&
      queryValue === null &&
      location.search === ''
    ) {
      setSearch({
        results: [],
        totalNumberOfSearchResults: -1,
      })
    }
  }, [templateId, queryValue, setSearch, location.search])

  useEffect(() => {
    if (queryValue !== '' && currentPage !== 0) {
      setSearch({
        results: getEmptyContent(SEARCH_RESULTS_PAGE),
        loading: true,
        firstTimeSearch: currentPage <= 1 ? true : false,
      })
      fetchContent(
        targetURL,
        sitename,
        database,
        language,
        currentPage,
        queryValue,
        setSearch
      )
    }
  }, [
    queryValue,
    targetURL,
    currentPage,
    sitename,
    database,
    language,
    setSearch,
  ])

  return (
    <>
      <div className="search-results-container">
        <SearchInput
          onSearch={searchQueryURL}
          queryValue={queryValue ? queryValue : ''}
        />
        {totalResults === 0 || totalResults === -1 ? (
          <h1
            className="search-results-headline search-results-headline-no-results"
            dangerouslySetInnerHTML={{
              __html: t('search-no-results', {
                query: queryValue,
              }),
            }}
          ></h1>
        ) : undefined}
        {totalResults !== 0 && totalResults !== -1 ? (
          <div>
            {!firstTimeSearch ? (
              <h1
                className="search-results-headline"
                dangerouslySetInnerHTML={{
                  __html: t('search-results', {
                    results: totalResults,
                    query: queryValue,
                  }),
                }}
              ></h1>
            ) : (
              <div className="search-results-headline-loading">
                <div className="search-results-headline-loading-bar" />
              </div>
            )}
          </div>
        ) : undefined}
        <div className="search-results-results-container">
          {searchResults.results.map((r, i) => (
            <SearchResult
              key={i}
              loading={r.loading}
              delay={r.delay}
              title={r.title}
              date={r.date}
              content={r.content}
              image={r.image}
              url={r.url}
              language={language}
            />
          ))}
        </div>
        {totalResults ? (
          <div className="search-pagination-container">
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={totalResults}
              pageSize={SEARCH_RESULTS_PAGE}
              onPageChange={(page) => {
                if (!loading) {
                  setCurrentPage(page)
                  setTimeout(
                    () =>
                      document.documentElement.scrollIntoView({
                        behavior: 'smooth',
                      }),
                    100
                  )
                }
              }}
            />
          </div>
        ) : undefined}
      </div>
    </>
  )
}

SearchResults.defaultProps = {
  targetURL: getConfig('website.searchEndpoint'),
}

export default withSitecoreContext()(SearchResults)
