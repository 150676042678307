// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://bit.ly/CRA-PWA

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
)

const registerValidSW = (swUrl, config) => {
  // // NOTE: Only enable if we want to disable service worker completely.
  // // We are going to disable the service worker until we can check
  // // that there are no bugs related
  // navigator.serviceWorker.ready.then((registration) => {
  //   registration.unregister().then(() => {
  //     window.location.reload()
  //   })
  // })
  // // Delete caches as well
  // caches.keys().then((cacheNames) => {
  //   cacheNames.forEach((cacheName) => {
  //     caches.delete(cacheName)
  //   })
  // })

  navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {
      // eslint-disable-next-line
      registration.onupdatefound = () => {
        const installingWorker = registration.installing
        if (installingWorker == null) {
          return
        }
        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            // At this point, the updated precached content has been fetched.
            // Workbox webpack plugin is configured to "skip waiting" so,
            // the new content will be served instantly.
            if (navigator.serviceWorker.controller) {
              // Execute callback
              if (config && config.onUpdate) {
                config.onUpdate(registration)
              }
              // At this point, everything has been precached.
              // It's the perfect time to display a
              // "Content is cached for offline use." message.
            } else if (config && config.onSuccess) {
              config.onSuccess(registration)
            }
          }
        }
      }
    })
    .catch((e) => {
      console.log('registering service worker', e)
    })
}

const checkValidServiceWorker = (swUrl, config) => {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl, {
    headers: { 'Service-Worker': 'script' },
  })
    .then((response) => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get('content-type')
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            window.location.reload()
          })
        })
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl, config)
      }
    })
    .catch((e) => {
      console.log('checking valid service worker', e)
    })
}

const register = (config) => {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      const swUrl = '/service-worker.js'

      if (isLocalhost) {
        // This is running on localhost.
        // Let's check if a service worker still exists or not.
        checkValidServiceWorker(swUrl, config)
      } else {
        // Is not localhost. Just register service worker
        registerValidSW(swUrl, config)
      }
    })
  }
}

const unregister = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister()
      })
      .catch(() => {})
  }
}

export default {
  register,
  unregister,
}
