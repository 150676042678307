import React, { useRef, useState, useCallback, useEffect } from 'react'
import ReactPlayer from 'react-player/lazy'
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react'
import { useInView } from 'react-intersection-observer'

import withMinimumRequirements from '../../../withMinimumRequirements'

import {
  efo,
  log,
  getModulePosition,
  pushGlEvent,
  getDataSourceName,
} from '../../../helpers'

import './styles.scss'

const ArticleBodyScrollSectionItem = (props) => {
  const { fields, params, rendering } = props
  const { title, text } = fields
  const { active, move, setActive, setMove } = params
  const { uid: id } = rendering

  const containerRef = useRef()
  const mediaContainerRef = useRef()
  const dataContainerRef = useRef()
  const [mount, setMount] = useState(false)
  const [inViewRefContainer, inViewContainer] = useInView()
  const [inViewRefData, inViewData] = useInView()
  const [inViewRefTrack, inViewTrack] = useInView({
    threshold: 0.3,
  })

  const [opacity, setOpacity] = useState(1)

  const finalVideo =
    efo(fields, 'videofile.value.src') ||
    efo(fields, 'videourl.value.url') ||
    efo(fields, 'videourl.value.href') ||
    ''
  const finalImage =
    finalVideo && finalVideo.includes('.jpg')
      ? {
          value: {
            src: finalVideo,
          },
        }
      : efo(fields, 'image')
  const hasVideo = finalVideo && !finalVideo.includes('.jpg')

  const setRefsContainer = useCallback(
    (node) => {
      containerRef.current = node
      inViewRefContainer(node)
      inViewRefTrack(node)
    },
    [inViewRefContainer, inViewRefTrack]
  )
  const setRefsData = useCallback(
    (node) => {
      dataContainerRef.current = node
      inViewRefData(node)
    },
    [inViewRefData]
  )

  const scrollHandler = () =>
    requestAnimationFrame(() => {
      if (
        containerRef &&
        containerRef.current &&
        mediaContainerRef &&
        mediaContainerRef.current &&
        dataContainerRef &&
        dataContainerRef.current
      ) {
        const {
          height: vHeight,
        } = mediaContainerRef.current.getBoundingClientRect()
        const {
          top,
          bottom,
          height: dHeight,
        } = dataContainerRef.current.getBoundingClientRect()

        if (top < vHeight) {
          setOpacity(
            Number(
              bottom < vHeight
                ? 0.3
                : ((bottom - vHeight) / dHeight) * 0.7 + 0.3
            ).toFixed(2)
          )
        } else if (opacity !== 1) {
          setOpacity(1)
        }
      }
    })

  useEffect(() => {
    log('Article Body Scroll Sections > Item', { ...fields })
  }, [fields])

  useEffect(() => {
    if (!mount) {
      setMount(true)
    }
  }, [mount])

  // Tracking load
  useEffect(() => {
    let checkGDL = setInterval(() => {
      if (typeof window !== 'undefined' && !window.GDL) return
      clearInterval(checkGDL)
      if (typeof window !== 'undefined' && window.GDL && !mount) {
        pushGlEvent(
          false,
          'ScrollSection',
          [],
          'ScrollSection',
          getModulePosition(
            'article-body-scroll-sections-content-container',
            containerRef
          ),
          'ScrollSection',
          efo(rendering, 'uid'),
          'ScrollSection'
        )
      }
    }, 100)
  }, [fields, rendering, mount])

  // Tracking in view
  useEffect(() => {
    //console.log('renderuid', efo(rendering, 'uid'))
    if (
      inViewTrack &&
      typeof window !== 'undefined' &&
      window.GDL &&
      active.indexOf(efo(rendering, 'uid')) !== -1
    ) {
      pushGlEvent(
        true,
        'ScrollSection',
        [],
        'ScrollSection',
        getModulePosition(
          'article-body-scroll-sections-content-container',
          containerRef
        ),
        'ScrollSection',
        efo(rendering, 'uid'),
        'ScrollSection'
      )
    }
  }, [inViewTrack, fields, rendering, active])

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler)
    return () => window.removeEventListener('scroll', scrollHandler)
  })

  useEffect(() => {
    if (inViewData && active.find((a) => a === id) === undefined) {
      setActive([...active, id])
    } else if (!inViewData && active.find((a) => a === id) !== undefined) {
      setActive(active.filter((l) => l !== id))
    }
  }, [inViewData, id, active, setActive])

  useEffect(() => {
    let timeout

    if (move !== undefined && move === id) {
      if (containerRef && containerRef.current) {
        containerRef.current.scrollIntoView()
      }

      timeout = setTimeout(() => {
        setMove(undefined)
        setActive([id])
      }, 100)
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [id, move, setMove, setActive])

  return (
    <div
      ref={setRefsContainer}
      className="article-body-scroll-sections-content-container"
      id={getDataSourceName(efo(props, 'rendering.dataSource'))}
    >
      <div
        ref={mediaContainerRef}
        className="article-body-scroll-sections-content-media-container"
        style={{
          opacity,
        }}
      >
        {hasVideo ? (
          <ReactPlayer
            className="article-body-scroll-sections-content-video-element"
            url={
              efo(fields, 'videofile.value.src') ||
              efo(fields, 'videourl.value.url') ||
              efo(fields, 'videourl.value.href')
            }
            playing={inViewContainer}
            width="100%"
            height="100%"
            muted
            loop
          />
        ) : (
          <div
            className="article-body-scroll-sections-content-image-element"
            style={{
              backgroundImage: `url("${efo(finalImage, 'value.src')}")`,
            }}
          />
        )}
      </div>
      {title || text ? (
        <div
          ref={setRefsData}
          className="article-body-scroll-sections-content-data-container"
        >
          {title ? <Text tag="h3" field={title} /> : undefined}
          {text ? (
            <RichText
              className="article-body-scroll-sections-content-text-container"
              field={text}
            />
          ) : undefined}
        </div>
      ) : undefined}
    </div>
  )
}

export default withMinimumRequirements(ArticleBodyScrollSectionItem, [
  'fields.title.value',
  {
    mode: 'one-of',
    structure: [
      'fields.videofile.value.src',
      'fields.videourl.value.url',
      'fields.videourl.value.href',
      'fields.image.value',
    ],
  },
])
