import React, { useState, useRef, useEffect, useCallback } from 'react'
import { useInView } from 'react-intersection-observer'
import ReactPlayer from 'react-player/lazy'
import screenfull from 'screenfull'
import { v4 } from 'uuid'

import withMinimumRequirements from '../../../withMinimumRequirements'
import Icon from '../Icon'
import Image from '../Image'

import { getMediaList, getRatio, efo, log } from '../../../helpers'

import './styles.scss'
import useApprovalConsent from '../../../hooks/useApprovalConsent'

const Video = (props) => {
  log('Video::props_____________________', props)
  const { url, image, imageField, options = {}, isHovering, isTeaser } = props
  const {
    aspectRatio: ratio,
    autoplay,
    playOnHover,
    loop,
    muted,
    livestream,
  } = options

  const finalURL = efo(url, 'href') || url

  const [inViewRef, inView] = useInView({
    triggerOnce: false,
  })

  const videoContainerRef = useRef()
  const videoRef = useRef()
  const videoViewRef = useRef(inView)

  const [videoURL, setVideoURL] = useState(finalURL)
  const [ready, setReady] = useState(false)
  const [playing, setPlaying] = useState(autoplay || livestream)
  const [pip, setPiP] = useState(false)
  const [fullscreen, setFullScreen] = useState(false)
  const [neverPlayed, setNeverPlayed] = useState(true)
  const [progress, setProgress] = useState(0)
  const [progressChanged, setProgressChanged] = useState(false)
  const [progressChanging, setProgressChanging] = useState(false)
  const [timeOut, setTimeOut] = useState(undefined)
  const [hideOnFullscreen, setHideOnFullscreen] = useState(false)
  const [playingWhileChanging, setPlayingWhileChanging] = useState(undefined)
  const [isMuted, setIsMuted] = useState(
    autoplay || playOnHover || livestream || muted
  )
  const [isHovered, setIsHovered] = useState(false)
  const [isYouTube] = useState(
    videoURL &&
      (videoURL.includes('youtube.com') || videoURL.includes('youtu.be'))
  )
  const [uuidForPS, setUuidForPS] = useState('')
  //log('Video::videoURL_________________', videoURL)

  useEffect(() => {
    setUuidForPS(v4())
  }, [])

  const ucProcesssors = props.UC_PROCESSOR || {}
  const ucProcessorEmpty = Object.keys(ucProcesssors).length === 0
  const document = typeof window === 'undefined' ? undefined : window.document
  const videoDataProcessorList = ucProcessorEmpty
    ? [{}]
    : [
        {
          vimeo: ucProcesssors.vimeo,
          youtube: ucProcesssors.youtubevideo || ucProcesssors.youtube,
        },
      ]
  const psEle = document
    ? document.getElementById(`youtube-iframe-privacy-shield-${uuidForPS}`)
    : undefined

  const approvalConsent = useApprovalConsent(
    finalURL,
    psEle,
    videoDataProcessorList,
    props.UC_READY
  )

  const needsConsent =
    videoDataProcessorList.length > 0 &&
    (videoURL.includes('youtube.com') ||
      videoURL.includes('youtu.be') ||
      videoURL.includes('vimeo'))
  const allowedToPlay = (approvalConsent && needsConsent) || !needsConsent

  const isLoop = playOnHover || loop
  const isPaused = !playing && !progressChanging
  const isAutoplay = autoplay || livestream
  const showImage = isAutoplay || playOnHover ? false : neverPlayed
  const showControls = () => {
    if (
      videoURL &&
      (videoURL.includes('youtube.com') || videoURL.includes('youtu.be')) &&
      livestream
    ) {
      return true
    }
    if (isAutoplay || playOnHover) {
      return false
    }
    return undefined
  }
  const showPiP =
    typeof document !== 'undefined'
      ? ReactPlayer.canEnablePIP(videoURL) && !fullscreen
      : false
  const videoDuration =
    videoRef && videoRef.current ? videoRef.current.getDuration() : undefined
  const showVideo =
    livestream || playOnHover
      ? approvalConsent
      : approvalConsent && !neverPlayed

  useEffect(() => {
    if (
      isAutoplay &&
      !playing &&
      !videoViewRef.current &&
      inView &&
      !livestream
    ) {
      setPlaying(true)
    }

    if (videoViewRef.current !== inView) {
      videoViewRef.current = inView
    }
  }, [inView, isAutoplay, playing, livestream])

  useEffect(() => {
    if (finalURL !== videoURL) {
      setVideoURL(finalURL)
    }
  }, [finalURL, videoURL])

  useEffect(() => {
    if (ready) {
      if (!playing && isHovering && playOnHover) {
        setPlaying(true)
      }
      if (playing && !isHovering && playOnHover) {
        setPlaying(false)
      }
    }
  }, [ready, isHovering, playOnHover, playing])

  useEffect(() => {
    if (progressChanged && videoRef && videoRef.current) {
      videoRef.current.seekTo(progress, 'seconds')
      setProgressChanged(false)
    }
  }, [progress, progressChanged])

  useEffect(() => {
    if (!fullscreen && hideOnFullscreen) {
      setHideOnFullscreen(false)
    }
  }, [fullscreen, hideOnFullscreen])

  useEffect(() => {
    const listener = () => {
      clearTimeout(timeOut)
      if (hideOnFullscreen) {
        setHideOnFullscreen(false)
      }
      setTimeOut(setTimeout(() => setHideOnFullscreen(true), 2000))
    }

    window.addEventListener('mousemove', listener)
    return () => {
      window.removeEventListener('mousemove', listener)
      if (timeOut) {
        clearTimeout(timeOut)
      }
    }
  }, [timeOut, hideOnFullscreen])

  useEffect(() => {
    if (screenfull.isEnabled) {
      const listener = () => {
        setFullScreen(screenfull.isFullscreen)
      }

      screenfull.on('change', listener)
      return () => screenfull.off('change', listener)
    }
  }, [])

  const setRefs = useCallback(
    (node) => {
      videoContainerRef.current = node
      inViewRef(node)
    },
    [inViewRef]
  )

  const [cssClass, setCssClass] = useState('')
  useEffect(() => {
    if (allowedToPlay === true) {
      setCssClass('general-video__consent--given')
    }
  }, [allowedToPlay])

  return (
    <>
      {
        //console.log(showVideo, approvalConsent, allowedToPlay)
      }
      {!showVideo && isTeaser && (
        <div
          id={`youtube-iframe-privacy-shield-${uuidForPS}`}
          className={cssClass}
        />
      )}
      <div
        ref={setRefs}
        className="general-video-container"
        style={{
          paddingTop: `${100 * getRatio(fullscreen ? undefined : ratio)}%`,
        }}
        data-fullscreen-hide={hideOnFullscreen ? '' : undefined}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={(event) => {
          event.preventDefault()
          event.stopPropagation()
          setPlaying(!playing)
          setNeverPlayed(false)
        }}
      >
        {!showVideo && !isTeaser && (
          <div
            id={`youtube-iframe-privacy-shield-${uuidForPS}`}
            className={cssClass}
          />
        )}
        {image && videoURL ? (
          <>
            {approvalConsent && (showVideo || isAutoplay) ? (
              <ReactPlayer
                ref={videoRef}
                className="general-video-player"
                url={videoURL}
                playing={inView ? playing : false}
                loop={isLoop}
                controls={showControls()}
                muted={isMuted}
                width="100%"
                height="100%"
                pip={pip}
                stopOnUnmount={false}
                playIcon={playOnHover ? null : undefined}
                style={{
                  backgroundColor: playing || playOnHover ? 'black' : undefined,
                }}
                progressInterval={100}
                onReady={() => setReady(true)}
                onPlay={() => setPlaying(true)}
                onPause={() => setPlaying(false)}
                onProgress={({ playedSeconds }) => setProgress(playedSeconds)}
                playsinline
                config={{
                  file: {
                    attributes: {
                      playsInline: true,
                    },
                  },
                }}
              />
            ) : undefined}
            {!isAutoplay ? (
              <Image
                className="general-video-image"
                fields={{
                  image: imageField,
                }}
                src={image.src}
                width={image.width}
                height={image.height}
                alt={image.alt}
                thumb={image.base64String}
                mediaList={isTeaser ? getMediaList(400) : getMediaList()}
                options={{
                  'data-show': showImage || !approvalConsent ? '' : undefined,
                }}
                cover
              />
            ) : undefined}
            {approvalConsent && !playOnHover && !muted ? (
              <div
                className="general-video-icon-mute"
                data-muted={isMuted ? '' : undefined}
                onClick={(event) => {
                  if (isMuted) {
                    event.preventDefault()
                    event.stopPropagation()
                    setIsMuted(false)
                  }
                }}
              >
                <Icon name="volumeOff" size={30} />
              </div>
            ) : undefined}
            {approvalConsent && !playOnHover ? (
              <>
                <div></div>
                <div
                  className="general-video-icon-play"
                  data-show={isPaused ? '' : undefined}
                  data-livestream={livestream ? '' : undefined}
                >
                  {livestream ? 'LIVE' : <Icon name="play" size={42} />}
                </div>
                {!livestream && (
                  <div
                    className="general-video-bottom-icon-container"
                    data-show={
                      !neverPlayed && (isPaused || isHovered) ? '' : undefined
                    }
                    data-is-youtube={isYouTube ? '' : undefined}
                    data-paused={isPaused ? '' : undefined}
                    data-progress-changing={progressChanging ? '' : undefined}
                    data-fullscreen-hide={hideOnFullscreen ? '' : undefined}
                  >
                    {showPiP && (
                      <Icon
                        name="external"
                        size={25}
                        color="white"
                        className="general-video-icon-pip"
                        data-is-pip-enabled={pip ? '' : undefined}
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          setPiP(!pip)
                        }}
                      />
                    )}
                    {screenfull.isEnabled ? (
                      <Icon
                        name={fullscreen ? 'zoomIn' : 'zoomOut'}
                        size={25}
                        color="white"
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          if (!fullscreen) {
                            setPiP(false)
                            screenfull.request(videoContainerRef.current)
                          } else {
                            screenfull.exit()
                          }
                        }}
                      />
                    ) : undefined}
                  </div>
                )}
              </>
            ) : undefined}
            {!neverPlayed && approvalConsent && !livestream && !playOnHover ? (
              <div
                className="general-video-duration"
                data-fullscreen-hide={hideOnFullscreen ? '' : undefined}
              >
                <progress
                  data-progress-changing={progressChanging ? '' : undefined}
                  max={videoDuration}
                  value={progress}
                />
                <input
                  max={videoDuration}
                  min="0"
                  step="any"
                  type="range"
                  value={progress}
                  onMouseDown={() => {
                    setProgressChanging(true)
                    setPlayingWhileChanging(playing)
                    setPlaying(false)
                  }}
                  onMouseUp={() => {
                    setProgressChanging(false)
                    setPlaying(playingWhileChanging)
                    setPlayingWhileChanging(undefined)
                  }}
                  onChange={(event) => {
                    event.preventDefault()
                    event.stopPropagation()
                    setProgress(event.target.value)
                    setProgressChanged(true)
                  }}
                  onInput={(event) => {
                    event.preventDefault()
                    event.stopPropagation()
                    setProgress(event.target.value)
                    setProgressChanged(true)
                  }}
                  onClick={(event) => {
                    event.preventDefault()
                    event.stopPropagation()
                  }}
                />
              </div>
            ) : undefined}
          </>
        ) : (
          <div className="general-video-lazy-image">
            <svg width="160" height="90" />
          </div>
        )}
      </div>
    </>
  )
}

export default withMinimumRequirements(Video, ['url'])
