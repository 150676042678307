import SwiperCore, { Navigation, Pagination, Controller } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.scss'
// ----
import './styles.scss'

SwiperCore.use([Pagination, Navigation, Controller])

export { SwiperSlide as Slide }

const Slider = Swiper

export default Slider
