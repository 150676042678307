import React, { useEffect, useState } from 'react'
import {
  Placeholder,
  Text,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react'
import { useTranslation } from 'react-i18next'
import { NavLink, useLocation } from 'react-router-dom'

import Icon from '../../shared/Icon'
import BackToOverview from '../../shared/BackToOverview'

import {
  log,
  efo,
  getKey,
  getShareFacebook,
  getShareTwitter,
  getCorrectURL,
} from '../../../helpers'

import '../ArticleLayout/styles.scss'

const getVerticalSplit = (rendering) => {
  const heroModules = efo(rendering, 'placeholders.jss-motorsport-content-hero')
  const heroModule = heroModules && heroModules[0]

  if (heroModule && heroModule.componentName === 'ArticleHeroMedia') {
    const image = efo(heroModule, 'fields.image.value')
    const videoAspectRatio = efo(
      heroModule,
      'fields.video.fields.aspectRatio.value'
    )

    if (image && Number(image.width) <= Number(image.height)) {
      return true
    }

    if (videoAspectRatio) {
      const values = videoAspectRatio.split(':')

      return Number(values[0]) <= Number(values[1])
    }
  }

  return false
}

const getTargetURL = (mounted) => {
  if (typeof window !== 'undefined' && mounted) {
    return window.location.href
  }

  return ''
}

const getTags = (tags) =>
  tags.filter((t) => {
    const isCategory = efo(t, 'fields.isCategory.value')
    const categoryValue =
      efo(t, 'fields.categoryPage.value.href') ||
      efo(t, 'fields.categoryPage.value.url')

    if (isCategory && categoryValue && categoryValue.endsWith('alltopics')) {
      return false
    }
    return true
  })

const ContentLayout = (props) => {
  const { rendering, sitecoreContext } = props
  const fields = efo(sitecoreContext, 'route.fields')
  const masterCategory = efo(fields, 'masterCategory')
  const { title, tags /*, likes*/ } = fields || {}

  const [t] = useTranslation()
  const location = useLocation()

  const [mount, setMount] = useState(false)
  // const [liked, setLiked] = useState(false)
  const [verticalSplit] = useState(getVerticalSplit(rendering))

  useEffect(() => {
    log('Content Layout', { ...fields })
  }, [fields])

  useEffect(() => {
    if (!mount) {
      setMount(true)
    }
  }, [mount, location])

  useEffect(() => {
    const hash = location.hash
    if (hash !== '') {
      let retries = 0
      const id = hash.replace('#', '')
      const scroll = () => {
        retries += 1
        if (retries > 50) return
        const element = document.getElementById(id)
        if (element) {
          setTimeout(() => element.scrollIntoView(), 0)
        } else {
          setTimeout(scroll, 100)
        }
      }
      scroll()
    }
  }, [location])

  return (
    <>
      <div
        className="article-hero-container"
        data-vertical-split={verticalSplit ? '' : undefined}
      >
        <BackToOverview masterCategory={masterCategory} />
        <div className="article-hero-component-container">
          <Placeholder
            name="jss-motorsport-content-hero"
            rendering={rendering}
          />
        </div>
        <div className="article-hero-content-container">
          <Text tag="h1" field={title} />
        </div>
      </div>
      <div>
        <Placeholder name="jss-motorsport-content-body" rendering={rendering} />
      </div>
      <div className="article-share-like-container">
        <div className="article-share-container">
          <div className="article-share-options">
            <a
              className="article-share-options--facebook"
              href={getShareFacebook(getTargetURL(mount), mount)}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(event) => {
                event.stopPropagation()
              }}
            >
              <Icon name="logoFacebook" size={25} />
            </a>
            <a
              className="article-share-options--twitter"
              href={getShareTwitter(getTargetURL(mount), mount, title, tags)}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(event) => {
                event.stopPropagation()
              }}
            >
              <Icon name="logoTwitter" size={25} />
            </a>
          </div>
          <div className="article-share-button">
            <Icon name="share" size={25} />
            <span>{t('article-share')}</span>
          </div>
        </div>
        {/* <div
          className="article-like-container"
          onClick={(event) => {
            event.preventDefault()
            event.stopPropagation()
            // TODO: Add like of this teaser
            setLiked(!liked)
          }}
          data-liked={liked ? '' : undefined}
        >
          <svg viewBox="0 0 24 20">
            <g transform="translate(1, 1)">
              <path d="M16.40625,0.307309739 C14.896875,0.307309739 13.51875,1.0210502 12.665625,2.25387462 C11.8125,3.48669905 10.5,5.23860745 10.5,5.23860745 C10.5,5.23860745 9.253125,3.42181356 8.334375,2.18898913 C7.48125,0.9561647 6.0375,0.242424242 4.59375,0.242424242 C2.034375,0.242424242 0,2.25387462 0,4.78440898 C0,6.0172334 0.525,7.25005783 1.44375,8.09356928 C2.49375,9.06685172 7.678125,14.3225769 10.5,17.2424242 C13.321875,14.3225769 18.50625,9.06685172 19.55625,8.09356928 C20.475,7.25005783 21,6.0821189 21,4.78440898 C21,2.31876012 18.965625,0.307309739 16.40625,0.307309739 Z" />
            </g>
          </svg>
          <span>
            {Number(likes && likes.value ? likes.value : 0) + (liked ? 1 : 0)}
          </span>
        </div> */}
      </div>
      {tags || masterCategory ? (
        <div className="article-tags-container">
          {masterCategory && (
            <div className="article-tag-container">
              <NavLink
                to={{
                  pathname: getCorrectURL(
                    `${efo(masterCategory, 'fields.categoryPage.value.href')}`
                  ),
                  state: {
                    layoutID: efo(
                      masterCategory,
                      'fields.categoryPage.value.id'
                    ),
                  },
                }}
              >
                {efo(masterCategory, 'fields.name.value')}
              </NavLink>
            </div>
          )}
          {getTags(tags).map((tag, index) => {
            const isCategory = efo(tag, 'fields.isCategory.value')
            const categoryValue =
              efo(tag, 'fields.categoryPage.value.href') ||
              efo(tag, 'fields.categoryPage.value.url')
            const nameValue = efo(tag, 'fields.name.value')

            return (
              <div
                key={getKey(tag.id, index)}
                className="article-tag-container"
              >
                <NavLink
                  to={{
                    pathname: getCorrectURL(
                      isCategory
                        ? categoryValue && categoryValue.includes('/category/')
                          ? categoryValue
                          : `/category/${categoryValue}`
                        : `/?filter=${nameValue}` || '/'
                    ),
                    state: {
                      layoutID: efo(tag, 'fields.categoryPage.value.id'),
                    },
                  }}
                >
                  {nameValue}
                </NavLink>
              </div>
            )
          })}
        </div>
      ) : undefined}
      <Placeholder name="jss-motorsport-content-footer" rendering={rendering} />
    </>
  )
}

export default withSitecoreContext()(ContentLayout)
