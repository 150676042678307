export const DEFAULT_ID = 'available-in-connected-mode'
export const DEFAULT_DATABASE = 'available-in-connected-mode'
export const DEFAULT_TAG_ID = '{E0E4E074-D7D6-4114-9215-91900D41E0F3}'

export const DEFAULT_TEASER_TYPE = 'Teaser'
export const TEASER_TYPE_VIDEO = 'VideoTeaser'
export const TEASER_TYPE_GALLERY = 'GalleryTeaser'
export const TEASER_TYPE_COUNTDOWN = 'CountdownTeaser'
export const TEASER_TYPE_VIDEO_SERIES = 'VideoSeriesTeaser'
export const TEASER_TYPE_QUIZ = 'QuizTeaser'
export const TEASER_TYPE_RACE_CALENDAR = 'RaceCalendarTeaser'
export const TEASER_TYPE_POLL = 'PollTeaser'
export const TEASER_TYPE_AUTOGRAPHS = 'AutographsTeaser'
export const TEASER_TYPE_AUDIO = 'AudioTeaser'
export const TEASER_TYPE_STORY = 'StoryTeaser'
export const TEASER_TYPE_SOCIAL_MEDIA = 'SocialMediaTeaser'
export const TEASER_TYPE_FANBOOST = 'FanboostTeaser'

export const LOCALSTORAGE_COUNTRY_KEY = 'country'

export const IMAGE_QUALITY_MULTIPLIER = 1

export const GRID_BREAKPOINTS = {
  default: 4,
  1900: 3,
  1300: 2,
  760: 1,
}

export const BREAKPOINT_PORTRAIT = 999

export const BREAKPOINT_MOBILE = Number(
  Object.keys(GRID_BREAKPOINTS).find((key) => GRID_BREAKPOINTS[key] === 1)
)

export const STATIC_TEASER_NUMBER = 6
export const STATIC_TEASER_SIZE_DESKTOP = 282
export const STATIC_TEASER_SIZE_MOBILE = 182
export const STATIC_TEASER_MARGIN = 3

export const TEASER_NUMBER = 15
export const TEASER_ANIMATION_DELAY = 100
export const TEASER_ANIMATION_MAX_DELAY = 1000
export const TEASER_ASPECT_RATIO = '16:9'

export const ARTICLE_TYPE_TEXT = 'ArticleText'
export const ARTICLE_TYPE_3D = 'Article3D'
export const ARTICLE_TYPE_PARTNER = 'ArticlePartner'
export const ARTICLE_TYPE_MEDIA = 'ArticleMedia'
export const ARTICLE_TYPE_GALLERY = 'ArticleGallery'

export const SKETCHFAB_VIEWER_DEFAULT_RATIO = '16:9'
export const SKETCHFAB_VIEWER_MOBILE_RATIO = '1:1'

export const RACE_CALENDAR_SHOWN_ITEMS = 4
export const RACE_CALENDAR_STATUS_DEFAULT = 'default'
export const RACE_CALENDAR_STATUS_CANCELED = 'canceled'
export const RACE_CALENDAR_STATUS_SUSPENDED = 'suspended'

export const COUNTDOWN_TEASER_PARTNER_IMAGE_HEIGHT = '40'
export const COUNTDOWN_ARTICLE_PARTNER_IMAGE_HEIGHT = '96'
export const TIME_EVENT = 'TIME_CHANGED'

export const DRIVERS_LAYOUT_GRID = 'grid'
export const DRIVERS_LAYOUT_SLIDER = 'slider'

export const SEARCH_ANIMATION_DELAY = 100
export const SEARCH_ANIMATION_MAX_DELAY = 1000
export const SEARCH_RESULTS_PAGE = 4
export const SEARCH_TEMPLATE_ID = 'faa728b4-f0ad-4a51-890c-86b029727067'
export const SEARCH_FALLBACK_IMAGE = '/assets/img/fallback-search-result.png'
export const SEARCH_MAX_CHARACTERS = 70

export const AUDIO_PLAYER_OPTIONS = {
  gap: 1,
  meterCount: 512,
  meterWidth: 2,
  capHeight: 2,
  capColor: '#d5001c',
}

export const ADD_TO_CALENDAR_OPTIONS = [
  {
    name: 'Google Calendar',
    action: (
      startDate,
      endDate,
      eventLocation,
      eventTitle,
      eventDescription,
      parseDate
    ) => {
      const url = encodeURI(
        `https://calendar.google.com/calendar/render?action=TEMPLATE&dates=${parseDate(
          startDate
        )}/${parseDate(
          endDate
        )}&location=${eventLocation}&text=${eventTitle}&details=${eventDescription}`
      )

      window.open(url, '_blank')
    },
  },
  {
    name: 'Apple iCal',
    action: (
      startDate,
      endDate,
      eventLocation,
      eventTitle,
      eventDescription,
      parseDate
    ) => {
      const url = `data:application/octet-stream,BEGIN:VCALENDAR\nVERSION:2.0\nBEGIN:VEVENT\nURL:${
        window.location.href
      }\nMETHOD:PUBLISH\nDTSTART:${parseDate(startDate)}\nDTEND:${parseDate(
        endDate
      )}\nSUMMARY:${eventTitle}\nDESCRIPTION:${eventDescription}\nLOCATION:${eventLocation}\nEND:VEVENT\nEND:VCALENDAR`

      const anchor = document.createElement('a')
      anchor.href = url
      anchor.target = '_blank'
      anchor.download = 'event.ics'

      anchor.click()
    },
  },
  {
    name: 'Outlook',
    action: (
      startDate,
      endDate,
      eventLocation,
      eventTitle,
      eventDescription,
      parseDate
    ) => {
      const url = `data:application/octet-stream,BEGIN:VCALENDAR\nVERSION:2.0\nBEGIN:VEVENT\nURL:${
        window.location.href
      }\nMETHOD:PUBLISH\nDTSTART:${parseDate(startDate)}\nDTEND:${parseDate(
        endDate
      )}\nSUMMARY:${eventTitle}\nDESCRIPTION:${eventDescription}\nLOCATION:${eventLocation}\nEND:VEVENT\nEND:VCALENDAR`

      const anchor = document.createElement('a')
      anchor.href = url
      anchor.target = '_blank'
      anchor.download = 'event.ics'

      anchor.click()
    },
  },
]

export const LOGGER_ALLOWED_SITES = [
  'http://localhost:3000',
  'http://motorsport-hub.dev.local',
  'https://motorsport-hub.dev.local',
  'https://motorsportdev.westeurope.cloudapp.azure.com',
  'https://motorsporttest.westeurope.cloudapp.azure.com',
  'https://qa.eu.porsche.com',
  'https://qa.sea.porsche.com',
  'https://qa.wus.porsche.com',
  'https://int.eu.porsche.com',
  'https://int.sea.porsche.com',
  'https://int.wus.porsche.com',
  'https://prod.eu.porsche.com',
  'https://prod.sea.porsche.com',
  'https://prod.wus.porsche.com',
  'https://prod.cm.eu.porsche.com',
  'https://prod.cm.sea.porsche.com',
  'https://prod.cm.wus.porsche.com',
  'https://cd.staging.pcom.weu.porsche.com',
]

export const SEO_APP_NAME = 'Porsche Motorsport Hub'
export const SEO_MAIN_CATEGORY = '/category/alltopics'
export const SEO_TOPIC = 'news'
export const SEO_ORGANIZATION = 'Dr. Ing. h.c. F. Porsche AG'

export const SOCIAL_IMAGE_DEFAULT_WIDTH = 1200
export const SOCIAL_IMAGE_DEFAULT_HEIGHT = 630
export const SOCIAL_IMAGE_DEFAULT_RATIO = 1.91
export const SOCIAL_IMAGE_DEFAULT_RATIO_MARGIN = 0.2
export const SOCIAL_IMAGE_DEFAULT_FILE =
  '/assets/img/motorsport-og-image-1200x630.jpg'
export const TWITTER_DEFAULT_CARD = 'summary_large_image'
