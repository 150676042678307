import React, { useRef, useState } from 'react'
import {
  Text,
  Image as SitecoreImage,
  isEditorActive,
} from '@sitecore-jss/sitecore-jss-react'
import { useInView } from 'react-intersection-observer'

import DynamicContainer from '../DynamicContainer'

import { efo, getSitecoreImage, getConfig } from '../../../helpers'
import { IMAGE_QUALITY_MULTIPLIER } from '../../../constants'

import './styles.scss'

const getThumb = (src, thumb) =>
  thumb && src
    ? `data:image/${
        src.split('.').pop().split(/#|\?/)[0]
      };charset=utf-8;base64, ${thumb}`
    : undefined

const getSrc = (rawSrc) => {
  let src = rawSrc

  const mediaUrlPrefix = /\/([-~]{1})\/media\//i
  const match = mediaUrlPrefix.exec(src)
  const proxy = getConfig('proxy.imageURL')

  if (match && match.length > 1) {
    // regex will provide us with /-/ or /~/ type
    src = src.replace(mediaUrlPrefix, `/${match[1]}/jssmedia/`)
  }

  if (proxy && src && !src.startsWith('http')) {
    src = proxy + src
  }

  return src
}

const getProps = (props) => {
  let {
    fields,
    width,
    height,
    src,
    base64String,
    lowSrc,
    alt,
    thumb,
    ratio,
  } = props
  let { image } = fields

  const fieldSrc = efo(image, 'value.src')
  if (src === undefined && fieldSrc) {
    src = fieldSrc
  }

  const fieldAlt = efo(image, 'value.alt')
  if (alt === undefined && fieldAlt) {
    alt = fieldAlt
  }

  const fieldWidth = efo(image, 'value.width')
  if (width === undefined) {
    width = fieldWidth || 'auto'
  }

  const fieldHeight = efo(image, 'value.height')
  if (height === undefined) {
    height = fieldHeight || 'auto'
  }

  const fieldThumb =
    efo(image, 'value.thumb') ||
    efo(image, 'value.base64String') ||
    efo(image, 'value.lowSrc') ||
    base64String ||
    lowSrc
  if (thumb === undefined) {
    thumb = fieldThumb
  }

  if (ratio === undefined) {
    ratio = Number(height) / Number(width)
  }

  src = getSrc(src)
  thumb = getThumb(src, thumb)

  return {
    ...props,
    src,
    alt,
    ratio,
    thumb,
  }
}

const ImageExpEdi = (props) => {
  const { inView, image } = props

  const ref = useRef()

  const [loaded, setLoaded] = useState(false)

  return (
    <SitecoreImage
      field={image}
      ref={ref}
      data-loaded={loaded ? '' : undefined}
      onLoad={inView ? () => setLoaded(true) : undefined}
    />
  )
}

const getMediaValue = (value) => {
  let v = value * IMAGE_QUALITY_MULTIPLIER

  if (typeof window !== 'undefined') {
    v = value * window.devicePixelRatio
  }

  return Math.round(v)
}

const getMediaSrc = (src, value) =>
  src ? `${src.split('?')[0]}?w=${value}&auto=format ${value}w` : undefined

const ImageCommon = (props) => {
  const { inView, thumb, src, alt, disableLazyLoad, mediaList } = props

  const ref = useRef()

  const [loaded, setLoaded] = useState(disableLazyLoad ? true : false)

  return (
    <picture>
      {inView || disableLazyLoad ? (
        <>
          {mediaList ? (
            <>
              {mediaList.map((l) => (
                <source
                  key={l.query}
                  media={`(max-width: ${l.query}px)`}
                  srcSet={getMediaSrc(src, getMediaValue(l.value))}
                />
              ))}
              <source
                media={`(min-width: ${
                  mediaList[mediaList.length - 1].query + 1
                }px)`}
                srcSet={getMediaSrc(
                  src,
                  mediaList[mediaList.length - 1]
                    ? mediaList[mediaList.length - 1].value
                    : 1000
                )}
              />
            </>
          ) : (
            <source srcSet={src} />
          )}
        </>
      ) : undefined}
      <img
        ref={ref}
        data-loaded={loaded ? '' : undefined}
        src={thumb}
        alt={alt}
        onLoad={inView ? () => setLoaded(true) : undefined}
      />
    </picture>
  )
}

const ImageComponent = (props) => {
  const {
    className,
    fields,
    src,
    alt,
    thumb,
    ratio,
    cover,
    disableLazyLoad,
    mediaList,
    options,
  } = getProps(props)
  const { image, caption } = fields

  const [ref, inView] = useInView({
    triggerOnce: true,
  })

  const isEE = isEditorActive()

  return (
    <figure
      ref={ref}
      className={`general-image-container ${className}`}
      data-cover={cover ? '' : undefined}
      {...options}
    >
      <DynamicContainer ratio={ratio} disable={cover}>
        {isEE ? (
          <ImageExpEdi
            inView={inView}
            image={image || getSitecoreImage(src)}
            src={src}
            alt={alt}
            disableLazyLoad={disableLazyLoad}
          />
        ) : (
          <ImageCommon
            inView={inView}
            thumb={thumb}
            src={src}
            alt={alt}
            disableLazyLoad={disableLazyLoad}
            mediaList={mediaList}
          />
        )}
      </DynamicContainer>
      {caption && <Text tag="figcaption" field={caption} />}
    </figure>
  )
}

ImageComponent.defaultProps = {
  className: '',
  fields: {},
  options: {},
  src: undefined,
  alt: undefined,
  width: undefined,
  height: undefined,
  cover: false,
}

export default ImageComponent
