import React, { useEffect, useRef } from 'react'
import { withTranslation } from 'react-i18next'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

import {
  getKey,
  getDefaultCountry,
  setActiveCountry,
  log,
  efo,
  isFullURLStructure,
} from '../../../../helpers'

import './styles.scss'

const CountryList = withTranslation()(
  ({
    countryList,
    countryListData,
    countrySelectorInput,
    countryListStatus,
    setCountryListData,
    countryItemHightlighted,
  }) => {
    const countryListContainerRef = useRef()

    useEffect(() => {
      log('Country List', {
        countryList: { ...countryList },
        countrySelectorInput,
        countryListStatus,
        countryItemHightlighted,
      })
    }, [
      countryList,
      countrySelectorInput,
      countryListStatus,
      countryItemHightlighted,
    ])

    useEffect(() => {
      const getSortedCountries = (input) => {
        if (input === undefined || input === '') {
          return (
            countryList &&
            countryList.sort((a, b) => {
              const aText = a.text
              const bText = b.text

              if (aText < bText) {
                return -1
              }
              if (aText > bText) {
                return 1
              }
              return 0
            })
          )
        }
        const result =
          countryList &&
          countryList.filter((c) =>
            c.countryName.value.toLowerCase().startsWith(input.toLowerCase())
          )

        if (result && result.length) {
          return result
        }
        return [getDefaultCountry(countryList)]
      }

      setCountryListData(getSortedCountries(countrySelectorInput))
    }, [countrySelectorInput, countryList, setCountryListData])

    useEffect(() => {
      if (countryListContainerRef && countryListContainerRef.current) {
        if (countryListStatus) {
          setTimeout(
            () => disableBodyScroll(countryListContainerRef.current),
            500
          )
        } else {
          setTimeout(
            () => enableBodyScroll(countryListContainerRef.current),
            500
          )
        }
      }
    }, [countryListStatus])

    return (
      <div className="header-country-list-wrapper-container">
        <div
          ref={countryListContainerRef}
          className="header-country-list-container"
          data-active={countryListStatus ? '' : undefined}
        >
          <div className="header-country-list-wrapper">
            <div className="header-country-list-subcontainer">
              {countryListData &&
                countryListData.map((c, i) => {
                  const target = efo(c, 'targetSite.fields.sitePath.value')
                  const defTarget = !isFullURLStructure()
                    ? target.replace(/^\/motorsport/gm, '')
                    : target

                  return (
                    <a
                      key={getKey(c.id, i)}
                      data-focused={
                        countryItemHightlighted === i ? '' : undefined
                      }
                      href={defTarget}
                      onClick={(event) => {
                        event.preventDefault()
                        event.stopPropagation()
                        setActiveCountry(c)
                        setTimeout(() => {
                          window.location.href = defTarget
                        }, 500)
                      }}
                    >
                      {c.countryName.value}
                    </a>
                  )
                })}
            </div>
          </div>
        </div>
      </div>
    )
  }
)

export default CountryList
