import React, { useEffect } from 'react'
import Helmet from 'react-helmet'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { withRouter } from 'react-router-dom'
import config from '../../../temp/config'

import { getConfig, log, efo } from '../../../helpers'
import {
  SEO_APP_NAME,
  SEO_MAIN_CATEGORY,
  SEO_TOPIC,
  SEO_ORGANIZATION,
  SOCIAL_IMAGE_DEFAULT_WIDTH,
  SOCIAL_IMAGE_DEFAULT_HEIGHT,
  SOCIAL_IMAGE_DEFAULT_RATIO,
  SOCIAL_IMAGE_DEFAULT_RATIO_MARGIN,
  SOCIAL_IMAGE_DEFAULT_FILE,
  TWITTER_DEFAULT_CARD,
} from '../../../constants'

const getDate = (date) => (date && date.value ? date.value : Date.now())

const getMetaDate = (rawDate) => {
  const date = new Date(getDate(rawDate))
  const year = date.getFullYear()
  const m = date.getMonth() + 1
  const month = (m < 10 ? '0' : '') + m
  const day = (date.getDate() < 10 ? '0' : '') + date.getDate()
  return `${year}-${month}-${day}`
}

const getTitle = (title, altTitle) =>
  `${
    efo(title, 'value') || efo(altTitle, 'value') || SEO_APP_NAME
  } | ${SEO_APP_NAME}`

const getSection = (elements) => {
  const categories = elements.filter(
    (element) =>
      efo(element, 'fields.isCategory.value') &&
      efo(element, 'fields.categoryPage.value') !== SEO_MAIN_CATEGORY
  )

  if (categories && categories.length) {
    return efo(categories[0], 'fields.name.value')
  }

  return undefined
}

const getTags = (elements) => {
  const tags = elements.filter(
    (element) => !efo(element, 'fields.isCategory.value')
  )

  if (tags && tags.length) {
    return tags.map((tag) => efo(tag, 'fields.name.value'))
  }

  return undefined
}

const getURL = (rawValue) => {
  let value = getValue(rawValue)

  if (getConfig('website.isProdIntQa')) {
    const result = /^[a-z:/.]+\/motorsport([\w\W]+)/gm.exec(value)

    if (result && result[1]) {
      return `${getConfig('website.path')}${result[1]}`
    }
  }

  return value
}

const sitepath = () => {
  const environment = getConfig('website.pagDataEnviroment')
  const sitepath = getConfig('website.path')
  let site
  if (environment === 'P' || environment === 'K') {
    site = sitepath
  } else {
    site = sitepath.substring(0, sitepath.length - 1)
  }
  return site
}

const fullURL = (location, context) => {
  const st = sitepath()
  if (context.site.name === null) {
    context.site.name = config.defaultSitename
  }

  const country = context.site.name.split('-').pop()
  const language = context.language.split('-')[0]
  if (location.pathname === '/') {
    return st + location.pathname + country + '/' + language
  } else {
    if (typeof window !== 'undefined') {
      return st + '/' + country + '/' + language + location.pathname
    } else {
      return st + location.pathname.replace('/motorsport', '')
    }
  }
}

const getValue = (value) =>
  value ? (value.value !== undefined ? value.value : value) : undefined

const isEmptyObject = (object) => {
  if (typeof object === 'object') {
    return Object.keys(object).length === 0
  } else {
    return false
  }
}

const checkValidSocialImage = (ogImage) => {
  if (ogImage && ogImage.src !== undefined) {
    if (checkValidImageRatio(ogImage.width, ogImage.height)) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}

const checkValidImageRatio = (width, height) => {
  const ratio = width / height
  if (
    ratio > SOCIAL_IMAGE_DEFAULT_RATIO - SOCIAL_IMAGE_DEFAULT_RATIO_MARGIN &&
    ratio < SOCIAL_IMAGE_DEFAULT_RATIO + SOCIAL_IMAGE_DEFAULT_RATIO_MARGIN
  ) {
    return true
  } else {
    return false
  }
}

const CategorySEO = (props) => {
  const {
    title,
    logo,
    siteURL,
    metaDescription,
    datePublished,
    dateModified,
    searchResultImage,
  } = props

  const author = SEO_APP_NAME

  const jdlData = {
    '@context': 'http://schema.org/',
    '@type': 'CollectionPage',
    headline: title,
    datePublished,
    dateModified,
    description: metaDescription,
    author,
    publisher: {
      '@type': 'Organization',
      name: SEO_APP_NAME,
      url: siteURL,
    },
    mainEntityOfPage: siteURL,
  }

  if (!isEmptyObject(searchResultImage)) {
    jdlData.primaryImageOfPage = {
      '@type': 'ImageObject',
      height: Number(searchResultImage.height),
      width: Number(searchResultImage.width),
      url: searchResultImage.src,
    }
  } else {
    jdlData.primaryImageOfPage = {
      '@type': 'ImageObject',
      height: SOCIAL_IMAGE_DEFAULT_HEIGHT,
      width: SOCIAL_IMAGE_DEFAULT_WIDTH,
      url: `${getConfig('website.path')}${
        getConfig('website.distPath') || ''
      }${SOCIAL_IMAGE_DEFAULT_FILE}`,
    }
  }

  if (logo) {
    jdlData.publisher.logo = {
      '@type': 'ImageObject',
      height: Number(logo.height),
      width: Number(logo.width),
      url: logo.src,
    }
  }

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(jdlData)}</script>
    </Helmet>
  )
}

const ArticleSEO = (props) => {
  const {
    title,
    datePublished,
    dateModified,
    dateExpired,
    metaDescription,
    searchResultImage,
    rawTags,
    logo,
    siteURL,
  } = props

  const tags = getTags(rawTags)
  const section = getSection(rawTags)
  const author = SEO_APP_NAME

  const jdlData = {
    '@context': 'http://schema.org/',
    '@type': 'NewsArticle',
    headline: title,
    datePublished,
    dateModified,
    description: metaDescription,
    author,
    publisher: {
      '@type': 'Organization',
      name: SEO_APP_NAME,
      url: siteURL,
    },
    mainEntityOfPage: siteURL,
  }

  if (
    !isEmptyObject(searchResultImage) &&
    typeof searchResultImage !== 'undefined'
  ) {
    jdlData.image = {
      '@type': 'ImageObject',
      height: Number(searchResultImage.height),
      width: Number(searchResultImage.width),
      url: searchResultImage.src,
    }
  } else {
    jdlData.image = {
      '@type': 'ImageObject',
      height: SOCIAL_IMAGE_DEFAULT_HEIGHT,
      width: SOCIAL_IMAGE_DEFAULT_WIDTH,
      url: `${getConfig('website.path')}${
        getConfig('website.distPath') || ''
      }${SOCIAL_IMAGE_DEFAULT_FILE}`,
    }
  }

  if (logo) {
    jdlData.publisher.logo = {
      '@type': 'ImageObject',
      height: Number(logo.height),
      width: Number(logo.width),
      url: logo.src,
    }
  }

  return (
    <Helmet>
      <meta property="article:author" content={author} />
      <meta
        property="article:published_time"
        content={getMetaDate(datePublished)}
      />
      <meta
        property="article:modified_time"
        content={getMetaDate(dateModified)}
      />
      {dateExpired ? (
        <meta
          property="article:expiration_time"
          content={getMetaDate(dateExpired)}
        />
      ) : undefined}
      {tags ? <meta property="article:tag" content={tags} /> : undefined}
      {section ? (
        <meta property="article:section" content={section} />
      ) : undefined}
      <script type="application/ld+json">{JSON.stringify(jdlData)}</script>
    </Helmet>
  )
}

const SEO = withSitecoreContext()((props) => {
  const { fields, sitecoreContext } = props
  const {
    title: rawTitle,
    tags: rawTags,
    datePublished: rawDatePublished,
    dateUpdated: rawDateModified,
    metaDateExpired: rawDateExpired,
    MetaDescription: rawMetaDescription,
    MetaKeywords: rawMetaKeywords,
    OpenGraphAdmins: rawOGAdmins,
    OpenGraphAppId: rawOGAppId,
    OpenGraphDescription: rawOGDescription,
    OpenGraphImageUrl: rawOGImage,
    OpenGraphTitle: rawOGTitle,
    OpenGraphSiteName: rawOGSiteName,
    OpenGraphType: rawOGType,
    TwitterCardType: rawTwitterCardType,
    TwitterDescription: rawTwitterDescription,
    TwitterImage: rawTwitterImage,
    TwitterTitle: rawTwitterTitle,
    TwitterSite: rawTwitterSite,
    logo: rawLogo,
    siteUrl: rawSiteUrl,
    excludePageFromSearchEngineIndexing: rawExcludePageFromSearchEngineIndexing,
    searchResultImage: rawSearchResultImage,
    MetaTitle,
  } = fields

  const title = MetaTitle.value
    ? MetaTitle.value
    : getTitle(rawTitle, rawOGTitle)
  const datePublished = getDate(rawDatePublished)
  const dateModified = getDate(rawDateModified)
  const dateExpired = getDate(rawDateExpired)
  const metaDescription = getValue(rawMetaDescription)
  const metaKeywords = getValue(rawMetaKeywords)
  const ogAppId = getValue(rawOGAppId)
  const ogAdmins = getValue(rawOGAdmins)
  const ogDescription = getValue(rawOGDescription)
  const ogImage = getValue(rawOGImage)
  const ogTitle = getTitle(rawOGTitle, rawTitle)
  const ogSiteName = getValue(rawOGSiteName)
  const ogType = getValue(rawOGType)
  const tDescription = getValue(rawTwitterDescription)
  const tImage = getValue(rawTwitterImage)
  const tTitle = getValue(rawTwitterTitle)
  const tSite = getValue(rawTwitterSite)
  const logo = getValue(rawLogo)
  const siteURL = getURL(rawSiteUrl)
  const disableIndexing = getValue(rawExcludePageFromSearchEngineIndexing)
  const searchResultImage = getValue(rawSearchResultImage)

  useEffect(() => {
    log('SEO', {
      title,
      datePublished,
      dateModified,
      dateExpired,
      metaDescription,
      metaKeywords,
      ogAppId,
      ogAdmins,
      ogDescription,
      ogImage,
      ogTitle,
      ogSiteName,
      ogType,
      tDescription,
      tImage,
      tTitle,
      tSite,
      logo,
      siteURL,
      searchResultImage,
    })
  }, [
    title,
    datePublished,
    dateModified,
    dateExpired,
    metaDescription,
    metaKeywords,
    ogAppId,
    ogAdmins,
    ogDescription,
    ogImage,
    ogTitle,
    ogSiteName,
    ogType,
    tDescription,
    tImage,
    tTitle,
    tSite,
    logo,
    siteURL,
    searchResultImage,
  ])

  return (
    <>
      <Helmet>
        <title>{title || ogTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={metaKeywords} />
        <meta itemProp="description" content={metaDescription} />
        <meta itemProp="dateModified" content={dateModified} />
        <meta itemProp="mainEntityOfPage" content={siteURL} />
        <meta name="subject" content={SEO_TOPIC} />
        <meta name="topic" content={SEO_TOPIC} />
        <meta name="copyright" content={SEO_ORGANIZATION} />
        <meta name="organization" content={SEO_ORGANIZATION} />
        <meta name="application-name" content={SEO_APP_NAME} />
        <meta name="apple-mobile-web-app-title" content={SEO_APP_NAME} />
        <meta name="author" content={SEO_APP_NAME} />
        <meta itemProp="author" content={SEO_APP_NAME} />
        <meta itemProp="name" content={SEO_APP_NAME} />
        <link
          rel="manifest"
          href={`${getConfig('website.distPath') || ''}/manifest${
            efo(sitecoreContext, 'site.name') === 'motorsport-germany'
              ? '.de-DE'
              : efo(sitecoreContext, 'site.name') === 'motorsport-usa'
              ? '.en-US'
              : efo(sitecoreContext, 'site.name') === 'motorsport-international'
              ? '.en-GB'
              : ''
          }.json`}
        />
        {/* Common Open Graph tags */}
        <meta property="og:type" content={ogType} />
        <meta property="og:title" content={ogTitle || title} />
        <meta
          property="og:description"
          content={ogDescription || metaDescription}
        />
        <meta property="og:site_name" content={ogSiteName} />
        <meta
          property="og:url"
          content={fullURL(props.location, sitecoreContext)}
        />
      </Helmet>
      {!isEmptyObject(ogImage) && checkValidSocialImage(ogImage) ? (
        <Helmet>
          <meta property="og:image" content={ogImage.src} />
          <meta property="og:image:width" content={ogImage.width} />
          <meta property="og:image:height" content={ogImage.height} />
          <meta
            property="og:image:alt"
            content={ogImage.alt || ogTitle || title}
          />
        </Helmet>
      ) : (
        <Helmet>
          <meta
            property="og:image"
            content={`${getConfig('website.path')}${
              getConfig('website.distPath') || ''
            }${SOCIAL_IMAGE_DEFAULT_FILE}`}
          />
          <meta
            property="og:image:width"
            content={SOCIAL_IMAGE_DEFAULT_WIDTH}
          />
          <meta
            property="og:image:height"
            content={SOCIAL_IMAGE_DEFAULT_HEIGHT}
          />
          <meta property="og:image:alt" content={ogTitle || title} />
        </Helmet>
      )}
      <Helmet>
        {/* Common Twitter tags */}
        <meta
          name="twitter:card"
          content={
            efo(rawTwitterCardType, 'fields.Value.value') ||
            TWITTER_DEFAULT_CARD
          }
        />
        <meta name="twitter:title" content={tTitle || ogTitle || title} />
        <meta
          name="twitter:description"
          content={tDescription || ogDescription || metaDescription}
        />
        <meta name="twitter:site" content={tSite} />
        <meta
          property="twitter:url"
          content={fullURL(props.location, sitecoreContext)}
        />
      </Helmet>
      {!isEmptyObject(tImage) && checkValidSocialImage(tImage) && (
        <Helmet>
          <meta name="twitter:image" content={tImage.src} />
          <meta name="twitter:image:alt" content={tImage.alt} />
        </Helmet>
      )}
      {isEmptyObject(tImage) &&
        !isEmptyObject(ogImage) &&
        checkValidSocialImage(ogImage) && (
          <Helmet>
            <meta name="twitter:image" content={ogImage.src} />
            <meta name="twitter:image:alt" content={ogImage.alt || title} />
          </Helmet>
        )}
      {isEmptyObject(tImage) && isEmptyObject(ogImage) && (
        <Helmet>
          <meta
            name="twitter:image"
            content={`${getConfig('website.path')}${
              getConfig('website.distPath') || ''
            }${SOCIAL_IMAGE_DEFAULT_FILE}`}
          />
          <meta name="twitter:image:alt" content={title} />
        </Helmet>
      )}
      <Helmet>
        {/* Common Facebook tags */}
        <meta property="fb:app_id" content={ogAppId} />
        <meta property="fb:admins" content={ogAdmins} />
      </Helmet>
      {/* Article related tags */}
      {ogType === 'article' ? (
        <ArticleSEO
          title={title}
          datePublished={datePublished}
          dateModified={dateModified}
          dateExpired={dateExpired}
          metaDescription={metaDescription}
          searchResultImage={searchResultImage}
          rawTags={rawTags}
          logo={logo}
        />
      ) : undefined}
      {/* Category related tags */}
      {ogType === 'collection' ? (
        <CategorySEO
          title={title}
          datePublished={datePublished}
          dateModified={dateModified}
          dateExpired={dateExpired}
          metaDescription={metaDescription}
          searchResultImage={searchResultImage}
          logo={logo}
        />
      ) : undefined}
      <Helmet>
        {/* Disable indexing */}
        {getConfig('website.disableIndexing') ? (
          <meta name="robots" content="noindex" />
        ) : disableIndexing ? (
          <meta name="robots" content="noindex, nofollow" />
        ) : (
          <meta name="robots" content="index, follow" />
        )}
      </Helmet>
    </>
  )
})

export default withRouter(SEO)
