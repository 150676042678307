import { RestLayoutService } from '@sitecore-jss/sitecore-jss-react'
import config from '../temp/config'
import { getSiteNameFromLocation } from '../helpers'

export class LayoutServiceFactory {
  create(sitecoreContext) {
    const name = sitecoreContext
      ? sitecoreContext.site.name
      : getSiteNameFromLocation() || config.defaultSitename
    return new RestLayoutService({
      apiHost: config.sitecoreApiHost,
      apiKey: config.sitecoreApiKey,
      siteName: name,
      //configurationName: 'jss',
    })
  }
}

export const layoutServiceFactory = new LayoutServiceFactory()
