import React, { useEffect, useRef, useState } from 'react'

import Icon from '../Icon'

import { AUDIO_PLAYER_OPTIONS } from '../../../constants'

import './styles.scss'

const prepareSpectrum = ({
  audioRef,
  analyser,
  source,
  setAnalyser,
  setSource,
}) => {
  let c
  let a

  window.AudioContext =
    window.AudioContext ||
    window.webkitAudioContext ||
    window.mozAudioContext ||
    window.msAudioContext

  window.requestAnimationFrame =
    window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.msRequestAnimationFrame

  window.cancelAnimationFrame =
    window.cancelAnimationFrame ||
    window.webkitCancelAnimationFrame ||
    window.mozCancelAnimationFrame ||
    window.msCancelAnimationFrame

  try {
    c = new window.AudioContext()
  } catch (e) {}

  if (c) {
    if (!analyser) {
      a = c.createAnalyser()
      a.smoothingTimeConstant = 0.8
      a.fftSize = 2048

      setAnalyser(a)
    }

    if (!source) {
      const s = c.createMediaElementSource(audioRef.current)
      s.connect(a)
      s.connect(c.destination)

      setSource(s)
    }
  }
}

const drawSpectrum = ({
  canvasRef,
  playing,
  analyser,
  gap,
  meterCount,
  meterWidth,
  capHeight,
  capColor,
}) => {
  const canvasWidth = canvasRef.current.width
  const canvasHeight = canvasRef.current.height - capHeight
  const capVerticalPosition = []

  const ctx = canvasRef.current.getContext('2d')

  let animation

  const drawMeter = () => {
    const array = new Uint8Array(analyser.frequencyBinCount)

    analyser.getByteFrequencyData(array)

    if (!playing) {
      for (let i = array.length - 1; i >= 0; i--) {
        array[i] = 0
      }

      if (!capVerticalPosition.some((cap) => cap > 0)) {
        ctx.clearRect(0, 0, canvasWidth, canvasHeight + capHeight)
        window.cancelAnimationFrame(animation)
        return
      }
    }

    const step = Math.round(array.length / meterCount)
    ctx.clearRect(0, 0, canvasWidth, canvasHeight + capHeight)

    for (let i = 0; i < meterCount; i++) {
      const value = array[i * step]

      if (capVerticalPosition.length < Math.round(meterCount)) {
        capVerticalPosition.push(value)
      }

      ctx.fillStyle = capColor

      if (value < capVerticalPosition[i]) {
        const preValue = --capVerticalPosition[i]
        const y = ((270 - preValue) * canvasHeight) / 270
        ctx.fillRect(i * (meterWidth + gap), y, meterWidth, capHeight)
      } else {
        const y = ((270 - value) * canvasHeight) / 270
        ctx.fillRect(i * (meterWidth + gap), y, meterWidth, capHeight)
        capVerticalPosition[i] = value
      }

      ctx.fillStyle = 'white'

      const y = ((270 - value) * canvasHeight) / 270 + capHeight
      ctx.fillRect(i * (meterWidth + gap), y, meterWidth, canvasHeight)
    }

    animation = requestAnimationFrame(drawMeter)
  }

  animation = requestAnimationFrame(drawMeter)
}

const AudioPlayer = ({ file, loop, children }) => {
  const audioRef = useRef()
  const canvasRef = useRef()

  const [playing, setPlaying] = useState(false)
  const [height, setHeigth] = useState(undefined)
  const [width, setWidth] = useState(undefined)
  const [analyser, setAnalyser] = useState(undefined)
  const [source, setSource] = useState(undefined)

  useEffect(() => {
    const listener = () => {
      if (audioRef && audioRef.current) {
        setWidth(audioRef.current.clientWidth)
        setHeigth(audioRef.current.clientHeight)
      }
    }

    listener()

    window.addEventListener('resize', listener)
    return () => window.removeEventListener('resize', listener)
  })

  useEffect(() => {
    if (audioRef && audioRef.current) {
      audioRef.current.onpause = () => setPlaying(false)
      audioRef.current.onplay = () => {
        setPlaying(true)
        prepareSpectrum({
          audioRef,
          analyser,
          source,
          setAnalyser,
          setSource,
        })
      }
    }
  }, [audioRef, analyser, source])

  useEffect(() => {
    if (analyser) {
      drawSpectrum({
        canvasRef,
        playing,
        analyser,
        ...AUDIO_PLAYER_OPTIONS,
      })
    }
  }, [canvasRef, playing, analyser])

  return (
    <div
      className="general-audio-player-container"
      data-playing={playing ? '' : undefined}
    >
      <div className="general-audio-player-subcontainer">
        <audio
          ref={audioRef}
          className="general-audio-player-file-container"
          src={file}
          loop={loop}
          controls
          onClick={() => {
            if (playing) {
              audioRef.current.pause()
            } else {
              audioRef.current.play()
            }
          }}
        >
          <source />
        </audio>
        <div className="general-audio-player-icon-container">
          <Icon name={playing ? 'pause' : 'volumeUp'} color="white" size={40} />
        </div>
        <canvas
          ref={canvasRef}
          className="general-audio-player-canvas"
          width={width}
          height={height}
        />
        {/* <div className="general-audio-player-bar-container">
          {data.map((d, i) => (
            <div
              key={i}
              className="general-audio-player-bar-element"
              style={{
                maxWidth: `${width / data.length}px`,
                maxHeight: `${(d / FFT_SIZE) * height}px`,
              }}
            />
          ))}
        </div> */}
      </div>
      {children}
    </div>
  )
}

export default AudioPlayer
