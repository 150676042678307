import React from 'react'

import MediaImage from './MediaImage'

import AudioPlayer from '../AudioPlayer'

const MediaAudio = ({ audioFile, audioLoop, image, ratio }) => (
  <AudioPlayer file={audioFile} loop={audioLoop}>
    <MediaImage image={image} ratio={ratio} />
  </AudioPlayer>
)

export default MediaAudio
