import React, { useRef, useEffect, useState } from 'react'
import ReactPlayer from 'react-player/lazy'

import withMinimumRequirements from '../../../withMinimumRequirements'
import DynamicContainer from '../DynamicContainer'
import Image from '../Image'
import Icon from '../Icon'
import Slider, { Slide } from '../Slider/SliderMin'

import { efo, getShareFacebook, getShareTwitter } from '../../../helpers'

import './styles.scss'

const Video = ({
  url,
  playing,
  muted,
  active,
  setDuration,
  setProgress,
  onEnded,
}) => {
  const videoRef = useRef()

  const [ready, setReady] = useState(false)

  useEffect(() => {
    if (ready && videoRef.current) {
      videoRef.current.seekTo(0, 'seconds')
      setProgress(0)

      if (active) {
        setDuration(videoRef.current.getDuration())
      }
    }
  }, [videoRef, active, ready, setDuration, setProgress])

  return (
    <ReactPlayer
      ref={videoRef}
      url={url}
      playing={playing}
      muted={muted}
      width="100%"
      height="100%"
      progressInterval={100}
      onProgress={
        active ? ({ playedSeconds }) => setProgress(playedSeconds) : undefined
      }
      onEnded={active ? onEnded : undefined}
      onReady={() => setReady(true)}
      playsinline
      config={{
        file: {
          attributes: {
            playsInline: true,
          },
        },
      }}
    />
  )
}

const Story = ({ image, aspectRatio, muted, items, tags }) => {
  const [mount, setMount] = useState(false)
  const [slider, setSlider] = useState(null)
  const [active, setActive] = useState(0)
  const [paused, setPaused] = useState(false)
  const [progress, setProgress] = useState(0)
  const [duration, setDuration] = useState(0)
  const [interacted, setInteracted] = useState(false)

  useEffect(() => {
    if (!mount) {
      setMount(true)
    }
  }, [mount])

  const goTo = (index) => {
    if (slider.activeIndex >= items.length - 1) {
      setInteracted(false)
      slider.slideTo(0)
    } else if (index !== undefined) {
      slider.slideTo(index)
    } else {
      slider.slideNext()
    }
  }

  return (
    <DynamicContainer
      className="general-story-container"
      ratio={aspectRatio}
      onClick={() => setInteracted(true)}
      onMouseDown={() => {
        if (interacted) {
          setPaused(true)
        }
      }}
      onMouseUp={() => {
        if (interacted) {
          setPaused(false)
        }
      }}
    >
      <div className="general-story-indicator-container">
        {items.map((item, i) => (
          <div
            key={i}
            className="general-story-indicator-item-container"
            data-active={i === active ? '' : undefined}
            onClick={i === active ? undefined : () => goTo(i)}
          >
            <progress
              max={active > i ? 1 : active === i ? duration : undefined}
              value={active > i ? 1 : active === i ? progress : undefined}
            />
          </div>
        ))}
      </div>
      <Slider
        onSwiper={setSlider}
        onActiveIndexChange={(s) => setActive(s.activeIndex)}
        allowTouchMove={false}
      >
        {items.map((item, i) => (
          <Slide className="general-story-item-container" key={i}>
            {i === 0 ? (
              <div
                className="general-story-item-preview-container"
                data-show={!interacted ? '' : undefined}
              >
                <Image {...image} />
                <div className="general-story-item-preview-icon-play">
                  <Icon name="play" size={42} />
                </div>
              </div>
            ) : undefined}
            <div
              className="general-story-item-data-container"
              data-show={interacted ? '' : undefined}
            >
              <span className="general-story-item-title">{item.title}</span>
              {item.link ? (
                <div className="general-story-item-share-container">
                  <div className="general-story-item-share-options">
                    <a
                      className="general-story-item-share-options--facebook"
                      href={getShareFacebook(efo(item, 'link.href'), mount)}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={(event) => {
                        event.stopPropagation()
                      }}
                    >
                      <Icon name="logoFacebook" />
                    </a>
                    <a
                      className="general-story-item-share-options--twitter"
                      href={getShareTwitter(
                        efo(item, 'link.href'),
                        mount,
                        item.title,
                        tags
                      )}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={(event) => {
                        event.stopPropagation()
                      }}
                    >
                      <Icon name="logoTwitter" />
                    </a>
                  </div>
                  <div
                    className="general-story-item-share-button"
                    onClick={(event) => {
                      event.stopPropagation()
                    }}
                  >
                    <Icon name="share" />
                  </div>
                </div>
              ) : undefined}
            </div>
            {interacted ? (
              <Video
                UC_READY="{props.UC_READY}"
                UC_PROCESSOR="{props.UC_PROCESSOR}"
                url={item.video}
                playing={paused ? false : interacted && i === active}
                active={i === active}
                muted={muted}
                setDuration={setDuration}
                setProgress={setProgress}
                onEnded={goTo}
              />
            ) : undefined}
          </Slide>
        ))}
      </Slider>
    </DynamicContainer>
  )
}

export default withMinimumRequirements(Story, [
  'aspectRatio',
  'items',
  'items.0',
  'items.0.title',
  'items.0.video',
  'items.1',
  'items.1.title',
  'items.1.video',
])
