import React, { useEffect, useRef, useCallback } from 'react'
import { useInView } from 'react-intersection-observer'

import withMinimumRequirements from '../../../withMinimumRequirements'
import Image from '../../shared/Image'
import { Image as SitecoreImage, Text } from '@sitecore-jss/sitecore-jss-react'

import {
  log,
  efo,
  getModulePosition,
  pushGlEvent,
  getDataSourceName,
} from '../../../helpers'

import './styles.scss'

const ArticleBodyImage = (props) => {
  const { fields } = props
  const { image, caption } = fields

  const { sitecoreContext } = props
  const { pageEditing } = sitecoreContext

  const ratio =
    Number(efo(image, 'value.height')) / Number(efo(image, 'value.width'))
  const ref = useRef()
  const [inViewRefTrack, inViewTrack] = useInView({
    threshold: 0.3,
  })

  const setRefs = useCallback(
    (node) => {
      ref.current = node
      inViewRefTrack(node)
    },
    [inViewRefTrack]
  )

  useEffect(() => {
    log('Article Body Image', { ...fields })
  }, [fields])

  // Tracking load
  useEffect(() => {
    if (typeof window !== 'undefined' && window.GDL) {
      pushGlEvent(
        false,
        'Image',
        [],
        'Image',
        getModulePosition('article-body-image-container', ref),
        'Image',
        efo(props, 'rendering.uid'),
        'Image'
      )
    }
  }, [props])

  // Tracking in view
  useEffect(() => {
    if (inViewTrack && typeof window !== 'undefined' && window.GDL) {
      pushGlEvent(
        true,
        'Image',
        [],
        'Image',
        getModulePosition('article-body-image-container', ref),
        'Image',
        efo(props, 'rendering.uid'),
        'Image'
      )
    }
  }, [inViewTrack, props])

  return (
    <div
      className={`article-body-image-container ${
        ratio === 1 ? 'article-body-image-container-ratio-one' : ''
      }`}
      id={getDataSourceName(efo(props, 'rendering.dataSource'))}
      ref={setRefs}
    >
      {pageEditing ? (
        <figure className="general-image-container-editing">
          {image && <SitecoreImage field={image} />}
          {caption && <Text tag="figcaption" field={caption} />}
        </figure>
      ) : (
        <Image
          fields={{
            image,
            caption,
          }}
        />
      )}
    </div>
  )
}

export default withMinimumRequirements(ArticleBodyImage, ['fields.image.value'])
