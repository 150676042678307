import React, { useEffect, useRef, useCallback } from 'react'
import {
  Text,
  RichText,
  isEditorActive,
} from '@sitecore-jss/sitecore-jss-react'
import { useInView } from 'react-intersection-observer'

import withMinimumRequirements from '../../../withMinimumRequirements'
import Icon from '../../shared/Icon'

import {
  efo,
  log,
  getModulePosition,
  pushGlEvent,
  getDataSourceName,
} from '../../../helpers'

import './styles.scss'

const ArticleBodyText = (props) => {
  const { fields } = props

  const { link: rawLink, headline, subline, text } = fields

  const ref = useRef()
  const [inViewRefTrack, inViewTrack] = useInView({
    threshold: 0.3,
  })

  const setRefs = useCallback(
    (node) => {
      ref.current = node
      inViewRefTrack(node)
    },
    [inViewRefTrack]
  )

  useEffect(() => {
    log('Article Body Text', { ...fields })
  }, [fields])

  // Tracking load
  useEffect(() => {
    if (typeof window !== 'undefined' && window.GDL) {
      pushGlEvent(
        false,
        'Text',
        [],
        'Text',
        getModulePosition('article-body-text-container', ref),
        'Text',
        efo(props, 'rendering.uid'),
        'Text'
      )
    }
  }, [props])

  // Tracking in view
  useEffect(() => {
    if (inViewTrack && typeof window !== 'undefined' && window.GDL) {
      pushGlEvent(
        true,
        'Text',
        [],
        'Text',
        getModulePosition('article-body-text-container', ref),
        'Text',
        efo(props, 'rendering.uid'),
        'Text'
      )
    }
  }, [inViewTrack, props])

  const link = efo(rawLink, 'value')

  return (
    <div
      className="article-body-text-container"
      id={getDataSourceName(efo(props, 'rendering.dataSource'))}
      ref={setRefs}
    >
      {headline || subline || isEditorActive() ? (
        <div
          className="article-body-text-head-container"
          data-has-copy={text ? '' : undefined}
        >
          {headline || isEditorActive() ? (
            <Text id={link} tag="h2" field={headline} />
          ) : undefined}
          {subline || isEditorActive() ? (
            <Text id={link} tag="h3" field={subline} />
          ) : undefined}
          {link ? (
            <a href={`#${link}`} className="article-body-text-link-container">
              <Icon name="external" />
            </a>
          ) : undefined}
        </div>
      ) : undefined}
      {text || isEditorActive() ? (
        <RichText
          className="article-body-text-copy-container"
          tag="div"
          field={text}
        />
      ) : undefined}
    </div>
  )
}

export default withMinimumRequirements(ArticleBodyText, [
  {
    mode: 'one-of',
    structure: [
      'fields.headline.value',
      'fields.subline.value',
      'fields.text.value',
    ],
  },
])
