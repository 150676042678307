const types = {
  VIDEO_SERIES: {
    SET: {
      VIDEO: {
        SELECTED: 'VIDEO_SERIES.SET.VIDEO.SELECTED',
      },
    },
  },
}

const initialState = {
  videoSeriesSelected: {},
}

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.VIDEO_SERIES.SET.VIDEO.SELECTED:
      return {
        ...state,
        videoSeriesSelected: {
          ...state.videoSeriesSelected,
          [action.id]: action.video,
        },
      }
    default:
      return state
  }
}

const setTeaserVideoSeriesSelected = (dispatch, values) =>
  dispatch({
    type: types.VIDEO_SERIES.SET.VIDEO.SELECTED,
    ...values,
  })

export default {
  initialState,
  reducer,
  actions: {
    setTeaserVideoSeriesSelected,
  },
}
