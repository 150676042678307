import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useContext,
} from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import { useInView } from 'react-intersection-observer'

import { actions, Store } from '../../../store'
import {
  log,
  getCorrectURL,
  efo,
  getTeaserAnchor,
  pushGlEvent,
} from '../../../helpers'

import {
  DEFAULT_TEASER_TYPE,
  TEASER_TYPE_VIDEO,
  TEASER_TYPE_GALLERY,
  TEASER_TYPE_COUNTDOWN,
  TEASER_TYPE_VIDEO_SERIES,
  TEASER_TYPE_QUIZ,
  TEASER_TYPE_RACE_CALENDAR,
  TEASER_TYPE_POLL,
  TEASER_TYPE_AUTOGRAPHS,
  TEASER_TYPE_AUDIO,
  TEASER_TYPE_STORY,
  TEASER_TYPE_SOCIAL_MEDIA,
  TEASER_TYPE_FANBOOST,
} from '../../../constants'

import './styles.scss'

import { withMinimumRequirementsByType } from '../../../withMinimumRequirements'
import MediaGallery from './MediaGallery'
import MediaImage from './MediaImage'
import MediaVideo from './MediaVideo'
import MediaCountdown from './MediaCountdown'
import MediaVideoSeries from './MediaVideoSeries'
import MediaAudio from './MediaAudio'
import MediaStory from './MediaStory'
import MediaSocialMedia from './MediaSocialMedia'
import BodyDefault from './BodyDefault'
import BodyRaceCalendar from './BodyRaceCalendar'
import BodyPoll from './BodyPoll'
import BottomShareLike from './BottomShareLike'
import BottomVideoSeries from './BottomVideoSeries'
import BottomQuiz from './BottomQuiz'
import BottomRaceCalendar from './BottomRaceCalendar'
import BottomPoll from './BottomPoll'
import BottomAutographs from './BottomAutographs'
import BottomFanboost from './BottomFanboost'

// -----------------------
// Media Components
const MediaComponents = {
  [DEFAULT_TEASER_TYPE]: MediaImage,
  [TEASER_TYPE_VIDEO]: MediaVideo,
  [TEASER_TYPE_GALLERY]: MediaGallery,
  [TEASER_TYPE_COUNTDOWN]: MediaCountdown,
  [TEASER_TYPE_VIDEO_SERIES]: MediaVideoSeries,
  [TEASER_TYPE_QUIZ]: () => null,
  [TEASER_TYPE_RACE_CALENDAR]: () => null,
  [TEASER_TYPE_POLL]: MediaImage,
  [TEASER_TYPE_AUTOGRAPHS]: () => null,
  [TEASER_TYPE_AUDIO]: MediaAudio,
  [TEASER_TYPE_STORY]: MediaStory,
  [TEASER_TYPE_SOCIAL_MEDIA]: MediaSocialMedia,
  [TEASER_TYPE_FANBOOST]: MediaImage,
}
// -----------------------

const AbstractMedia = (props) => {
  const { fields } = props

  const Media =
    MediaComponents[fields.teaserComponentName] ||
    MediaComponents[DEFAULT_TEASER_TYPE]

  if (Media) {
    return <Media {...fields} {...props} />
  }

  return null
}

// -----------------------
// Body Components
const BodyComponents = {
  [DEFAULT_TEASER_TYPE]: BodyDefault,
  [TEASER_TYPE_VIDEO]: BodyDefault,
  [TEASER_TYPE_GALLERY]: BodyDefault,
  [TEASER_TYPE_COUNTDOWN]: () => null,
  [TEASER_TYPE_VIDEO_SERIES]: BodyDefault,
  [TEASER_TYPE_QUIZ]: () => null,
  [TEASER_TYPE_RACE_CALENDAR]: BodyRaceCalendar,
  [TEASER_TYPE_POLL]: BodyPoll,
  [TEASER_TYPE_AUTOGRAPHS]: () => null,
  [TEASER_TYPE_STORY]: () => null,
  [TEASER_TYPE_SOCIAL_MEDIA]: () => null,
  [TEASER_TYPE_FANBOOST]: BodyDefault,
}
// -----------------------

const AbstractBody = (props) => {
  const { fields } = props

  const Body =
    BodyComponents[fields.teaserComponentName] ||
    BodyComponents[DEFAULT_TEASER_TYPE]

  if (Body) {
    return <Body {...fields} {...props} />
  }

  return null
}

// -----------------------
// Bottom Components
const BottomComponents = {
  [DEFAULT_TEASER_TYPE]: BottomShareLike,
  [TEASER_TYPE_VIDEO]: BottomShareLike,
  [TEASER_TYPE_GALLERY]: BottomShareLike,
  [TEASER_TYPE_COUNTDOWN]: () => null,
  [TEASER_TYPE_VIDEO_SERIES]: BottomVideoSeries,
  [TEASER_TYPE_QUIZ]: BottomQuiz,
  [TEASER_TYPE_RACE_CALENDAR]: BottomRaceCalendar,
  [TEASER_TYPE_POLL]: BottomPoll,
  [TEASER_TYPE_AUTOGRAPHS]: BottomAutographs,
  [TEASER_TYPE_STORY]: () => null,
  [TEASER_TYPE_SOCIAL_MEDIA]: () => null,
  [TEASER_TYPE_FANBOOST]: BottomFanboost,
}
// -----------------------

const AbstractBottom = (props) => {
  const { fields } = props

  const Bottom =
    BottomComponents[fields.teaserComponentName] ||
    BottomComponents[DEFAULT_TEASER_TYPE]

  if (Bottom) {
    return <Bottom {...fields} {...props} />
  }

  return null
}

const Teaser = withRouter((props) => {
  const { id, fields = {}, delay, loading, index, stopAnimation } = props

  const { dispatch } = useContext(Store)

  const [mount, setMount] = useState(false)
  const [liked, setLiked] = useState(false)
  const [hovering, setHovering] = useState(false)

  const { state } = useContext(Store)
  const { show } = state.modal

  const getTeaserType = (type) => {
    if (type === 'Teaser') {
      return 'ImageTeaser'
    }
    return type
  }

  const getTags = (fields) => {
    let tags = []
    if (efo(fields, 'masterCategory')) {
      tags.push(efo(fields, 'masterCategory.name'))
    }
    if (efo(fields, 'tags')) {
      efo(fields, 'tags').forEach((tag) => {
        tags.push(tag.name)
      })
    }
    return tags
  }

  const teaserRef = useRef()
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    rootMargin: '100%',
  })

  const [inViewRefTrack, inViewTrack] = useInView({
    threshold: 0.3,
  })

  const setRefs = useCallback(
    (node) => {
      teaserRef.current = node
      inViewRef(node)
      inViewRefTrack(node)
    },
    [inViewRef, inViewRefTrack]
  )

  useEffect(() => {
    if (!loading) {
      log(`Teaser > #${index} ${fields.teaserComponentName}`, { ...fields })
    }
  }, [loading, index, fields])

  // Tracking load
  useEffect(() => {
    if (typeof window !== 'undefined' && window.GDL && !loading) {
      pushGlEvent(
        false,
        getTeaserType(fields.teaserComponentName),
        getTags(fields),
        getTeaserType(fields.teaserComponentName),
        index < 10 ? `0${index}` : String(index),
        'Teaser',
        efo(fields, 'id'),
        efo(fields, 'title')
      )
    }
  }, [loading, fields, index])

  // Tracking in view
  useEffect(() => {
    if (
      inViewTrack &&
      typeof window !== 'undefined' &&
      window.GDL &&
      !loading
    ) {
      pushGlEvent(
        true,
        getTeaserType(fields.teaserComponentName),
        getTags(fields),
        getTeaserType(fields.teaserComponentName),
        index < 10 ? `0${index}` : String(index),
        'Teaser',
        efo(fields, 'id'),
        efo(fields, 'title')
      )
    }
  }, [inViewTrack, fields, loading, index])

  // We need to re-render because URL on PROD needs "window.location.pathname" access.
  useEffect(() => {
    if (!mount) {
      setMount(true)
    }
  }, [mount])

  const scrollIntoTeaser = () => {
    if (teaserRef && teaserRef.current) {
      actions.setHeaderSticky(dispatch, { value: true })
      teaserRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
      actions.setHeaderSticky(dispatch, { value: false })
    }
  }

  const targetURL = efo(fields, 'targetURL.href') || ''
  const targetID = efo(fields, 'targetURL.id')
  const targetAnchor = efo(fields, 'targetURL.anchor')
  const isInternal = targetURL.startsWith('/')
  const NavigationComponent = !targetURL
    ? React.createElement('div').type
    : isInternal
    ? NavLink
    : React.createElement('a').type

  return (
    <div
      ref={setRefs}
      id={id}
      className={`teaser-container ${fields.teaserComponentName.toLowerCase()}`}
      data-loading={loading ? '' : undefined}
      data-has-link={targetURL ? '' : undefined}
      data-intersected={inView ? '' : undefined}
      data-stop-animation={stopAnimation ? '' : undefined}
      data-id={efo(fields, 'id')}
      style={{
        animationDelay: `${delay}ms`,
        zIndex: 99999 - index,
      }}
      onMouseEnter={() => {
        if (!show) {
          setHovering(true)
        }
      }}
      onMouseLeave={() => {
        if (!show) {
          setHovering(false)
        }
      }}
    >
      {targetURL ? (
        <NavigationComponent
          className="teaser-link"
          href={isInternal ? undefined : targetURL}
          target={isInternal ? undefined : '_blank'}
          to={
            isInternal
              ? {
                  pathname: getCorrectURL(targetURL),
                  hash: getTeaserAnchor(targetAnchor)
                    ? `#${targetAnchor}`
                    : undefined,
                  state: {
                    layoutID: targetID,
                  },
                }
              : undefined
          }
        />
      ) : undefined}
      {loading && <div className="skeleton-lazy-loader" />}
      <AbstractMedia {...props} isHovering={hovering} />
      <AbstractBody {...props} loading={loading} isHovering={hovering} />
      <AbstractBottom
        {...props}
        loading={loading}
        liked={liked}
        setLiked={setLiked}
        scrollIntoTeaser={scrollIntoTeaser}
      />
    </div>
  )
})

export default withMinimumRequirementsByType(
  Teaser,
  'fields.teaserComponentName',
  {
    LoadingTeaser: [],
    Teaser: ['fields.id', 'fields.title', 'fields.image'],
    VideoTeaser: [
      'fields.id',
      'fields.title',
      'fields.image',
      {
        mode: 'one-of',
        structure: ['fields.url', 'fields.fileURL'],
      },
    ],
    GalleryTeaser: [
      'fields.id',
      'fields.title',
      {
        mode: 'one-of',
        structure: ['fields.image', 'fields.images.0'],
      },
    ],
    CountdownTeaser: [
      'fields.id',
      'fields.title',
      'fields.image',
      'fields.startDate',
    ],
    VideoSeriesTeaser: [
      'fields.id',
      'fields.title',
      'fields.videos',
      'fields.videos.0',
    ],
    QuizTeaser: [
      'fields.id',
      'fields.title',
      'fields.image',
      'fields.questions',
      'fields.questions.0',
    ],
    RaceCalendarTeaser: [
      'fields.id',
      'fields.title',
      'fields.items',
      'fields.items.0',
    ],
    PollTeaser: [
      'fields.id',
      'fields.title',
      'fields.image',
      'fields.question',
      'fields.answers',
      'fields.answers.0',
    ],
    AudioTeaser: [
      'fields.id',
      'fields.title',
      'fields.image',
      'fields.audioFile',
    ],
    StoryTeaser: [
      'fields.aspectRatio',
      'fields.story',
      'fields.story.items.0',
      'fields.story.items.0.video',
      'fields.story.items.0.title',
    ],
    SocialMediaTeaser: ['fields.storyStream.id', 'fields.storyStream.token'],
    FanboostTeaser: [
      'fields.fanboostLink',
      'fields.fanboostText',
      'fields.fanboostThankText',
    ],
  }
)
