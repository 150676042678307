import React, { useContext } from 'react'

import Slider, { Slide } from '../Slider'
import Image from '../Image'
import BottomShareLike from './BottomShareLike'

import { getKey, getMediaList } from '../../../helpers'
import { actions, Store } from '../../../store'

const getVideoSeriesIndex = (videoSeries = [], videoSeriesSelected) => {
  const index = videoSeriesSelected
    ? videoSeries.indexOf(videoSeriesSelected) + 1
    : 1

  if (index < 10) {
    return `0${index}`
  }

  return index
}

const getVideoSeriesTotal = (videoSeries = []) => {
  const total = videoSeries.length

  if (total < 10) {
    return `0${total}`
  }

  return total
}

const BottomVideoSeries = (props) => {
  const { id, videos: videoSeries } = props

  const { state, dispatch } = useContext(Store)
  const { videoSeriesSelected: allVideoSeriesSelected } = state.teaser

  const videoSeriesSelected = (allVideoSeriesSelected || {})[id]

  return (
    <div className="teaser-bottom-video-series-container">
      <div className="teaser-bottom-video-series-indicator-container">
        <div className="teaser-bottom-video-series-indicator-subcontainer">
          <span data-index>
            {getVideoSeriesIndex(videoSeries, videoSeriesSelected)}
          </span>
          <span data-total>{getVideoSeriesTotal(videoSeries)}</span>
        </div>
        <BottomShareLike {...props} omitPadding />
      </div>
      <Slider
        className="teaser-bottom-video-series-slider-container slider-ratio-landscape"
        slidesMargin={1}
        navigation
        navigationMini
      >
        {(videoSeries || []).map((video = {}, index) => (
          <Slide key={getKey(video.id, index)}>
            <div
              className="teaser-bottom-video-series-slide-container"
              onClick={(event) => {
                event.preventDefault()
                event.stopPropagation()
                actions.setTeaserVideoSeriesSelected(dispatch, { id, video })
              }}
            >
              <Image
                className="teaser-bottom-video-series-slide-image"
                {...(video.image || {})}
                mediaList={getMediaList(250)}
                cover
                options={{
                  style: {
                    height: 200,
                  },
                }}
              />
              <div className="teaser-bottom-video-series-slide-subcontainer">
                <span
                  data-active={
                    getVideoSeriesIndex(videoSeries, video) ===
                    getVideoSeriesIndex(videoSeries, videoSeriesSelected)
                      ? ''
                      : undefined
                  }
                >
                  {getVideoSeriesIndex(videoSeries, video)}
                </span>
                <span>{video.title || ''}</span>
              </div>
            </div>
          </Slide>
        ))}
      </Slider>
    </div>
  )
}

export default BottomVideoSeries
