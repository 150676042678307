import React, { useEffect } from 'react'
import { File, Text } from '@sitecore-jss/sitecore-jss-react'

import withMinimumRequirements from '../../../withMinimumRequirements'
import Icon from '../../shared/Icon'

import { efo, log, getDataSourceName } from '../../../helpers'

import './styles.scss'

const ArticleBodyDownloadPanelItem = (props) => {
  const { fields } = props
  const { title, file, downloadAll } = fields

  useEffect(() => {
    log('Article Body Download Panel > Item', { ...fields })
  }, [fields])

  return (
    <File
      className="article-body-download-item-container"
      id={getDataSourceName(efo(props, 'rendering.dataSource'))}
      field={file}
      target="_blank"
      rel="noopener noreferrer"
      data-download-all={efo(downloadAll, 'value') ? '' : undefined}
    >
      <div className="article-body-download-item-icon-container">
        <Icon name="download" size={25} />
      </div>
      <Text tag="span" field={title} />
    </File>
  )
}

export default withMinimumRequirements(ArticleBodyDownloadPanelItem, [
  'fields.title.value',
  'fields.file.value.src',
])
