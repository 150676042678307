import React, { useContext, useState, useRef, useEffect } from 'react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { NavLink, useLocation, useHistory } from 'react-router-dom'
import { useLastLocation } from 'react-router-last-location'

import Icon from '../../shared/Icon'
import Image from '../../shared/Image'

import CountrySelector from './CountrySelector'
import CountryList from './CountryList'
import Menu from './Menu'
import Burger from './Burger'
import Search from './Search'

import useScrollDirection from '../../../hooks/useScrollDirection'
import useSize from '../../../hooks/useSize'

import { actions, Store } from '../../../store'

import {
  efo,
  isPortrait,
  log,
  getMediaList,
  getCorrectURL,
} from '../../../helpers'

import './styles.scss'

const Header = (props) => {
  const {
    fields,
    rendering,
    sitecoreContext,
    disableCountryRedirection,
  } = props

  const logo = efo(fields, 'logo')
  const menuMainImage = efo(fields, 'defaultMenuImage')
  const menuHeadline = efo(fields, 'menuHeadline')
  const navigationLinks = efo(fields, 'navigationLinks')
  const countryList = efo(fields, 'countries')
  const id = efo(rendering, 'uid')
  const searchResultPage = efo(fields, 'searchResultPage')
  const templateId = efo(sitecoreContext, 'route.templateId')
  const itemId = efo(sitecoreContext, 'route.itemId')

  const [menuVisible, setMenuVisible] = useState(false)

  const handleMenu = (visible) => {
    setMenuVisible(visible)
  }

  const [scrollDirection, passedThresholds] = useScrollDirection({
    topThresholds: [300, 750],
  })
  const ref = useRef(null)
  const [, height] = useSize(ref, {
    initialHeight: 0,
  })

  const { state, dispatch } = useContext(Store)
  const {
    countrySelectorStatus,
    countryListStatus,
    tagSelectorStatus,
    searchStatus,
    sticky,
  } = state.header

  const setHeaderCountrySelectorStatus = (value) =>
    actions.setHeaderCountrySelectorStatus(dispatch, { value })
  const setHeaderCountryListStatus = (value) =>
    actions.setHeaderCountryListStatus(dispatch, { value })
  const setHeaderMenuStatus = (value) =>
    actions.setHeaderMenuStatus(dispatch, { value })
  const setHeaderSearchStatus = (value) =>
    actions.setHeaderSearchStatus(dispatch, { value })

  const [countryItemHightlighted, setCountryItemHightlighted] = useState(0)
  const [countrySelectorInput, setCountrySelectorInput] = useState('')
  const [countryListData, setCountryListData] = useState([])

  const handleLogoClick = () => {
    setHeaderMenuStatus(false)
    handleMenu(false)
  }

  useEffect(() => {
    log('Header2021', {
      fields: { ...fields },
      rendering: { ...rendering },
    })
  }, [fields, rendering])

  return (
    <>
      <header
        ref={ref}
        className={`header-sticky ${
          !sticky
            ? 'down'
            : countrySelectorStatus || tagSelectorStatus
            ? 'up'
            : scrollDirection
        } ${passedThresholds[0] ? 'passed-first-threshold' : ''} ${
          passedThresholds[1] ? 'passed-second-threshold' : ''
        }`}
        style={{
          minHeight: passedThresholds[0] ? `${height}px` : null,
        }}
      >
        <div className="header2021-super-wrapper">
          <div className="header2021-wrapper">
            <div className="header2021-bottom-navigation-container">
              <NavLink
                to="/"
                className="header2021-logo-container"
                onClick={() => handleLogoClick()}
              >
                {logo && (
                  <Image
                    fields={{ image: logo }}
                    mediaList={getMediaList(150)}
                    originalSize
                    disableLazyLoad
                  />
                )}
                <div className="header2021-bottom-navigation-shadow" />
              </NavLink>
              <Search
                setHeaderSearchStatus={setHeaderSearchStatus}
                searchStatus={searchStatus}
                searchResultPage={searchResultPage}
                isMobile={true}
                templateId={templateId}
                itemId={itemId}
                inHeader
              />
              <CountrySelector
                countryList={countryList}
                countryListData={countryListData}
                countrySelectorStatus={countrySelectorStatus}
                setHeaderCountrySelectorStatus={setHeaderCountrySelectorStatus}
                countrySelectorInput={countrySelectorInput}
                setCountrySelectorInput={setCountrySelectorInput}
                countryListStatus={countryListStatus}
                disableCountryRedirection={disableCountryRedirection}
                setHeaderCountryListStatus={setHeaderCountryListStatus}
                onCountryItemHightlightedChanged={setCountryItemHightlighted}
                mobileVersion={true}
              />
              <div className="header2021-bottom-navigation-subcontainer">
                <Search
                  setHeaderSearchStatus={setHeaderSearchStatus}
                  searchStatus={searchStatus}
                  searchResultPage={searchResultPage}
                  isMobile={false}
                  templateId={templateId}
                  itemId={itemId}
                  inHeader
                />
                <CountrySelector
                  countryList={countryList}
                  countryListData={countryListData}
                  countrySelectorStatus={countrySelectorStatus}
                  setHeaderCountrySelectorStatus={
                    setHeaderCountrySelectorStatus
                  }
                  countrySelectorInput={countrySelectorInput}
                  setCountrySelectorInput={setCountrySelectorInput}
                  countryListStatus={countryListStatus}
                  disableCountryRedirection={disableCountryRedirection}
                  setHeaderCountryListStatus={setHeaderCountryListStatus}
                  onCountryItemHightlightedChanged={setCountryItemHightlighted}
                  mobileVersion={false}
                />
                <Burger
                  setHeaderMenuStatus={setHeaderMenuStatus}
                  handleMenu={handleMenu}
                  menuVisible={menuVisible}
                />
              </div>
            </div>

            <CountryList
              countryList={countryList}
              countryListData={countryListData}
              countrySelectorInput={countrySelectorInput}
              countryListStatus={countryListStatus}
              setCountryListData={setCountryListData}
              setHeaderCountrySelectorStatus={setHeaderCountrySelectorStatus}
              countryItemHightlighted={countryItemHightlighted}
            />
          </div>
        </div>
        <Menu
          navigationLinks={navigationLinks}
          menuMainImage={menuMainImage}
          menuHeadline={menuHeadline}
          setHeaderMenuStatus={setHeaderMenuStatus}
          menuVisible={menuVisible}
          handleMenu={handleMenu}
          id={id}
        />
      </header>
    </>
  )
}

const HeaderClose = (props) => {
  const { masterCategory } = props
  const history = useHistory()
  const lastLocation = useLastLocation()

  const { state } = useContext(Store)
  const { sticky } = state.header

  const onCloseClick = () => {
    if (lastLocation) {
      history.goBack()
    } else {
      history.push('/')
    }
  }

  return (
    <div
      className="header2021-close"
      data-move-offset={!sticky ? '' : undefined}
    >
      {lastLocation || !masterCategory ? (
        <button className="header2021-close-button" onClick={onCloseClick}>
          <Icon name="close" size={20} color="black" />
        </button>
      ) : (
        <NavLink
          className="header2021-close-button"
          to={{
            pathname: getCorrectURL(
              `${efo(masterCategory, 'fields.categoryPage.value.href')}`
            ),
            state: {
              layoutID: efo(masterCategory, 'fields.categoryPage.value.id'),
            },
          }}
        >
          <Icon name="close" size={20} color="black" />
        </NavLink>
      )}
    </div>
  )
}

const HeaderWrapper = (props) => {
  const { sitecoreContext } = props
  const fields = efo(sitecoreContext, 'route.fields')
  const masterCategory = efo(fields, 'masterCategory')

  const [isMobileView] = useState(isPortrait())
  const location = useLocation()

  if ((efo(location, 'pathname') || '').includes('article') && isMobileView) {
    return <HeaderClose masterCategory={masterCategory} />
  }
  if (
    (efo(location, 'pathname') || '').includes('/category/') &&
    isMobileView
  ) {
    return <HeaderClose masterCategory={masterCategory} />
  }

  return <Header {...props} />
}

Header.displayName = 'Header'

export default withSitecoreContext()(HeaderWrapper)
