import React from 'react'

import AbstractStaticTeaser from '../AbstractStaticTeaser'
import Slider, { Slide } from '../Slider'

import { getKey } from '../../../helpers'

import './styles.scss'

const StaticTeaserGrid = (props) => {
  const { staticTeasers, staticTeaserAnimationIndex } = props

  return (
    <div
      className="static-teaser-grid-container"
      data-hide={!staticTeasers || !staticTeasers.length ? '' : undefined}
    >
      <Slider slidesMargin={0} navigation>
        {(staticTeasers || []).map((data, i) => (
          <Slide key={getKey(data.id, i)}>
            <AbstractStaticTeaser
              {...data}
              index={i}
              stopAnimation={
                staticTeaserAnimationIndex !== -1 &&
                staticTeaserAnimationIndex > i
              }
            />
          </Slide>
        ))}
      </Slider>
    </div>
  )
}

export default StaticTeaserGrid
