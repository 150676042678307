import React, { useEffect, useRef, useState } from 'react'
import { withTranslation } from 'react-i18next'

import Image from '../../../shared/Image'
import Icon from '../../../shared/Icon'

import {
  getActiveCountry,
  getDefaultCountry,
  isMobile,
  setActiveCountry,
  log,
  efo,
  isFullURLStructure,
  getMediaList,
} from '../../../../helpers'

import './styles.scss'

const CountrySelector = withTranslation()(
  ({
    t,
    countryList,
    countryListData,
    countrySelectorStatus,
    countryListStatus,
    setHeaderCountrySelectorStatus,
    setHeaderCountryListStatus,
    countrySelectorInput,
    disableCountryRedirection,
    setCountrySelectorInput,
    onCountryItemHightlightedChanged,
  }) => {
    const [country, setCountry] = useState(undefined)
    const [cursor, setCursor] = useState(0)

    const inputRef = useRef()

    useEffect(() => {
      log('Country Selector', {
        countryList: { ...countryList },
        countryListData: { ...countryListData },
        countrySelectorStatus,
        countryListStatus,
      })
    }, [countryList, countryListData, countrySelectorStatus, countryListStatus])

    useEffect(() => {
      const activeCountry = disableCountryRedirection
        ? getDefaultCountry(countryList)
        : getActiveCountry(countryList)

      setCountry(activeCountry)
      setActiveCountry(activeCountry)
    }, [countryList, disableCountryRedirection])

    useEffect(() => {
      if (countrySelectorStatus && !countryListStatus) {
        setHeaderCountryListStatus(true)
        if (inputRef && inputRef.current) {
          setTimeout(() => inputRef.current.focus(), 240)
        }
      } else if (!countrySelectorStatus && countryListStatus) {
        setHeaderCountryListStatus(false)
        if (inputRef && inputRef.current) {
          inputRef.current.value = ''
          setCountrySelectorInput('')
        }
      }
    }, [
      countrySelectorStatus,
      countryListStatus,
      setCountrySelectorInput,
      setHeaderCountryListStatus,
    ])

    useEffect(() => {
      setCursor(0)
    }, [countrySelectorInput])

    const handleKeyDown = (e) => {
      if (e.keyCode === 13) {
        // KEY ENTER
        try {
          const targetCountry = countryListData[cursor]
          const targetCountryLink = efo(
            targetCountry,
            'targetSite.fields.sitePath.value'
          )

          // Try to go to the selected country
          setActiveCountry(targetCountry)
          setHeaderCountrySelectorStatus(false)
          setTimeout(() => {
            window.location.href = !isFullURLStructure()
              ? targetCountryLink.replace(/^\/motorsport/gm, '')
              : targetCountryLink
          }, 500)
          updateSelectedCountry(0)
        } catch (e) {
          console.e(`Cannot go to target country URL: ${e}`)
        }
        return
      }

      // arrow up/down button should select next/previous list element
      if (e.keyCode === 38 && cursor > 0) {
        // KEY DOWN
        updateSelectedCountry(cursor - 1)
      } else if (e.keyCode === 40 && cursor < countryListData.length - 1) {
        // KEY UP
        updateSelectedCountry(cursor + 1)
      }
    }

    const updateSelectedCountry = (countryIndex) => {
      setCursor(countryIndex)
      onCountryItemHightlightedChanged(countryIndex)
    }

    return (
      <div
        className="header-country-selector-container"
        data-active={countrySelectorStatus ? '' : undefined}
      >
        <div
          className="header-country-selector-subcontainer"
          data-active={countrySelectorStatus ? '' : undefined}
          onClick={() => setHeaderCountrySelectorStatus(!countrySelectorStatus)}
        >
          {country && country.countryFlag ? (
            <Image
              fields={{
                image: country.countryFlag.value
                  ? country.countryFlag
                  : { value: country.countryFlag },
              }}
              mediaList={getMediaList(50)}
              disableLazyLoad
            />
          ) : getDefaultCountry(countryList) ? (
            <Image
              fields={{ image: getDefaultCountry(countryList).countryFlag }}
              mediaList={getMediaList(50)}
              disableLazyLoad
            />
          ) : undefined}
          <div>
            <Icon name="close" size={isMobile() ? 20 : 30} />
          </div>
        </div>
        <div
          className="header-country-selector-input-container"
          data-active={countrySelectorStatus ? '' : undefined}
          data-typing={countrySelectorInput !== '' ? '' : undefined}
        >
          <input
            ref={inputRef}
            type="search"
            placeholder={t('country-selector-input-placeholder')}
            value={countrySelectorInput}
            onChange={(event) => setCountrySelectorInput(event.target.value)}
            onKeyDown={handleKeyDown}
          />
          <div
            className="header-country-selector-input-clear"
            data-show={countrySelectorInput !== '' ? '' : undefined}
            onClick={() => {
              if (inputRef && inputRef.current) {
                inputRef.current.value = ''
                setCountrySelectorInput('')
              }
            }}
          >
            <Icon name="close" size={20} />
          </div>
        </div>
      </div>
    )
  }
)

export default CountrySelector
