import React, { useEffect, useRef, useCallback } from 'react'
import { useInView } from 'react-intersection-observer'

import withMinimumRequirements from '../../../withMinimumRequirements'
import Drivers from '../../shared/Drivers'

import {
  efo,
  log,
  getModulePosition,
  pushGlEvent,
  getDataSourceName,
} from '../../../helpers'

import './styles.scss'

const ArticleBodyDrivers = (props) => {
  const { fields } = props
  const { layout } = fields
  const ref = useRef()
  const [inViewRefTrack, inViewTrack] = useInView({
    threshold: 0.3,
  })

  const setRefs = useCallback(
    (node) => {
      ref.current = node
      inViewRefTrack(node)
    },
    [inViewRefTrack]
  )

  useEffect(() => {
    log('Article Body Drivers', { ...fields })
  }, [fields])

  // Tracking load
  useEffect(() => {
    if (typeof window !== 'undefined' && window.GDL) {
      pushGlEvent(
        false,
        'Drivers',
        [],
        'Drivers',
        getModulePosition('article-body-drivers-container', ref),
        'Drivers',
        efo(props, 'rendering.uid'),
        'Drivers'
      )
    }
  }, [props])

  // Tracking in view
  useEffect(() => {
    if (inViewTrack && typeof window !== 'undefined' && window.GDL) {
      pushGlEvent(
        true,
        'Drivers',
        [],
        'Drivers',
        getModulePosition('article-body-drivers-container', ref),
        'Drivers',
        efo(props, 'rendering.uid'),
        'Drivers'
      )
    }
  }, [inViewTrack, props])

  return (
    <div
      className="article-body-drivers-container"
      data-layout={efo(layout, 'value')}
      ref={setRefs}
      id={getDataSourceName(efo(props, 'rendering.dataSource'))}
    >
      <Drivers {...fields} />
    </div>
  )
}

export default withMinimumRequirements(ArticleBodyDrivers, [
  'fields.layout.value',
  {
    mode: 'one-of',
    structure: ['fields.drivers.value', 'fields.drivers'],
  },
  {
    mode: 'one-of',
    structure: ['fields.drivers.value.0', 'fields.drivers.0'],
  },
])
