import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Text,
  RichText,
  isEditorActive,
} from '@sitecore-jss/sitecore-jss-react'
import uniqueId from 'lodash/uniqueId'

import withMinimumRequirements from '../../../withMinimumRequirements'
import Slider, { Slide } from '../../shared/Slider/SliderOnPortal'
import { default as ImageC } from '../../shared/Image'
import Icon from '../../shared/Icon'
import { Portal } from '../../shared/Modal'

import { actions, Store } from '../../../store'
import {
  getKey,
  isMobile,
  getTable,
  efo,
  getMediaList,
  waitForElementInDom,
} from '../../../helpers'
import { DRIVERS_LAYOUT_GRID, DRIVERS_LAYOUT_SLIDER } from '../../../constants'

import './styles.scss'

const DriverModalSlide = (props) => {
  const { fields } = props
  const {
    image,
    carImage,
    driverNumber,
    driverType,
    name,
    twitterURL,
    facebookURL,
    instagramURL,
    websiteURL,
    table,
    description,
    races,
  } = fields

  const [t] = useTranslation()

  const [imageVisible, setImageVisible] = useState('user')
  const [imageVisibleName, setimageVisibleName] = useState('car')
  const [carImageLoaded, setCarImageLoaded] = useState(false)
  const [carImageLoading, setCarImageLoading] = useState(false)

  const handleImageSwitch = () => {
    if (imageVisibleName === 'car') {
      setimageVisibleName('user')
      if (carImageLoaded) {
        setImageVisible('car')
      } else {
        setCarImageLoading(true)
        let carImageLoad = new Image()
        carImageLoad.onload = () => {
          setImageVisible('car')
          waitForElementInDom('.general-driver-car-image').then(() =>
            setCarImageLoaded(true)
          )
        }
        carImageLoad.src = carImage.value.src
      }
    }
    if (imageVisibleName === 'user') {
      setimageVisibleName('car')
      setImageVisible('user')
    }
  }

  const handleLoadingAnimationEnd = () => {
    setCarImageLoading(false)
  }

  return (
    <div className="general-driver-modal-slide-container">
      {efo(carImage, 'value.src') ? (
        <>
          <div
            className="general-driver-modal-car-switch"
            onClick={handleImageSwitch}
          >
            <Icon name={imageVisibleName} size={25} />
          </div>
          <div className="general-driver-modal-image-container">
            {imageVisible === 'user' ? (
              <ImageC
                fields={{ image: image }}
                ratio={image.value.height / image.value.width}
                mediaList={getMediaList(300)}
              />
            ) : (
              <div className="general-driver-car-image">
                <ImageC
                  fields={{ image: carImage }}
                  ratio={carImage.value.height / carImage.value.width}
                  mediaList={getMediaList(300)}
                />
              </div>
            )}
            {carImageLoading && (
              <div
                className={`general-driver-modal-image-loading ${
                  carImageLoaded && 'general-driver-modal-image-loading-out'
                }`}
                onAnimationEnd={handleLoadingAnimationEnd}
              />
            )}
          </div>
        </>
      ) : (
        <ImageC fields={{ image }} mediaList={getMediaList(300)} />
      )}
      <div className="general-driver-modal-data-container">
        <div className="general-driver-modal-share-container">
          {efo(twitterURL, 'value.href') && (
            <a
              className="general-driver-modal-share-element-container"
              href={efo(twitterURL, 'value.href')}
              target={efo(twitterURL, 'value.target') || '_blank'}
            >
              <Icon name="logoTwitter" size={isMobile ? 20 : 25} />
            </a>
          )}
          {efo(facebookURL, 'value.href') && (
            <a
              className="general-driver-modal-share-element-container"
              href={efo(facebookURL, 'value.href')}
              target={efo(facebookURL, 'value.target') || '_blank'}
            >
              <Icon name="logoFacebook" size={isMobile ? 20 : 25} />
            </a>
          )}
          {efo(instagramURL, 'value.href') && (
            <a
              className="general-driver-modal-share-element-container"
              href={efo(instagramURL, 'value.href')}
              target={efo(instagramURL, 'value.target') || '_blank'}
            >
              <Icon name="logoInstagram" size={isMobile ? 20 : 25} />
            </a>
          )}
          {efo(websiteURL, 'value.href') && (
            <a
              className="general-driver-modal-share-element-container"
              href={efo(websiteURL, 'value.href')}
              target={efo(websiteURL, 'value.target') || '_blank'}
            >
              <Icon name="globe" size={isMobile ? 20 : 25} />
            </a>
          )}
        </div>
        <div className="general-driver-modal-subline-container">
          {!driverType ? (
            <span>{t('drivers-subline')}</span>
          ) : (
            <Text tag="span" field={driverType} />
          )}
          {driverNumber ? <Text tag="span" field={driverNumber} /> : undefined}
        </div>
        <Text tag="h3" field={name} />
        {table ? (
          <div className="general-driver-modal-table-container">
            {getTable(table).map((tableData, index) => (
              <div
                key={index}
                className="general-driver-modal-table-line-container"
              >
                <Text tag="span" field={tableData.description} />
                <Text tag="span" field={tableData.text} />
              </div>
            ))}
          </div>
        ) : undefined}
        {(races || []).length ? (
          <div className="general-driver-modal-race-container">
            <h3>{t('team-listing-history')}</h3>
            {(races || []).map((race, raceIndex) => (
              <div
                className="general-driver-modal-subrace"
                key={getKey(race.id, raceIndex)}
              >
                <h4 className="general-driver-modal-subrace-year">
                  {efo(race, 'fields.year.value')}
                </h4>
                {efo(race, 'fields.races', []).map((subrace, subraceIndex) => (
                  <div
                    className="general-driver-modal-subrace-text"
                    key={getKey(subrace.id, subraceIndex)}
                  >
                    {efo(subrace, 'fields.placement.value') && (
                      <span className="general-driver-modal-subrace-placement">
                        <Text
                          tag="span"
                          field={efo(subrace, 'fields.placement')}
                        />
                        <span className="general-driver-modal-subrace-placement-sep">
                          .
                        </span>
                        <span>{t('race-history-race-placement')}</span>
                      </span>
                    )}
                    <Text
                      tag="span"
                      className="general-driver-modal-subrace-race"
                      field={efo(subrace, 'fields.race')}
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>
        ) : undefined}
        {description ? (
          <RichText
            className="general-driver-modal-description-container"
            tag="div"
            field={description}
          />
        ) : undefined}
      </div>
    </div>
  )
}

const ProtectedDriverModalSlide = withMinimumRequirements(DriverModalSlide, [
  'fields.name.value',
  'fields.image.value',
])

const DriverModal = (props) => {
  const { drivers, index, setShowModal } = props

  const { dispatch } = useContext(Store)

  return (
    <>
      <div className="general-driver-modal-close-container">
        <div
          className="general-driver-modal-close-icon"
          onClick={() => {
            setShowModal(false)
            actions.setModalHide(dispatch)
          }}
        >
          <Icon name="close" size={isMobile ? 20 : 25} />
        </div>
      </div>
      <div className="general-driver-modal-body-container">
        <Slider slidesMargin={35} index={index} watchOverflow centeredSlides>
          {drivers.map((driver, index) => (
            <Slide key={getKey(driver.id, index)}>
              <ProtectedDriverModalSlide {...driver} isOnSlider />
            </Slide>
          ))}
        </Slider>
      </div>
    </>
  )
}

const ProtectedDriverModal = withMinimumRequirements(DriverModal, [
  'drivers',
  'drivers.0',
])

const DriverSlide = (props) => {
  const { iid, index, mini, fields, isOnSlider, setShowModal } = props
  const { image, name, driverNumber, driverType } = fields

  const [t] = useTranslation()
  const { dispatch } = useContext(Store)

  return (
    <div
      className="general-driver-slider-container"
      data-is-on-slider={isOnSlider ? '' : undefined}
      onClick={() => {
        actions.setModalShow(dispatch, {
          iid,
          index,
        })
        setShowModal(true)
      }}
    >
      <ImageC
        fields={{ image }}
        mediaList={mini ? getMediaList(200) : getMediaList(370)}
      />
      <div className="general-driver-data-container">
        <div className="general-driver-subline-container">
          {!driverType && !isEditorActive() ? (
            <span>{t('drivers-subline')}</span>
          ) : (
            <Text tag="span" field={driverType} />
          )}
          <Text tag="span" field={driverNumber} />
        </div>
        <Text tag="h3" field={name} />
      </div>
    </div>
  )
}

const ProtectedDriverSlide = withMinimumRequirements(DriverSlide, [
  'fields.name.value',
  'fields.image.value',
])

const getDrivers = (drivers) => efo(drivers, 'value') || drivers || []

const DriverList = (props) => {
  const { headline, subline, layout, drivers, mini, disableAutoFormat } = props

  const [t] = useTranslation()

  const { state } = useContext(Store)
  const { data: modalData } = state.modal
  const [iid] = useState(uniqueId('drivers_'))
  const [showModal, setShowModal] = useState(false)

  return (
    <div
      className="general-drivers-container"
      data-mini={mini ? '' : undefined}
      data-layout={layout.value}
      data-disabled-auto-format={disableAutoFormat ? '' : undefined}
    >
      {headline || isEditorActive() ? (
        <Text data-mini={mini ? '' : undefined} tag="h2" field={headline} />
      ) : undefined}
      {mini && !headline ? <h2 data-mini>{t('drivers-title')}</h2> : undefined}
      {(subline || isEditorActive()) && !mini ? (
        <Text tag="span" field={subline} />
      ) : undefined}
      {(!isMobile() || disableAutoFormat) &&
      layout.value === DRIVERS_LAYOUT_GRID ? (
        <div className="general-drivers-grid-container">
          {getDrivers(drivers).map((driver, index) => (
            <ProtectedDriverSlide
              key={getKey(driver.id, index)}
              iid={iid}
              index={index}
              mini={mini}
              setShowModal={setShowModal}
              {...driver}
            />
          ))}
        </div>
      ) : isMobile() ||
        !disableAutoFormat ||
        layout.value === DRIVERS_LAYOUT_SLIDER ? (
        <Slider pagination slidesMargin={13}>
          {getDrivers(drivers).map((driver, index) => (
            <Slide key={getKey(driver.id, index)}>
              <ProtectedDriverSlide
                iid={iid}
                index={index}
                mini={mini}
                setShowModal={setShowModal}
                {...driver}
                isOnSlider
              />
            </Slide>
          ))}
        </Slider>
      ) : undefined}
      {modalData &&
      modalData.index !== undefined &&
      modalData.iid === iid &&
      showModal ? (
        <Portal>
          <ProtectedDriverModal
            {...modalData}
            drivers={getDrivers(drivers)}
            setShowModal={setShowModal}
          />
        </Portal>
      ) : undefined}
    </div>
  )
}

export default withMinimumRequirements(DriverList, [
  'layout.value',
  {
    mode: 'one-of',
    structure: ['drivers.value', 'drivers'],
  },
  {
    mode: 'one-of',
    structure: ['drivers.value.0', 'drivers.0'],
  },
])
