import React, { useEffect } from 'react'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import decodeUriComponent from 'decode-uri-component'

import withMinimumRequirements from '../../../withMinimumRequirements'

import { efo, log } from '../../../helpers'

import './styles.scss'

const getTable = (rawTable) => {
  const table = []

  for (let row of efo(rawTable, 'value').split(/&/gm)) {
    table.push((row.split(/=/gm) || []).map((v) => decodeUriComponent(v)))
  }

  return table
}

const ArticleBodyTableSection = (props) => {
  const { fields } = props
  const { title, table } = fields

  useEffect(() => {
    log('Article Body Table > Section', { ...fields })
  }, [fields])

  return (
    <div className="article-body-table-section-container">
      {title ? <Text tag="h3" field={title} /> : undefined}
      <div className="article-body-table-section-subcontainer">
        {getTable(table).map((values, i) => (
          <div key={i} className="article-body-table-section-row">
            {values.map((value, j) => (
              <span key={j}>{value}</span>
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}

export default withMinimumRequirements(ArticleBodyTableSection, [
  'fields.table.value',
])
