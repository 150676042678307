import React, { useEffect, useRef, useCallback } from 'react'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import { useInView } from 'react-intersection-observer'

import withMinimumRequirements from '../../../withMinimumRequirements'
import Video from '../../shared/Video'

import {
  efo,
  log,
  getSitecoreRatio,
  getModulePosition,
  pushGlEvent,
  getDataSourceName,
} from '../../../helpers'

import './styles.scss'

const ArticleBodyVideo = (props) => {
  //log('ArticleBodyVideo::props_____________________', props)

  const { fields } = props

  const { image, caption } = fields

  const ref = useRef()
  const [inViewRefTrack, inViewTrack] = useInView({
    threshold: 0.3,
  })

  const setRefs = useCallback(
    (node) => {
      ref.current = node
      inViewRefTrack(node)
    },
    [inViewRefTrack]
  )

  useEffect(() => {
    log('Article Body Video', { ...fields })
  }, [fields])

  // Tracking load
  useEffect(() => {
    if (typeof window !== 'undefined' && window.GDL) {
      pushGlEvent(
        false,
        'Video',
        [],
        'Video',
        getModulePosition('article-body-video-container', ref),
        'Video',
        efo(props, 'rendering.uid'),
        'Video'
      )
    }
  }, [props])

  // Tracking in view
  useEffect(() => {
    if (inViewTrack && typeof window !== 'undefined' && window.GDL) {
      pushGlEvent(
        true,
        'Video',
        [],
        'Video',
        getModulePosition('article-body-video-container', ref),
        'Video',
        efo(props, 'rendering.uid'),
        'Video'
      )
    }
  }, [inViewTrack, props])

  return (
    <div
      className="article-body-video-container"
      id={getDataSourceName(efo(props, 'rendering.dataSource'))}
      ref={setRefs}
    >
      <Video
        UC_READY={props.UC_READY}
        UC_PROCESSOR={props.UC_PROCESSOR}
        url={
          efo(fields, 'videofile.value.src') ||
          efo(fields, 'videourl.value.url') ||
          efo(fields, 'videourl.value.href')
        }
        image={efo(image, 'value')}
        imageField={image}
        options={{
          autoplay: !!efo(fields, 'autoplay.value'),
          loop: !!efo(fields, 'loop.value'),
          playOnHover: !!efo(fields, 'playonhover.value'),
          livestream: !!efo(fields, 'livestream.value'),
          muted: !!efo(fields, 'muted.value'),
          aspectRatio: getSitecoreRatio(efo(fields, 'aspectRatio')),
        }}
      />
      {caption ? (
        <Text className="article-body-video-caption" tag="p" field={caption} />
      ) : undefined}
    </div>
  )
}

export default withMinimumRequirements(ArticleBodyVideo, [
  'fields.image.value',
  {
    mode: 'one-of',
    structure: [
      'fields.videofile.value.src',
      'fields.videourl.value.url',
      'fields.videourl.value.href',
    ],
  },
])
