const types = {
  GRID: {
    SET: 'GRID.SET',
  },
}

const initialState = {
  grids: {},
}

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.GRID.SET:
      return {
        ...state,
        grids: {
          ...state.grids,
          [action.id]: action.values,
        },
      }
    default:
      return state
  }
}

const setGrid = (dispatch, values) =>
  dispatch({
    type: types.GRID.SET,
    ...values,
  })

export default {
  initialState,
  reducer,
  actions: {
    setGrid,
  },
}
