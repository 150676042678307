import React, { useEffect, useRef, useCallback } from 'react'
import { RichText } from '@sitecore-jss/sitecore-jss-react'
import { useInView } from 'react-intersection-observer'

import withMinimumRequirements from '../../../withMinimumRequirements'

import {
  efo,
  log,
  getModulePosition,
  pushGlEvent,
  getDataSourceName,
} from '../../../helpers'

import './styles.scss'

const ArticleBodySmallText = (props) => {
  const { fields } = props

  const ref = useRef()
  const [inViewRefTrack, inViewTrack] = useInView({
    threshold: 0.3,
  })

  const setRefs = useCallback(
    (node) => {
      ref.current = node
      inViewRefTrack(node)
    },
    [inViewRefTrack]
  )

  useEffect(() => {
    log('Article Body Small Text', { ...fields })
  }, [fields])

  // Tracking load
  useEffect(() => {
    if (typeof window !== 'undefined' && window.GDL) {
      pushGlEvent(
        false,
        'SmallText',
        [],
        'SmallText',
        getModulePosition('article-body-small-text-container', ref),
        'SmallText',
        efo(props, 'rendering.uid'),
        'SmallText'
      )
    }
  }, [props])

  // Tracking in view
  useEffect(() => {
    if (inViewTrack && typeof window !== 'undefined' && window.GDL) {
      pushGlEvent(
        true,
        'SmallText',
        [],
        'SmallText',
        getModulePosition('article-body-small-text-container', ref),
        'SmallText',
        efo(props, 'rendering.uid'),
        'SmallText'
      )
    }
  }, [inViewTrack, props])

  return (
    <div
      className="article-body-small-text-container"
      id={getDataSourceName(efo(props, 'rendering.dataSource'))}
      ref={setRefs}
    >
      <RichText
        className="article-body-small-text-subcontainer"
        field={efo(fields, 'text')}
      />
    </div>
  )
}

export default withMinimumRequirements(ArticleBodySmallText, [
  'fields.text.value',
])
