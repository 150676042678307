import React from 'react'
import PropTypes from 'prop-types'

import withMinimumRequirements from '../../../withMinimumRequirements'

import { getConfig } from '../../../helpers'

import './styles.scss'

const Icon = (props) => {
  const { name, size, color, className, onClick, ...other } = props

  delete other.sitecoreContext
  delete other.updateSitecoreContext

  return (
    <div
      {...other}
      className={`icon-container ${className}`}
      style={{
        '--icon-size': `${size}px`,
        '--icon-color': color,
      }}
      data-clicable={onClick ? '' : undefined}
      onClick={onClick}
    >
      <svg>
        <use
          href={`${
            getConfig('website.distPath') || ''
          }/assets/img/icons.svg#${name}`}
        />
      </svg>
    </div>
  )
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
}

Icon.defaultProps = {
  size: 20,
  className: '',
  onClick: undefined,
}

export default withMinimumRequirements(Icon, ['name'])
