import React from 'react'

import { isDebug } from '../../../helpers'

import './styles.scss'

const MinimumRequirements = (props) => {
  const { componentName, componentProps, structure } = props
  let parsedComponentProps

  if (!isDebug()) {
    return null
  }

  try {
    parsedComponentProps = JSON.stringify(componentProps || {}, null, 2)
  } catch (error) {}

  return (
    <div className="min-req-container">
      <h2>{`"${componentName}" not matching minimum requirements`}</h2>
      <span>Component requires the next props in order to work:</span>
      <ul>
        {structure.map((s, i) => {
          if (typeof s === 'string') {
            return (
              <li key={i}>
                <code>{s}</code>
              </li>
            )
          } else if (
            typeof s === 'object' &&
            !Array.isArray(s) &&
            s.mode &&
            s.structure
          ) {
            return (
              <li key={i}>
                Mode <code>{s.mode}</code>
                <ul>
                  {s.structure.map((subs, j) => (
                    <li key={j}>
                      <code>{subs}</code>
                    </li>
                  ))}
                </ul>
              </li>
            )
          }

          return null
        })}
      </ul>
      {parsedComponentProps ? (
        <>
          <span>Component given props are the next ones:</span>
          <div className="min-req-props">
            <pre>
              <code>{parsedComponentProps}</code>
            </pre>
          </div>
        </>
      ) : undefined}
      <span
        className="min-req-note"
        dangerouslySetInnerHTML={{
          __html: `You are seeing this because debugging is enabled (by default on this server, or enabled via <code>window.debug()</code>)`,
        }}
      />
    </div>
  )
}

export default MinimumRequirements
