import React, { useEffect, useRef, useCallback } from 'react'
import { useInView } from 'react-intersection-observer'

import withMinimumRequirements from '../../../withMinimumRequirements'
import Countdown from '../../shared/Countdown'

import {
  efo,
  log,
  getModulePosition,
  pushGlEvent,
  getDataSourceName,
} from '../../../helpers'

import './styles.scss'

const ArticleBodyCountdown = (props) => {
  const { fields } = props
  const {
    image,
    title,
    startDate,
    endDate,
    partner,
    subtitle,
    eventTitle,
    eventDescription,
    eventLocation,
    showSeconds,
  } = fields
  //const id = efo(props, 'rendering.uid').slice(1, -1)
  const ref = useRef()
  const [inViewRefTrack, inViewTrack] = useInView({
    threshold: 0.3,
  })

  const setRefs = useCallback(
    (node) => {
      ref.current = node
      inViewRefTrack(node)
    },
    [inViewRefTrack]
  )

  useEffect(() => {
    log('Article Body Countdown', { ...fields })
  }, [fields])

  // Tracking load
  useEffect(() => {
    if (typeof window !== 'undefined' && window.GDL) {
      pushGlEvent(
        false,
        'Countdown',
        [],
        'Countdown',
        getModulePosition('article-body-countdown-container', ref),
        'Countdown',
        efo(props, 'rendering.uid'),
        efo(title, 'value')
      )
    }
  }, [fields, title, props])

  // Tracking in view
  useEffect(() => {
    if (inViewTrack && typeof window !== 'undefined' && window.GDL) {
      pushGlEvent(
        true,
        'Countdown',
        [],
        'Countdown',
        getModulePosition('article-body-countdown-container', ref),
        'Countdown',
        efo(props, 'rendering.uid'),
        efo(title, 'value')
      )
    }
  }, [inViewTrack, title, props])

  return (
    <div
      className="article-body-countdown-container"
      id={getDataSourceName(efo(props, 'rendering.dataSource'))}
      ref={setRefs}
    >
      <Countdown
        poster={image}
        title={efo(title, 'value')}
        startDate={efo(startDate, 'value')}
        endDate={efo(endDate, 'value')}
        countdownPartner={partner}
        countdownSubtitle={efo(subtitle, 'value')}
        eventTitle={efo(eventTitle, 'value')}
        eventDescription={efo(eventDescription, 'value')}
        eventLocation={efo(eventLocation, 'value')}
        showSeconds={efo(showSeconds, 'value')}
        isArticleBody
      />
    </div>
  )
}

export default withMinimumRequirements(ArticleBodyCountdown, [
  'fields.startDate.value',
])
