import React from 'react'

import './styles.scss'

const getRatio = (ratio) => {
  let coeficient = 0

  if (ratio) {
    try {
      if (typeof ratio === 'string') {
        // e.g. "16:9"
        const values = ratio.split(':')

        coeficient = Number(values[1]) / Number(values[0])
      } else if (typeof ratio === 'number') {
        coeficient = ratio
      }
    } catch (error) {}
  }

  return (coeficient * 100).toFixed(2)
}

const DynamicContainer = (props) => {
  const { ratio, children, className, disable, ...rest } = props

  return (
    <div
      className={`${className || ''} general-dynamic-container`}
      style={
        !disable
          ? {
              paddingTop: `${getRatio(ratio)}%`,
            }
          : {
              height: '100%',
            }
      }
      {...rest}
    >
      <div className="general-dynamic-subcontainer">{children}</div>
    </div>
  )
}

export default DynamicContainer
