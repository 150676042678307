import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import withMinimumRequirements from '../../../withMinimumRequirements'
import Image from '../Image'
import Icon from '../Icon'

import useTime from '../../../hooks/useTime'

import { ADD_TO_CALENDAR_OPTIONS } from '../../../constants'
import { efo, getMediaList } from '../../../helpers'

import './styles.scss'

const getNumber = (type, currentDate, startDate) => {
  const date = new Date(startDate)
  const diff = (date.getTime() - currentDate) / 1000
  let value = 0

  switch (type) {
    case 'seconds':
      if (diff > 0) {
        value = Math.abs(Math.round(diff % 60))
      }
      if (value < 10) {
        value = `0${value}`
      }
      break
    case 'minutes':
      if (diff > 0) {
        value = Math.abs(Math.floor((diff / 60) % 60))
      }
      if (value < 10) {
        value = `0${value}`
      }
      break
    case 'hours':
      if (diff > 0) {
        value = Math.abs(Math.floor((diff / 60 / 60) % 24))
      }
      if (value < 10) {
        value = `0${value}`
      }
      break
    case 'days':
      if (diff > 0) {
        value = Math.abs(Math.floor(diff / 60 / 60 / 24))
      }
      break
    default:
      value = 0
  }

  return value
}

const TimeNumber = ({ type, text, currentDate, startDate }) => (
  <div className={`countdown-time-${type}`}>
    <span>{getNumber(type, currentDate, startDate)}</span>
    <span>{text}</span>
  </div>
)

TimeNumber.propTypes = {
  type: PropTypes.oneOf(['days', 'hours', 'minutes', 'seconds']).isRequired,
  text: PropTypes.string.isRequired,
  currentDate: PropTypes.number.isRequired,
  startDate: PropTypes.string.isRequired,
}

const TimeSeparator = (props) => (
  <div className="countdown-time-separator" {...props}>
    <span>:</span>
  </div>
)

const parseDate = (date) => date.replace(/([-|:])/gm, '')

const Countdown = (props) => {
  const {
    t,
    poster,
    title,
    startDate,
    endDate,
    showSeconds,
    countdownPartner,
    countdownSubtitle: cSubtitle,
    subtitle,
    eventTitle,
    eventDescription,
    eventLocation,
    noArticle,
    isHovering,
    isTeaser,
    isStaticTeaser,
    isArticleHero,
    isArticleBody,
  } = props

  const containerRef = useRef()

  const currentDate = useTime()

  const [showATCOverlay, setShowATCOverlay] = useState(false)

  const isOneToOne =
    (poster && efo(poster, 'value.width') === efo(poster, 'value.height')) ||
    getNumber('days', currentDate, startDate) > 9 ||
    isArticleBody ||
    isArticleHero
  const showDaySeparated = isOneToOne || isStaticTeaser

  const countdownSubtitle = cSubtitle || subtitle

  return (
    <div
      ref={containerRef}
      className="countdown-container"
      data-no-article={noArticle ? '' : undefined}
      data-is-hovering={isHovering ? '' : undefined}
      data-day-separated={showDaySeparated ? '' : undefined}
      data-is-static-teaser={isStaticTeaser ? '' : undefined}
      data-atc-overlay-shown={showATCOverlay ? '' : undefined}
      data-is-one-to-one={isOneToOne ? '' : undefined}
      data-is-article-hero={isArticleHero ? '' : undefined}
      data-is-article-body={isArticleBody ? '' : undefined}
      data-has-partner={countdownPartner ? '' : undefined}
      data-no-poster={!poster ? '' : undefined}
    >
      {poster ? (
        <div className="countdown-image">
          <Image
            fields={{
              image: poster,
            }}
            mediaList={
              isTeaser
                ? getMediaList(400)
                : isStaticTeaser
                ? getMediaList(182, 282, 282, 282, 282)
                : getMediaList()
            }
            cover
          />
        </div>
      ) : undefined}
      <div className="countdown-overlay" />
      <div
        className="countdown-subcontainer"
        data-hide={showATCOverlay ? '' : undefined}
      >
        <div className="countdown-data-container">
          <div className="countdown-content-container">
            {countdownPartner ? (
              <div className="countdown-partner-container">
                <Image
                  fields={{
                    image: countdownPartner,
                  }}
                  mediaList={getMediaList(50)}
                />
              </div>
            ) : countdownSubtitle ? (
              <span className="countdown-subtitle">{countdownSubtitle}</span>
            ) : undefined}
            {title ? (
              <span className="countdown-title">{title}</span>
            ) : undefined}
            {showDaySeparated ? (
              <div className="countdown-time-days-separated">
                <TimeNumber
                  type="days"
                  text={t('countdown-days')}
                  currentDate={currentDate}
                  startDate={startDate}
                />
              </div>
            ) : undefined}
            <div className="countdown-time-container">
              {!showDaySeparated ? (
                <TimeNumber
                  type="days"
                  text={t('countdown-days')}
                  currentDate={currentDate}
                  startDate={startDate}
                />
              ) : undefined}
              <TimeNumber
                type="hours"
                text={t('countdown-hours')}
                currentDate={currentDate}
                startDate={startDate}
              />
              <TimeSeparator />
              <TimeNumber
                type="minutes"
                text={t('countdown-minutes')}
                currentDate={currentDate}
                startDate={startDate}
              />
              {showSeconds && (
                <>
                  <TimeSeparator data-seconds />
                  <TimeNumber
                    type="seconds"
                    text={t('countdown-seconds')}
                    currentDate={currentDate}
                    startDate={startDate}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className="countdown-atc-button-container"
        data-hide={showATCOverlay ? '' : undefined}
        onClick={(event) => {
          event.preventDefault()
          event.stopPropagation()
          setShowATCOverlay(true)
        }}
      >
        <Icon name="calendar" color="white" size={25} />
        <span>{t('add-to-calendar-button')}</span>
      </div>
      <div
        className="countdown-atc-overlay-container"
        data-show={showATCOverlay ? '' : undefined}
      >
        {ADD_TO_CALENDAR_OPTIONS.map((option) => (
          <div
            key={option.name}
            className="countdown-atc-option-container"
            onClick={(event) => {
              event.preventDefault()
              event.stopPropagation()
              option.action(
                startDate,
                endDate,
                eventLocation,
                eventTitle,
                eventDescription,
                parseDate
              )
            }}
          >
            <Icon name="arrowHeadRight" />
            <span>{option.name}</span>
          </div>
        ))}
        <div
          className="countdown-atc-cancel-container"
          onClick={(event) => {
            event.preventDefault()
            event.stopPropagation()
            setShowATCOverlay(false)
          }}
        >
          <Icon name="close" size={20} />
          <span>{t('add-to-calendar-cancel-button')}</span>
        </div>
      </div>
    </div>
  )
}

export default withTranslation()(
  withMinimumRequirements(Countdown, ['startDate'])
)
