import React, { useEffect, useRef, useContext } from 'react'
import ReactDOM from 'react-dom'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

import withMinimumRequirements from '../../../withMinimumRequirements'

import { Store } from '../../../store'

import './styles.scss'

const modalID = 'modal-root'
const isNode = !(typeof window !== 'undefined' && window !== null)

export const Portal = withMinimumRequirements(
  (props) => {
    const { id = modalID, children } = props

    const element = !isNode ? document.createElement('div') : null
    const modalRoot = !isNode ? document.getElementById(id) : null

    useEffect(() => {
      // The portal element is inserted in the DOM tree after
      // the Modal's children are mounted, meaning that children
      // will be mounted on a detached DOM node. If a child
      // component requires to be attached to the DOM tree
      // immediately when mounted, for example to measure a
      // DOM node, or uses 'autoFocus' in a descendant, add
      // state to Modal and only render the children when Modal
      // is inserted in the DOM tree.
      if (modalRoot) {
        modalRoot.appendChild(element)
      }

      return () => {
        if (modalRoot) {
          modalRoot.removeChild(element)
        }
      }
    })

    return !isNode ? ReactDOM.createPortal(children, element) : <>{children}</>
  },
  ['children']
)

const Modal = () => {
  const modalRef = useRef()

  const { state } = useContext(Store)
  const { show } = state.modal

  useEffect(() => {
    if (modalRef && modalRef.current) {
      if (show) {
        disableBodyScroll(modalRef.current)
      } else {
        enableBodyScroll(modalRef.current)
      }
    }
  })

  return (
    <div
      ref={modalRef}
      className="general-modal-container"
      data-show={show ? '' : undefined}
    >
      <div id={modalID} />
    </div>
  )
}

export default Modal
