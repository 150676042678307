import React, {
  useEffect,
  useRef,
  useState,
  useContext,
  useCallback,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'

import { actions, Store } from '../../../store'

import Icon from '../Icon'

import { efo, getCorrectURL, sanitizeInput, iOS } from '../../../helpers'
import { SEARCH_TEMPLATE_ID, SEARCH_MAX_CHARACTERS } from '../../../constants'

import './styles.scss'

const SearchInput = (props) => {
  const {
    onSearch,
    setFocus,
    inHeader,
    setHeaderSearchStatus,
    searchStatus,
    setSearchInputActive,
    searchInputActive,
    isMobile,
    queryValue,
    searchResultPage,
    itemId,
    templateId,
    isSearchPage,
  } = props

  const { state, dispatch } = useContext(Store)
  const { homepage } = state.search
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const ref = useRef()
  const [inputValue, setInputValue] = useState(queryValue)

  const setHomepage = useCallback(
    (value) => actions.setHomepage(dispatch, { value }),
    [dispatch]
  )

  const setQuery = useCallback(
    (value) => actions.setQuery(dispatch, { value }),
    [dispatch]
  )

  const navigateToSearch = useCallback(
    (query) => {
      const sanitizedQuery = sanitizeInput(query)
      if (sanitizedQuery.trim().length !== 0) {
        if (templateId !== SEARCH_TEMPLATE_ID) {
          setHomepage({ pathname: location.pathname, id: itemId })
          setQuery(sanitizedQuery)
        }
        ref.current.blur()
        history.push({
          pathname: getCorrectURL(
            efo(searchResultPage, 'value.href') ||
              efo(searchResultPage, 'value.url')
          ),
          search: `?q=${sanitizedQuery}`,
          state: {
            layoutID: efo(searchResultPage, 'value.id'),
          },
        })
        setHeaderSearchStatus(false)
        if (inHeader && !isMobile && searchInputActive) {
          setSearchInputActive(false)
          setInputValue('')
        }
      }
    },
    [
      location,
      history,
      inHeader,
      isMobile,
      itemId,
      setSearchInputActive,
      searchResultPage,
      setHeaderSearchStatus,
      setHomepage,
      setQuery,
      searchInputActive,
      templateId,
    ]
  )

  const handleCloseInputMobile = () => {
    setSearchInputActive(false)
    setInputValue('')
    setQuery(null)

    if (searchStatus) setHeaderSearchStatus(false)

    if (isSearchPage) {
      if (homepage.pathname) {
        history.push({
          pathname: getCorrectURL(homepage.pathname),
          state: {
            layoutID: homepage.id,
          },
        })
      } else {
        history.push('/')
      }
    }
  }

  useEffect(() => {
    if (setFocus && ref.current) {
      setTimeout(() => ref.current.focus(), 500)
    }
  }, [setFocus])

  useEffect(() => {
    if (queryValue) setInputValue(queryValue)
  }, [queryValue])

  useEffect(() => {
    const handleFocusOut = () => {
      if (ref.current.value !== '') {
        navigateToSearch(ref.current.value)
      }
    }
    const inputRef = ref.current
    if (typeof window !== 'undefined') {
      if (inputRef && inHeader && iOS()) {
        inputRef.addEventListener('focusout', handleFocusOut)
      }
    }
    return () => {
      if (typeof window !== 'undefined') {
        if (inputRef && inHeader && iOS()) {
          inputRef.removeEventListener('focusout', handleFocusOut)
        }
      }
    }
  }, [navigateToSearch, inHeader])

  return (
    <div className="search-input-container">
      <form
        className="search-input-form"
        onSubmit={(e) => {
          e.preventDefault()
          inHeader
            ? navigateToSearch(ref.current.value)
            : onSearch(ref.current.value)
        }}
      >
        <input
          type="text"
          className="search-input-text"
          maxLength={SEARCH_MAX_CHARACTERS}
          placeholder={t('search-input-placeholder')}
          ref={ref}
          value={inputValue}
          onChange={() => setInputValue(ref.current ? ref.current.value : '')}
        />
      </form>
      <button
        className={`search-input-button search-input-button-search ${
          inHeader
            ? 'search-input-button-search-inheader'
            : 'search-input-button-search-inpage'
        }`}
      >
        <Icon
          name={'search'}
          size={(18, 26)}
          color={'#000000'}
          onClick={() =>
            inHeader
              ? navigateToSearch(ref.current.value)
              : onSearch(ref.current.value)
          }
        />
      </button>
      <button
        className={`search-input-button search-input-button-close ${
          inHeader
            ? 'search-input-button-close-inheader'
            : 'search-input-button-close-inpage'
        }`}
      >
        <Icon
          name={'close'}
          size={(18, 26)}
          color={'#000000'}
          onClick={handleCloseInputMobile}
        />
      </button>
    </div>
  )
}

export default SearchInput
