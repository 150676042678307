import React, { useContext, useState, useRef, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import Icon from '../Icon'

import { actions, Store } from '../../../store'
import { getKey, getCorrectURL, isMobile, efo } from '../../../helpers'

const getOrderedTags = (tags) => {
  tags.sort((a, b) => {
    const aCategory = a.isCategory
    const bCategory = b.isCategory

    if (aCategory && !bCategory) {
      return 1
    }
    if (!aCategory && bCategory) {
      return -1
    }
    return 0
  })
  // sort tags by isCategory === true
  return tags.sort((a, b) =>
    a.isCategory === b.isCategory ? 0 : a.isCategory ? -1 : 1
  )
}

const getTags = (tags, selected) => {
  const tagSelected = selected
    ? tags.find((t) => t.name === selected.name)
    : undefined

  if (tagSelected) {
    return [
      tagSelected,
      ...getOrderedTags(tags.filter((t) => t.name !== selected.name)),
    ]
  }

  return getOrderedTags(
    tags.filter((t) => {
      if (
        t.isCategory &&
        t.categoryPageUrl &&
        t.categoryPageUrl.endsWith('alltopics')
      ) {
        return false
      }
      return true
    })
  )
}

const BodyDefault = (props) => {
  const {
    tags,
    subtitle,
    title,
    loading,
    hideTags,
    isHovering,
    masterCategory,
  } = props

  const { state, dispatch } = useContext(Store)
  const { tagSelected } = state.tag

  const [showTags, setShowTags] = useState(false)
  const [showTagToggle, setShowTagToggle] = useState(false)
  const [containerOferflow, setContainerOverflow] = useState(false)
  const [previousShowTagToggle, setPreviousShowTagToggle] = useState(false)
  const [toggleActive, setToggleActive] = useState(false)
  const [hoverTag, setHoverTag] = useState()

  const tagContainerRef = useRef()

  const to = useRef({ timeOut: null })

  useEffect(() => {
    if (isHovering === false && !isMobile()) {
      if (showTags === true) {
        setShowTags(false)
        setShowTagToggle(true)
      }
    }
  }, [isHovering, showTags])

  useEffect(() => {
    const timeOutClear = to.current
    if (hoverTag === false) {
      clearTimeout(timeOutClear.timeOut)
      timeOutClear.timeOut = setTimeout(() => {
        if (!hoverTag) {
          setShowTags(false)
          if (previousShowTagToggle) {
            setShowTagToggle(true)
            setPreviousShowTagToggle(false)
            setShowTags(false)
          }
        }
      }, 700)
    }
    return () => {
      clearTimeout(timeOutClear.timeOut)
    }
  }, [hoverTag, previousShowTagToggle])

  const handleTagsLeave = () => {
    if (previousShowTagToggle || toggleActive) {
      setHoverTag(false)
    }
  }

  const handleTagsEnter = () => {
    if (checkTagOverflow() || previousShowTagToggle || toggleActive) {
      setHoverTag(true)
      setPreviousShowTagToggle(true)
      setShowTagToggle(false)
      setShowTags(true)
    }
  }

  const checkTagOverflow = () => {
    const scrollHeight = tagContainerRef.current.scrollHeight
    const clientHeight = tagContainerRef.current.clientHeight

    if (scrollHeight > clientHeight) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    if (!hideTags && tags && tags.length) {
      if (checkTagOverflow()) {
        setShowTagToggle(true)
        setContainerOverflow(true)
      } else {
        setShowTagToggle(false)
        setContainerOverflow(false)
      }
    }
  }, [hideTags, tags])

  return (
    <div className="teaser-data-container">
      {(!hideTags && tags && tags.length) || (!hideTags && masterCategory) ? (
        <div
          className={`teaser-tags-container ${
            containerOferflow && 'teaser-tags-container-overflow'
          }`}
          ref={tagContainerRef}
          data-show={showTags ? '' : undefined}
        >
          {masterCategory && (
            <NavLink
              className="teaser-tag-container"
              to={getCorrectURL(efo(masterCategory, 'categoryPageUrl'))}
              state={{
                layoutID: efo(masterCategory, 'categoryPageId'),
              }}
              onClick={(event) => {
                event.stopPropagation()
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth',
                })
              }}
              onMouseLeave={handleTagsLeave}
              onMouseEnter={handleTagsEnter}
            >
              {efo(masterCategory, 'name')}
            </NavLink>
          )}
          {tags &&
            tags.length &&
            getTags(tags, tagSelected).map((tag, i) => (
              <NavLink
                key={getKey(tag.id, i)}
                className="teaser-tag-container"
                data-active={
                  tagSelected && tag.name === tagSelected.name ? '' : undefined
                }
                to={getCorrectURL(
                  tag.isCategory
                    ? tag.categoryPageUrl
                    : `/?filter=${tag.name}` || '/'
                )}
                state={{
                  layoutID: tag.categoryPageId,
                }}
                onClick={(event) => {
                  event.stopPropagation()
                  if (!tag.isCategory) {
                    actions.setTagSelected(dispatch, { tag })
                  }
                  window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                  })
                }}
                onMouseLeave={handleTagsLeave}
                onMouseEnter={handleTagsEnter}
              >
                {tag.isCategory ? <b> {tag.name} </b> : <> {tag.name} </>}
              </NavLink>
            ))}
          {showTagToggle && (
            <div
              className={`teaser-tags-mobile-toggle ${
                showTags ? 'teaser-tags-mobile-toggle-invisible' : ''
              }`}
              onClick={() => {
                setShowTags(!showTags)
                setToggleActive(true)
              }}
            >
              {showTags ? (
                <Icon name="close" color="#e3e4e4" size={18} />
              ) : (
                <span>...</span>
              )}
            </div>
          )}
        </div>
      ) : loading ? (
        <div className="teaser-tags-container">
          <div className="teaser-lazy-tag teaser-lazy-tag-1" />
          <div className="teaser-lazy-tag teaser-lazy-tag-1" />
          <div className="teaser-lazy-tag teaser-lazy-tag-1" />
          <div className="teaser-lazy-tag teaser-lazy-tag-2" />
          <div className="teaser-lazy-tag teaser-lazy-tag-2" />
        </div>
      ) : undefined}
      {title ? (
        <h1 className="teaser-title">{title}</h1>
      ) : (
        <div className="teaser-lazy-title" />
      )}
      {subtitle ? (
        <span className="teaser-subtitle">{subtitle}</span>
      ) : loading ? (
        <div className="teaser-lazy-subtitle" />
      ) : undefined}
    </div>
  )
}

export default BodyDefault
