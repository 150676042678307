import React from 'react'

import Autographs from '../Autographs'

const getItems = (autographs) =>
  autographs.map((a) => ({
    fields: {
      ...a,
    },
  }))

const BottomAutographs = ({ title, autographFileLabel, autographs }) => (
  <Autographs
    headline={title}
    fileLabel={autographFileLabel}
    items={getItems(autographs)}
  />
)

export default BottomAutographs
