import React, { useEffect } from 'react'

import withMinimumRequirements from '../../../withMinimumRequirements'
import Story from '../../shared/Story'

import { efo, log, getDataSourceName } from '../../../helpers'

import './styles.scss'

const getProps = (fields) => ({
  image: efo(fields, 'image.value'),
  aspectRatio: efo(fields, 'aspectRatio.value'),
  muted: !!efo(fields, 'muted.value'),
  items: (efo(fields, 'items') || []).map((item) => ({
    title: efo(item, 'fields.title.value'),
    video: efo(item, 'fields.videofile.value.src'),
    link: efo(item, 'fields.link.value.url'),
  })),
})

const ArticleBodyStory = (props) => {
  const { fields } = props

  useEffect(() => {
    log('Article Body Story', { ...fields })
  }, [fields])

  return (
    <div
      className="article-body-story-container"
      id={getDataSourceName(efo(props, 'rendering.dataSource'))}
    >
      <div className="article-body-story-subcontainer">
        <Story {...getProps(fields)} />
      </div>
    </div>
  )
}

export default withMinimumRequirements(ArticleBodyStory, [
  'fields.image.value',
  'fields.aspectRatio.value',
  'fields.items.0',
  'fields.items.0.fields.title.value',
  'fields.items.0.fields.videofile.value.src',
  'fields.items.1',
  'fields.items.1.fields.title.value',
  'fields.items.1.fields.videofile.value.src',
])
