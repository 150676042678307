import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

import Image from '../Image'

import { DEFAULT_TEASER_TYPE, TEASER_TYPE_COUNTDOWN } from '../../../constants'
import {
  log,
  getSitecoreImage,
  efo,
  getMediaList,
  getCorrectURL,
} from '../../../helpers'

import './styles.scss'

import { withMinimumRequirementsByType } from '../../../withMinimumRequirements'
import MediaImage from './MediaImage'
import MediaCountdown from './MediaCountdown'

// -----------------------
// Media Components
const MediaComponents = {
  [DEFAULT_TEASER_TYPE]: MediaImage,
  [TEASER_TYPE_COUNTDOWN]: MediaCountdown,
}
// -----------------------

const AbstractMedia = (props) => {
  const { teaserComponentName } = props

  const Media =
    MediaComponents[teaserComponentName] || MediaComponents[DEFAULT_TEASER_TYPE]

  if (Media) {
    return <Media {...props} />
  }

  return null
}

const AbstractStaticTeaser = (props) => {
  const {
    loading,
    delay,
    image,
    targetURL,
    teaserComponentName,
    stopAnimation,
    index,
  } = props

  const [mount, setMount] = useState(false)

  // We need to re-render because URL on PROD needs "window.location.pathname" access.
  useEffect(() => {
    if (!mount) {
      setMount(true)
    }
  }, [mount])

  useEffect(() => {
    if (!loading) {
      log(`Static Teaser > #${index} ${teaserComponentName}`, {
        ...props,
      })
    }
  }, [loading, index, teaserComponentName, props])

  if (targetURL) {
    const finalURL = efo(targetURL, 'href') || ''
    const finalID = efo(targetURL, 'id')
    const isInternal = finalURL.startsWith('/')

    const NavigationComponent = isInternal
      ? NavLink
      : React.createElement('a').type

    return (
      <NavigationComponent
        className={`static-teaser-container ${teaserComponentName.toLowerCase()}`}
        data-loading={loading ? '' : undefined}
        data-stop-animation={stopAnimation ? '' : undefined}
        data-default-style={
          teaserComponentName === DEFAULT_TEASER_TYPE ? '' : undefined
        }
        style={{
          animationDelay: `${delay}ms`,
        }}
        href={isInternal ? undefined : finalURL}
        target={isInternal ? undefined : '_blank'}
        to={
          isInternal
            ? {
                pathname: getCorrectURL(finalURL),
                state: {
                  layoutID: finalID,
                },
              }
            : undefined
        }
      >
        {loading ? (
          <>
            <div className="skeleton-lazy-loader" />
            {image && (
              <Image
                className="static-teaser-container-image"
                fields={{
                  image: getSitecoreImage(
                    efo(image, 'src'),
                    efo(image, 'width'),
                    efo(image, 'height'),
                    { ...(image || {}) }
                  ),
                }}
                mediaList={getMediaList(182, 282, 282, 282, 282)}
                cover
              />
            )}
            <div className="static-teaser-fade" />
            <div className="static-teaser-subcontainer">
              <div className="static-teaser-lazy static-teaser-lazy-subtitle" />
              <div className="static-teaser-lazy static-teaser-lazy-title" />
            </div>
          </>
        ) : (
          <AbstractMedia {...props} />
        )}
        <div className="static-teaser-active-indicator" />
      </NavigationComponent>
    )
  }

  return (
    <div
      className={`static-teaser-container ${teaserComponentName.toLowerCase()}`}
      data-loading={loading ? '' : undefined}
      data-stop-animation={stopAnimation ? '' : undefined}
      data-default-style={
        teaserComponentName === DEFAULT_TEASER_TYPE ? '' : undefined
      }
      style={{
        animationDelay: `${delay}ms`,
      }}
    >
      {loading ? (
        <>
          <div className="skeleton-lazy-loader" />
          {image && (
            <Image
              className="static-teaser-container-image"
              fields={{
                image: getSitecoreImage(
                  efo(image, 'src'),
                  efo(image, 'width'),
                  efo(image, 'height'),
                  { ...(image || {}) }
                ),
              }}
              mediaList={getMediaList(182, 282, 282, 282, 282)}
              cover
            />
          )}
          <div className="static-teaser-fade" />
          <div className="static-teaser-subcontainer">
            <div className="static-teaser-lazy static-teaser-lazy-subtitle" />
            <div className="static-teaser-lazy static-teaser-lazy-title" />
          </div>
        </>
      ) : (
        <AbstractMedia {...props} />
      )}
      <div className="static-teaser-active-indicator" />
    </div>
  )
}

export default withMinimumRequirementsByType(
  AbstractStaticTeaser,
  'teaserComponentName',
  {
    LoadingTeaser: [],
    Teaser: ['id', 'title', 'image'],
    CountdownTeaser: ['id', 'title', 'image', 'startDate'],
  }
)
