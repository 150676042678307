import React, { useEffect, useRef, useCallback } from 'react'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import { useInView } from 'react-intersection-observer'

import withMinimumRequirements from '../../../withMinimumRequirements'
import Partner from '../../shared/Partner'

import {
  efo,
  log,
  getModulePosition,
  pushGlEvent,
  getDataSourceName,
} from '../../../helpers'

import './styles.scss'

const ArticleBodyPartner = (props) => {
  const { fields } = props

  const { headline, partners } = fields

  const ref = useRef()
  const [inViewRefTrack, inViewTrack] = useInView({
    threshold: 0.3,
  })

  const setRefs = useCallback(
    (node) => {
      ref.current = node
      inViewRefTrack(node)
    },
    [inViewRefTrack]
  )

  useEffect(() => {
    log('Article Body Partner', { ...fields })
  }, [fields])

  // Tracking load
  useEffect(() => {
    if (typeof window !== 'undefined' && window.GDL) {
      pushGlEvent(
        false,
        'Partner',
        [],
        'Partner',
        getModulePosition('article-body-partner-container', ref),
        'Partner',
        efo(props, 'rendering.uid'),
        'Partner'
      )
    }
  }, [props])

  // Tracking in view
  useEffect(() => {
    if (inViewTrack && typeof window !== 'undefined' && window.GDL) {
      pushGlEvent(
        true,
        'Partner',
        [],
        'Partner',
        getModulePosition('article-body-partner-container', ref),
        'Partner',
        efo(props, 'rendering.uid'),
        'Partner'
      )
    }
  }, [inViewTrack, props])

  return (
    <div
      className="article-body-partner-container"
      id={getDataSourceName(efo(props, 'rendering.dataSource'))}
      ref={setRefs}
    >
      <Text tag="h2" field={headline} />
      <Partner
        data={
          partners
            ? partners.map((p = {}) => ({
                id: p.id,
                link:
                  efo(p, 'fields.link.value.href') ||
                  efo(p, 'fields.link.value.url'),
                logo: efo(p, 'fields.logo'),
              }))
            : undefined
        }
        theme="light"
      />
    </div>
  )
}

export default withMinimumRequirements(ArticleBodyPartner, [
  'fields.partners',
  'fields.partners.0',
  'fields.partners.0.fields.logo.value',
  {
    mode: 'one-of',
    structure: [
      'fields.partners.0.fields.link.value.url',
      'fields.partners.0.fields.link.value.href',
    ],
  },
])
