const types = {
  SET: {
    TAG: {
      SELECTED: 'SET.TAG.SELECTED',
    },
  },
}

const initialState = {
  tagSelected: undefined,
}

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.SET.TAG.SELECTED:
      return {
        ...state,
        tagSelected: action.tag,
      }
    default:
      return state
  }
}

const setTagSelected = (dispatch, values) =>
  dispatch({
    type: types.SET.TAG.SELECTED,
    ...values,
  })

export default {
  initialState,
  reducer,
  actions: {
    setTagSelected,
  },
}
