import React from 'react'
import { NavLink } from 'react-router-dom'

import Image from '../Image'

import { getCorrectURL, getMediaList, efo, getConfig } from '../../../helpers'

import { SEO_APP_NAME, SEARCH_FALLBACK_IMAGE } from '../../../constants'

import './styles.scss'

const getDate = (locale, date) => {
  const resultDate = new Date(date.replace(/ /g, 'T'))

  const options = {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  }

  return resultDate.toLocaleDateString(locale, options)
}

const getImage = (image) => {
  if (image) {
    return { value: image }
  } else {
    return {
      value: {
        src: `${getConfig('website.path')}${
          getConfig('website.distPath') || ''
        }${SEARCH_FALLBACK_IMAGE}`,
        width: '400',
        height: '400',
        alt: SEO_APP_NAME,
      },
    }
  }
}

const SearchResult = (props) => {
  const { title, date, content, image, url, delay, loading, language } = props
  const finalURL = efo(url, 'href')
  const finalID = efo(url, 'id')

  return (
    <div
      className="search-result-container"
      style={{
        animationDelay: `${delay}ms`,
      }}
    >
      {loading ? (
        <div className="search-result-lazy-container">
          <div className="search-result-image-title">
            <div className="search-result-image search-result-lazy-image"></div>
            <div className="search-result-text-container">
              <div className="search-result-lazy-title"></div>
              <div className="search-result-lazy-date"></div>
            </div>
          </div>
          <div className="search-result-lazy-content"></div>
          <div className="skeleton-lazy-loader"></div>
        </div>
      ) : (
        <NavLink
          className="search-result-link"
          to={{
            pathname: getCorrectURL(finalURL),
            state: {
              layoutID: finalID,
            },
          }}
        >
          <div className="search-result-image-title">
            <div className="search-result-image">
              <Image
                fields={{ image: getImage(image) }}
                mediaList={getMediaList(400)}
                cover
              />
            </div>
            <div className="search-result-text-container">
              <h2
                className="search-result-title"
                dangerouslySetInnerHTML={{ __html: title }}
              ></h2>
              <time className="search-result-date">
                {getDate(language, date)}
              </time>
            </div>
          </div>
          <div
            className="search-result-content"
            dangerouslySetInnerHTML={{ __html: content }}
          ></div>
        </NavLink>
      )}
    </div>
  )
}

export default SearchResult
