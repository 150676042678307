import React from 'react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'

import MinimumRequirements from './components/shared/MinimumRequirements'

import { efo, errorLog } from './helpers'

const checkStructure = (structure = [], props = {}) => {
  for (let field of structure) {
    let result = false

    if (typeof field === 'string') {
      result = !!efo(props, field)
    } else if (
      typeof field === 'object' &&
      !Array.isArray(field) &&
      field.mode &&
      field.structure
    ) {
      switch (field.mode) {
        case 'one-of':
          for (let subfield of field.structure) {
            if (checkStructure([subfield], props)) {
              result = true
            }
          }
          break
        default:
          break
      }
    }

    if (!result) {
      return false
    }
  }

  return true
}

export const withMinimumRequirementsByType = (
  Component,
  typePropField,
  structure = {}
) =>
  withSitecoreContext()((props) => {
    const { sitecoreContext } = props

    if (!sitecoreContext.pageEditing) {
      if (!props || !efo(props, typePropField)) {
        const s = [typePropField]

        errorLog(Component.name, {
          structure: s,
          props,
        })

        return (
          <MinimumRequirements
            componentName={Component.name}
            componentProps={props}
            structure={s}
          />
        )
      }

      if (structure) {
        const s = structure[efo(props, typePropField)]

        if (!checkStructure(s, props)) {
          errorLog(Component.name, {
            structure: s,
            props,
          })

          return (
            <MinimumRequirements
              componentName={Component.name}
              componentProps={props}
              structure={s}
            />
          )
        }
      }
    }

    return <Component {...props} />
  })

const withMinimumRequirements = (Component, structure = []) =>
  withSitecoreContext()((props) => {
    const { sitecoreContext } = props

    if (structure && Array.isArray(structure) && !sitecoreContext.pageEditing) {
      if (!checkStructure(structure, props)) {
        errorLog(Component.name, {
          structure,
          props,
        })

        return (
          <MinimumRequirements
            componentName={Component.name}
            componentProps={props}
            structure={structure}
          />
        )
      }
    }

    return <Component {...props} />
  })

export default withMinimumRequirements
