import React, { useEffect, useState, useRef } from 'react'
import { withTranslation } from 'react-i18next'

import withMinimumRequirements from '../../../withMinimumRequirements'
import Slider, { Slide } from '../Slider/SliderMin'
import Image from '../Image'
import Video from '../Video'
import Icon from '../Icon'

import { efo, getKey, getMediaList, shuffle } from '../../../helpers'

import './styles.scss'

const getTwoDigitNumber = (num) => (num < 10 ? `0${num}` : num)

const generateInitialData = (quizId, questions, image, force) => {
  const data = {
    index: 0,
    correct: 0,
    questions: [],
  }

  if (!force && typeof localStorage !== 'undefined') {
    const alreadyData = localStorage.getItem(quizId)

    if (alreadyData && alreadyData.length) {
      return JSON.parse(alreadyData)
    }
  }

  for (let question of questions) {
    data.questions.push({
      ...question,
      image: question.image || image,
      answers: shuffle(question.answers).map((a, i) => ({
        ...a,
        index: i,
      })),
      result: undefined,
      showResult: false,
    })
  }

  return data
}

const Question = withTranslation()((props) => {
  const { t, data, isFocused, isTeaser, setResult, setNext } = props

  const {
    image,
    url,
    fileURL,
    aspectRatio,
    question,
    explanationHeadline,
    explanationDescription,
    answers,
    result,
    showResult,
  } = data

  const video = efo(url, 'href') || fileURL

  return (
    <div
      className="general-quiz-subcontainer"
      data-focused={isFocused ? '' : undefined}
    >
      <div className="general-quiz-answers-media-container">
        {video ? (
          <Video
            UC_READY={props.UC_READY}
            UC_PROCESSOR={props.UC_PROCESSOR}
            url={video}
            image={image}
            options={{
              aspectRatio,
              autoPlay: true,
            }}
            isTeaser
          />
        ) : (
          <Image
            className="general-quiz-answers-image"
            src={image.src}
            thumb={image.base64String}
            alt={image.alt}
            width={image.width}
            height={image.height}
            mediaList={getMediaList(400)}
          />
        )}
      </div>
      <div
        className="general-quiz-answers-container"
        data-is-teaser={isTeaser ? '' : undefined}
      >
        <span className="general-quiz-answers-title">{t('quiz-title')}</span>
        <span className="general-quiz-answers-question">{question}</span>
        <div className="general-quiz-answers-subcontainer">
          {answers.map((answer) => (
            <div
              key={answer.index}
              className="general-quiz-answer-container"
              data-answered={showResult ? '' : undefined}
              data-answered-correct={
                showResult && answer.correct ? '' : undefined
              }
              data-answered-incorrect={
                showResult &&
                !answer.correct &&
                JSON.stringify(answer) === JSON.stringify(result)
                  ? ''
                  : undefined
              }
              onClick={() => {
                if (!result) {
                  setResult(answer)
                }
              }}
            >
              <span>{answer.text}</span>
            </div>
          ))}
        </div>
        <div
          className="general-quiz-result-container"
          data-show={showResult ? '' : undefined}
        >
          {explanationHeadline || explanationDescription ? <div /> : undefined}
          {explanationHeadline && (
            <span className="general-quiz-result-headline">
              {explanationHeadline}
            </span>
          )}
          {explanationDescription && (
            <span className="general-quiz-result-description">
              {explanationDescription}
            </span>
          )}
          <div className="general-quiz-next" onClick={setNext}>
            <span>{t('quiz-next-question')}</span>
            <div className="general-quiz-next-icon-container">
              <Icon name="arrowHeadRight" color="white" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})

const Quiz = (props) => {
  const {
    t,
    id,
    image,
    questions: rawQuestions,
    randomGenerated,
    isTeaser,
    scrollIntoTeaser,
  } = props

  const quizId = `quiz-${id}`

  const oldIndex = useRef(0)

  const [swiper, setSwiper] = useState(undefined)
  const [localStorageChecked, setLocalStorageChecked] = useState(false)
  const [quizData, setQuizData] = useState(
    generateInitialData(quizId, rawQuestions, image, randomGenerated)
  )

  const { index, correct, questions } = quizData

  useEffect(() => {
    if (!localStorageChecked) {
      if (!randomGenerated) {
        const oldData = JSON.parse(localStorage.getItem(quizId))
        if (oldData && JSON.stringify(oldData) !== JSON.stringify(quizData)) {
          setQuizData(oldData)
        }
      }
      setLocalStorageChecked(true)
    }
  }, [localStorageChecked, quizData, quizId, randomGenerated])

  useEffect(() => {
    if (typeof localStorage !== 'undefined' && !randomGenerated) {
      const oldData = localStorage.getItem(quizId)
      const newData = JSON.stringify(quizData)
      if (oldData !== newData) {
        try {
          localStorage.setItem(quizId, newData)
        } catch (error) {
          // Nothing to do here
        }
      }
    }
  }, [quizData, quizId, randomGenerated])

  useEffect(() => {
    if (oldIndex.current !== index && swiper) {
      swiper.slideTo(index)
      oldIndex.current = index
    }
  }, [swiper, index, oldIndex])

  return (
    <div
      className="general-quiz-container"
      data-is-teaser={isTeaser ? '' : undefined}
    >
      <Slider
        onSwiper={setSwiper}
        slidesPerView={1}
        allowTouchMove={false}
        observer
        observeSlideChildren
        observeParents
      >
        {questions.map((data, i) => (
          <Slide key={getKey(data.id, i)}>
            <Question
              {...props}
              data={data}
              isFocused={i === index}
              setResult={(resultAnswer) =>
                setQuizData((stateData) => {
                  const stateQuestions = [...stateData.questions]

                  stateQuestions[i] = {
                    ...stateQuestions[i],
                    result: resultAnswer,
                    showResult: true,
                  }

                  return {
                    ...stateData,
                    correct: stateData.correct + (resultAnswer.correct ? 1 : 0),
                    questions: stateQuestions,
                  }
                })
              }
              setNext={() => {
                setQuizData((stateData) => ({
                  ...stateData,
                  index: stateData.index + 1,
                }))
                if (scrollIntoTeaser) {
                  setTimeout(scrollIntoTeaser, 100)
                }
              }}
            />
          </Slide>
        ))}
        <Slide key={getKey('final', questions.length)}>
          <div
            className="general-quiz-final-result-container"
            data-show={index >= questions.length ? '' : undefined}
          >
            <div className="general-quiz-final-result-image-container">
              <Image
                className="general-quiz-final-result-image"
                src={image.src}
                thumb={image.base64String}
                alt={image.alt}
                ratio={image.height / image.width}
                mediaList={getMediaList(400)}
              />
            </div>
            <div className="general-quiz-final-result-subcontainer">
              <span className="general-quiz-final-result-title">
                {t('quiz-title')}
              </span>
              <span className="general-quiz-final-result-text">
                {t('quiz-result-text', {
                  correct,
                  total: questions.length,
                })}
              </span>
              <div
                className="general-quiz-next"
                onClick={() => {
                  setQuizData(
                    generateInitialData(quizId, questions, image, true)
                  )
                }}
              >
                <span>{t('quiz-retry')}</span>
                <div className="general-quiz-next-icon-container">
                  <Icon name="arrowHeadRight" color="white" />
                </div>
              </div>
            </div>
          </div>
        </Slide>
      </Slider>
      <div
        className="general-quiz-indicator-container"
        data-hide={!(index < questions.length) ? '' : undefined}
      >
        <div className="general-quiz-indicator-horizontal-divider" />
        <span className="general-quiz-indicator-current">
          {index >= questions.length
            ? getTwoDigitNumber(questions.length)
            : getTwoDigitNumber(index + 1)}
        </span>
        <div className="general-quiz-indicator-vertical-divider" />
        <span className="general-quiz-indicator-total">
          {getTwoDigitNumber(questions.length)}
        </span>
        <div className="general-quiz-indicator-horizontal-divider" />
      </div>
    </div>
  )
}

export default withTranslation()(
  withMinimumRequirements(Quiz, ['id', 'image', 'questions'])
)
