import React from 'react'

import Image from '../Image'

import { efo, getSitecoreImage, getMediaList } from '../../../helpers'

const MediaImage = (props) => {
  const { image, subtitle, title } = props

  return (
    <>
      <Image
        className="static-teaser-container-image"
        fields={{
          image: getSitecoreImage(
            efo(image, 'src'),
            efo(image, 'width'),
            efo(image, 'height'),
            { ...(image || {}) }
          ),
        }}
        mediaList={getMediaList(182, 282, 282, 282, 282)}
        cover
      />
      <div className="static-teaser-fade" />
      <div className="static-teaser-subcontainer">
        <span className="static-teaser-subtitle">{subtitle}</span>
        <span className="static-teaser-title">{title}</span>
      </div>
    </>
  )
}

export default MediaImage
