import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

import {
  DEFAULT_TEASER_TYPE,
  TEASER_TYPE_VIDEO,
  TEASER_TYPE_COUNTDOWN,
} from '../../../constants'
import { log, efo, getCorrectURL, getTeaserAnchor } from '../../../helpers'

import './styles.scss'

import { withMinimumRequirementsByType } from '../../../withMinimumRequirements'
import MediaImage from './MediaImage'
import MediaVideo from './MediaVideo'
import MediaCountdown from './MediaCountdown'

// -----------------------
// Media Components
const MediaComponents = {
  [DEFAULT_TEASER_TYPE]: MediaImage,
  [TEASER_TYPE_VIDEO]: MediaVideo,
  [TEASER_TYPE_COUNTDOWN]: MediaCountdown,
}
// -----------------------

const AbstractMedia = (props) => {
  const { teaserComponentName } = props

  //log('AbstractMedia', props)

  const Media =
    MediaComponents[teaserComponentName] || MediaComponents[DEFAULT_TEASER_TYPE]

  if (Media) {
    return <Media {...props} />
  }

  return null
}

const AbstractCategoryTeaser = (props) => {
  const { fields, index, teaserComponentName } = props

  //log('AbstractCategoryTeaser', props)

  const { targeturl } = fields

  const [mount, setMount] = useState(false)
  const [hovering, setHovering] = useState(false)

  // We need to re-render because URL on PROD needs "window.location.pathname" access.
  useEffect(() => {
    if (!mount) {
      setMount(true)
    }
  }, [mount])

  useEffect(() => {
    log(`Category Teaser > #${index} ${teaserComponentName}`, {
      ...fields,
    })
  }, [index, teaserComponentName, props, fields])

  if (
    efo(targeturl, 'value') &&
    efo(targeturl, 'value.href') &&
    teaserComponentName === DEFAULT_TEASER_TYPE
  ) {
    const finalURL = efo(targeturl, 'value.href') || ''
    const finalID = efo(targeturl, 'value.id')
    const finalAnchor = efo(targeturl, 'value.anchor')
    const isInternal = finalURL.startsWith('/')

    const NavigationComponent = isInternal
      ? NavLink
      : React.createElement('a').type

    return (
      <NavigationComponent
        className={`category-teaser-container ${teaserComponentName.toLowerCase()}`}
        data-default-style={
          teaserComponentName === DEFAULT_TEASER_TYPE ? '' : undefined
        }
        href={isInternal ? undefined : finalURL}
        target={isInternal ? undefined : '_blank'}
        to={
          isInternal
            ? {
                pathname: getCorrectURL(finalURL),
                hash: getTeaserAnchor(finalAnchor)
                  ? `#${finalAnchor}`
                  : undefined,
                state: {
                  layoutID: finalID,
                },
              }
            : undefined
        }
      >
        <AbstractMedia {...fields} teaserComponentName={teaserComponentName} />
        <div className="category-teaser-active-indicator" />
      </NavigationComponent>
    )
  }

  return (
    <div
      className={`category-teaser-container ${teaserComponentName.toLowerCase()}`}
      data-default-style={
        teaserComponentName === DEFAULT_TEASER_TYPE ? '' : undefined
      }
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <AbstractMedia
        UC_READY={props.UC_READY}
        UC_PROCESSOR={props.UC_PROCESSOR}
        {...fields}
        isHovering={hovering}
        teaserComponentName={teaserComponentName}
      />
      <div className="category-teaser-active-indicator" />
    </div>
  )
}

export default withMinimumRequirementsByType(
  AbstractCategoryTeaser,
  'teaserComponentName',
  {
    LoadingTeaser: [],
    Teaser: ['id', 'fields.title', 'fields.image'],
    VideoTeaser: [
      'id',
      'fields.title',
      'fields.image',
      {
        mode: 'one-of',
        structure: ['fields.videourl', 'fields.videofile'],
      },
    ],
  }
)
