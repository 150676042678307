import React, { useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { RichText } from '@sitecore-jss/sitecore-jss-react'
import { useInView } from 'react-intersection-observer'

import Partner from '../../shared/Partner'

import { getKey, efo, log, pushGlEvent } from '../../../helpers'

import './styles.scss'

const Footer = ({ fields, rendering, categoryId, partnersForFooter }) => {
  useEffect(() => {
    log('Footer', { ...fields })
  }, [fields])

  const id = efo(rendering, 'uid')

  const [footerRef, inView] = useInView({
    threshold: 0.3,
  })
  const filteredData = partnersForFooter.filter((item) => {
    return (
      item.fields.categories &&
      item.fields.categories.some(
        (category) =>
          category.id &&
          category.id.toUpperCase() === categoryId?.slice(1, -1).toUpperCase()
      )
    )
  })
  const partnersToShow =
    filteredData.length > 0
      ? filteredData
      : fields.partners.length > 0
      ? fields.partners
      : []
  //log('Footer_Partners__1', partnersForFooter)
  //log('Footer_Partners__2', filteredData)
  //log('Footer_Partners__3', fields.partners)
  //log('Footer_Partners__4', partnersToShow)

  // Tracking load
  useEffect(() => {
    if (typeof window !== 'undefined' && window.GDL) {
      pushGlEvent(
        false,
        'FooterNavigation',
        [],
        'FooterNavigation',
        '00',
        'FooterNavigation',
        id,
        'FooterNavigation'
      )
    }
  }, [id])

  // Tracking in view
  useEffect(() => {
    if (inView && typeof window !== 'undefined' && window.GDL) {
      pushGlEvent(
        true,
        'FooterNavigation',
        [],
        'FooterNavigation',
        '00',
        'FooterNavigation',
        id,
        'FooterNavigation'
      )
    }
  }, [inView, id])

  return (
    <footer ref={footerRef}>
      <div className="footer-navigation-container">
        <div className="footer-navigation-subcontainer">
          {fields.footerLinks &&
            fields.footerLinks.map((value, i) => (
              <a
                key={getKey(value.id, i)}
                className="footer-navigation-link"
                href={value.fields.link.value.href}
                target="_blank"
                rel="noopener noreferrer"
              >
                {value.fields.link.value.text}
              </a>
            ))}
        </div>
      </div>
      <div className="footer-partner-container">
        <div className="footer-partner-subcontainer">
          {partnersToShow.length > 0 && (
            <Partner
              data={
                partnersToShow
                  ? partnersToShow.map((p) => ({
                      id: p.id,
                      link: efo(p, 'fields.link.value.href'),
                      logo: efo(p, 'fields.logo'),
                    }))
                  : undefined
              }
              disableLazyLoad
              showSeparator
            />
          )}
        </div>
      </div>
      <div className="footer-imprint-container">
        <div className="footer-imprint-subcontainer">
          <RichText field={fields.footerText} />
        </div>
      </div>
    </footer>
  )
}

export default withTranslation()(Footer)
