import React, { useContext } from 'react'
import { Placeholder } from '@sitecore-jss/sitecore-jss-react'
import AbstractTeaserGrid from '../../shared/AbstractTeaserGrid'

import { Store } from '../../../store'
import { log } from '../../../helpers'

import './styles.scss'

const TeaserGrid = (props) => {
  //log('TeaserGrid', props)
  const { rendering } = props
  const { state } = useContext(Store)
  const { countrySelectorStatus } = state.header
  return (
    <>
      <div className="teaser-grid-tag-selector">
        <Placeholder
          UC_READY={props.UC_READY}
          UC_PROCESSOR={props.UC_PROCESSOR}
          name="jss-motorsport-teaser-grid-tags"
          rendering={rendering}
          params={{ countrySelectorStatus: countrySelectorStatus.toString() }}
        />
      </div>
      <AbstractTeaserGrid
        UC_READY={props.UC_READY}
        UC_PROCESSOR={props.UC_PROCESSOR}
      />
    </>
  )
}

export default TeaserGrid
