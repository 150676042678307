import { useEffect, useRef } from 'react'

const useInterval = (callback, delay) => {
  const savedCallbackRef = useRef()

  useEffect(() => {
    savedCallbackRef.current = callback
  }, [callback])

  useEffect(() => {
    const tick = () => {
      savedCallbackRef.current()
    }

    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

export default useInterval
