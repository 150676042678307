import React, { useEffect, useRef, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import { useInView } from 'react-intersection-observer'

import withMinimumRequirements from '../../../withMinimumRequirements'
import AbstractTeaserGrid from '../../shared/AbstractTeaserGrid'

import {
  efo,
  log,
  getModulePosition,
  pushGlEvent,
  getDataSourceName,
} from '../../../helpers'

import './styles.scss'

const ArticleBodyRecommendation = (props) => {
  const { fields } = props
  let { headline, teasers } = fields

  const [t] = useTranslation()

  const ref = useRef()
  const [inViewRefTrack, inViewTrack] = useInView({
    threshold: 0.3,
  })

  const setRefs = useCallback(
    (node) => {
      ref.current = node
      inViewRefTrack(node)
    },
    [inViewRefTrack]
  )

  useEffect(() => {
    log('Article Body Recommendation', { ...fields })
  }, [fields])

  // Tracking load
  useEffect(() => {
    if (typeof window !== 'undefined' && window.GDL) {
      pushGlEvent(
        false,
        'Recommendation',
        [],
        'Recommendation',
        getModulePosition('article-body-quote-container', ref),
        'Recommendation',
        efo(props, 'rendering.uid'),
        'Recommendation'
      )
    }
  }, [props])

  // Tracking in view
  useEffect(() => {
    if (inViewTrack && typeof window !== 'undefined' && window.GDL) {
      pushGlEvent(
        true,
        'Recommendation',
        [],
        'Recommendation',
        getModulePosition('article-body-quote-container', ref),
        'Recommendation',
        efo(props, 'rendering.uid'),
        'Recommendation'
      )
    }
    teasers = teasers.teaserItems.sort(
      (a, b) => new Date(b.date) - new Date(a.date)
    )
  }, [inViewTrack, props])

  return (
    <div
      className="article-recommended-container"
      id={getDataSourceName(efo(props, 'rendering.dataSource'))}
      ref={setRefs}
    >
      <Text
        tag="h2"
        field={
          efo(headline, 'value')
            ? headline
            : {
                value: t('article-recommended-articles'),
              }
        }
      />
      <AbstractTeaserGrid defaultData={efo(teasers, 'value') || teasers} />
    </div>
  )
}

export default withMinimumRequirements(ArticleBodyRecommendation, [
  'fields.teasers',
  {
    mode: 'one-of',
    structure: [
      'fields.teasers.teaserItems.0',
      'fields.teasers.value.teaserItems.0',
    ],
  },
])
