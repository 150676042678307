import React, { useEffect, useRef, useCallback } from 'react'
import { Link } from '@sitecore-jss/sitecore-jss-react'
import { useInView } from 'react-intersection-observer'

import withMinimumRequirements from '../../../withMinimumRequirements'
import Icon from '../../shared/Icon'

import {
  efo,
  log,
  getModulePosition,
  pushGlEvent,
  getDataSourceName,
} from '../../../helpers'

import './styles.scss'

const ArticleBodyButton = (props) => {
  const { fields } = props
  const { icon, link } = fields
  const ref = useRef()
  const [inViewRefTrack, inViewTrack] = useInView({
    threshold: 0.3,
  })

  const setRefs = useCallback(
    (node) => {
      ref.current = node
      inViewRefTrack(node)
    },
    [inViewRefTrack]
  )

  // Tracking load
  useEffect(() => {
    if (typeof window !== 'undefined' && window.GDL) {
      pushGlEvent(
        false,
        'Button',
        [],
        'Button',
        getModulePosition('article-body-button-container', ref),
        'Button',
        efo(props, 'rendering.uid'),
        efo(link, 'value.text')
      )
    }
  }, [fields, link, props])

  // Tracking in view
  useEffect(() => {
    if (inViewTrack && typeof window !== 'undefined' && window.GDL) {
      pushGlEvent(
        true,
        'Button',
        [],
        'Button',
        getModulePosition('article-body-button-container', ref),
        'Button',
        efo(props, 'rendering.uid'),
        efo(link, 'value.text')
      )
    }
  }, [inViewTrack, link, props])

  useEffect(() => {
    log('Article Body Button', { ...fields })
  }, [fields])

  const iconName = efo(icon, 'fields.identifier.value')

  return (
    <div
      className="article-body-button-container"
      id={getDataSourceName(efo(props, 'rendering.dataSource'))}
      ref={setRefs}
    >
      <Link
        className="article-body-button-subcontainer"
        data-has-icon={iconName ? '' : undefined}
        field={link}
        target={
          efo(link, 'value.linktype') === 'external' ? '_blank' : undefined
        }
      >
        {iconName ? <Icon name={iconName} /> : undefined}
        <span>{efo(link, 'value.text')}</span>
      </Link>
    </div>
  )
}

export default withMinimumRequirements(ArticleBodyButton, [
  'fields.link.value.text',
  {
    mode: 'one-of',
    structure: ['fields.link.value.url', 'fields.link.value.href'],
  },
])
