import React, { useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { File, Text } from '@sitecore-jss/sitecore-jss-react'
import withMinimumRequirements from '../../../withMinimumRequirements'

import Image from '../../shared/Image'
import Icon from '../../shared/Icon'

import { efo, log, getMediaList } from '../../../helpers'

const getSitecoreField = (rawField) => {
  try {
    if (typeof rawField === 'object' && 'value' in rawField) {
      return rawField
    } else {
      return { value: rawField }
    }
  } catch {
    return rawField
  }
}

const raceNumberFormat = (raceNumber) => {
  const raceNumberField = Number(raceNumber)
  return raceNumberField > 9 ? raceNumberField : `0${raceNumberField}`
}

const ArticleBodyAutograph = (props) => {
  const { t, fields, params, fileLabel } = props

  useEffect(() => {
    log('Article Body Autograph Item', {
      fields: { ...fields },
      params: { ...params },
      fileLabel: { ...fileLabel },
    })
  }, [fields, params, fileLabel])

  return (
    <div className="swiper-slide article-body-autograph-collection-slide">
      <div className="general-autographs-item-name-container">
        <Text tag="span" field={getSitecoreField(fields.name)} />
      </div>
      <Image
        fields={{ image: getSitecoreField(fields.image) }}
        mediaList={getMediaList(350)}
        disableLazyLoad={true}
      />
      <div className="general-autographs-race-slider-container">
        <div className="general-autographs-race-slider-shadow general-autographs-race-slider-shadow-left" />
        <div className="general-autographs-race-slider-shadow general-autographs-race-slider-shadow-right" />
        <div className="general-autographs-race-container">
          <div className="general-autographs-race-separator-item general-autographs-race-separator-item-article" />
          <div className="general-autographs-race-subcontainer general-autographs-race-subcontainer-article">
            <div className="general-autographs-race-text-container">
              {efo(fields, 'raceNumber.value') && (
                <Text
                  tag="span"
                  className="general-autographs-race-text-container-racenumber"
                  field={getSitecoreField(
                    raceNumberFormat(efo(fields, 'raceNumber.value'))
                  )}
                />
              )}
              <Text tag="span" field={getSitecoreField(fields.race)} />
            </div>
          </div>
        </div>
      </div>
      {efo(fields.file, 'value') && (
        <File
          className="general-autographs-file-subcontainer"
          field={getSitecoreField('fields.file')}
          href={efo(fields.file, 'value.src')}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="general-autographs-file-download-button">
            <Icon name="download" size={25} />
          </div>
          <Text
            tag="span"
            field={getSitecoreField(fileLabel || t('autograph-download'))}
          />
        </File>
      )}
    </div>
  )
}

export default withTranslation()(
  withMinimumRequirements(ArticleBodyAutograph, [
    'fields.name.value',
    'fields.image.value',
    'fields.race.value',
  ])
)
