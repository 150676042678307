import React, { useEffect, useRef, useState } from 'react'

import withMinimumRequirements from '../../../withMinimumRequirements'

import {
  SKETCHFAB_VIEWER_DEFAULT_RATIO,
  SKETCHFAB_VIEWER_MOBILE_RATIO,
  BREAKPOINT_MOBILE,
} from '../../../constants'

const getURL = (
  modelID,
  {
    animatedEntrance,
    turnableAnimation,
    preselectAnotation,
    anotationAutopilot,
  }
) =>
  `https://sketchfab.com/models/${modelID}/embed?autostart=1&amp;preload=1` +
  (animatedEntrance ? '' : '&amp;camera=0') +
  (turnableAnimation ? '&amp;autospin=0.2' : '') +
  (preselectAnotation ? '&amp;annotation=1' : '') +
  (anotationAutopilot ? '&amp;annotation_cycle=5' : '')

const getHeight = (viewerRef) => {
  if (viewerRef && viewerRef.current) {
    const width = viewerRef.current.clientWidth

    let ratio = SKETCHFAB_VIEWER_DEFAULT_RATIO
    if (width < BREAKPOINT_MOBILE) {
      ratio = SKETCHFAB_VIEWER_MOBILE_RATIO
    }

    const ratioSplit = ratio.split(':')
    const ratioWidth = ratioSplit[0]
    const ratioHeight = ratioSplit[1]

    return (width * ratioHeight) / ratioWidth
  }

  return 0
}

const SketchfabViewer = (props) => {
  const { modelID, options } = props

  const viewerRef = useRef()

  const [height, setHeight] = useState(0)

  useEffect(() => {
    const listener = (viewerRef) => () => {
      setHeight(getHeight(viewerRef))
    }

    listener(viewerRef)()

    window.addEventListener('resize', listener(viewerRef))
    return () => window.removeEventListener('resize', listener(viewerRef))
  }, [])

  return (
    <iframe
      ref={viewerRef}
      src={getURL(modelID, options || {})}
      title="3D Model"
      width="100%"
      height={height}
      frameBorder="0"
      allow="autoplay; fullscreen; vr"
      mozallowfullscreen="true"
      webkitallowfullscreen="true"
    />
  )
}

export default withMinimumRequirements(SketchfabViewer, ['modelID'])
