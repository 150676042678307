const types = {
  SET: {
    STICKY: 'SET.STICKY',
    MENU: 'SET.MENU',
    SEARCH: 'SET.SEARCH',
    COUNTRY: {
      SELECTOR: 'SET.COUNTRY.SELECTOR',
      LIST: 'SET.COUNTRY.LIST',
    },
    TAG: {
      SELECTOR: 'SET.TAG.SELECTOR',
      LIST: 'SET.TAG.LIST',
    },
  },
}

const initialState = {
  sticky: true,
  menuStatus: false,
  searchStatus: false,
  countrySelectorStatus: false,
  countryListStatus: false,
  tagSelectorStatus: false,
  tagListStatus: false,
}

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.SET.SEARCH:
      return {
        ...state,
        searchStatus: action.value,
      }
    case types.SET.MENU:
      return {
        ...state,
        menuStatus: action.value,
      }
    case types.SET.STICKY:
      return {
        ...state,
        sticky: action.value,
      }
    case types.SET.COUNTRY.SELECTOR:
      return {
        ...state,
        countrySelectorStatus: action.value,
      }
    case types.SET.COUNTRY.LIST:
      return {
        ...state,
        countryListStatus: action.value,
      }
    case types.SET.TAG.SELECTOR:
      return {
        ...state,
        tagSelectorStatus: action.value,
      }
    case types.SET.TAG.LIST:
      return {
        ...state,
        tagListStatus: action.value,
      }
    default:
      return state
  }
}

const setHeaderSearchStatus = (dispatch, values) =>
  dispatch({
    type: types.SET.SEARCH,
    ...values,
  })

const setHeaderMenuStatus = (dispatch, values) =>
  dispatch({
    type: types.SET.MENU,
    ...values,
  })

const setHeaderSticky = (dispatch, values) =>
  dispatch({
    type: types.SET.STICKY,
    ...values,
  })

const setHeaderCountrySelectorStatus = (dispatch, values) =>
  dispatch({
    type: types.SET.COUNTRY.SELECTOR,
    ...values,
  })

const setHeaderCountryListStatus = (dispatch, values) =>
  dispatch({
    type: types.SET.COUNTRY.LIST,
    ...values,
  })

const setHeaderTagSelectorStatus = (dispatch, values) =>
  dispatch({
    type: types.SET.TAG.SELECTOR,
    ...values,
  })

const setHeaderTagListStatus = (dispatch, values) =>
  dispatch({
    type: types.SET.TAG.LIST,
    ...values,
  })

export default {
  initialState,
  reducer,
  actions: {
    setHeaderSearchStatus,
    setHeaderMenuStatus,
    setHeaderSticky,
    setHeaderCountrySelectorStatus,
    setHeaderCountryListStatus,
    setHeaderTagSelectorStatus,
    setHeaderTagListStatus,
  },
}
