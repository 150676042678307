import React from 'react'
import { useEffect, useState } from 'react'

import usePrivacyShield from '../../../hooks/usePrivacyShield'
import { efo, log } from '../../../helpers'

import './styles.scss'

// load storystram widget
const loadExternalScript = (scriptUrl) => {
  // Create script element
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.id = `stry-strm-script`

  // TODO: pass props and read script url from props
  script.src = scriptUrl
  script.async = true

  // Append script to the 'body' div
  document.getElementsByTagName(`body`)[0].appendChild(script)

  // Clean up the script when the component is unmounted
  return () => {
    //// remove privacy shield if it still exists
    ////  -> get story stream tontained
    ////  -> check if the last child has the class uc-data-consent
    //const elem = document.getElementById('storystream-module')
    //const lastChild = elem.lastChild
    //if (lastChild.className.indexOf('uc-data-consent') >= 0) {
    //  elem.remove(lastChild)
    //}
    document.getElementsByTagName(`body`)[0].removeChild(script)
  }
}

const StoryStream = (props) => {
  log('StoryStream::props_____________________', props)

  // use privacy shield to check consent
  const storyStreamSettings = efo(props, 'rendering.fields')
  const ucProcesssors = props.UC_PROCESSOR || {}
  const ucProcessorEmpty = Object.keys(ucProcesssors).length === 0
  const document = typeof window === 'undefined' ? undefined : window.document
  // TODO: pass correct ucProcessorList
  // TODO: privacyShield fails to remove itself after giving consent
  const storyStreamDataProcessorList = ucProcessorEmpty
    ? []
    : [
        ucProcesssors.facebooksocialplugin,
        ucProcesssors.facebookvideos,
        ucProcesssors.instagramcontent,
        ucProcesssors.storystream,
        ucProcesssors.vimeo,
        ucProcesssors.youtubevideo,
      ]
  const privacyShieldContainer = document
    ? document.getElementById(`storystream-module`)
    : undefined
  const approvalConsent = usePrivacyShield(
    storyStreamDataProcessorList,
    privacyShieldContainer,
    props.UC_READY
  )

  // widget data
  // -> sample widget url: 'https://apps.storystream.ai/app/js/2038187314125.js'
  const scriptUrl = efo(storyStreamSettings, 'WidgetScriptUrl.value') //
  const moduleHeadline = efo(storyStreamSettings, 'Title.value')
  const cssClassList = efo(storyStreamSettings, 'WidgetCSSClass.value') //

  const [cssModuleClasses, setModuleCssClasslist] = useState(
    'storystream__module'
  )

  useEffect(() => {
    if (approvalConsent === false) {
      return false
    }

    loadExternalScript(scriptUrl)
    setModuleCssClasslist('storystream__module consent--given')
  }, [scriptUrl, approvalConsent, cssModuleClasses])

  return (
    <>
      <div className={cssModuleClasses} id={`storystream-module`}>
        <div className={`storystream__content`}>
          {moduleHeadline && (
            <h2 className={`storystream__h2`}>{moduleHeadline}</h2>
          )}
          <div className={`storystream__wrapper`}>
            {<div id={`stry-wrapper`} className={cssClassList}></div>}
          </div>
        </div>
      </div>
    </>
  )
}

export default StoryStream
