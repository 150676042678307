import { useState, useEffect } from 'react'
import { TIME_EVENT } from '../constants'

const useTime = () => {
  const [time, setTime] = useState(new Date().getTime())

  const onTimeChanged = ({ detail: t }) => setTime(t)

  useEffect(() => {
    document.addEventListener(TIME_EVENT, onTimeChanged, false)
    return () => {
      document.removeEventListener(TIME_EVENT, onTimeChanged, false)
    }
  }, [])

  return time
}

export default useTime
