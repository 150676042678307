const types = {
  SEARCH: {
    SET: 'SEARCH.SET',
  },
  HOMEPAGE: {
    SET: 'HOMEPAGE.SET',
  },
  QUERY: {
    SET: 'QUERY.SET',
  },
}

const initialState = {
  search: {},
  homepage: {},
  query: null,
}

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.SEARCH.SET:
      return {
        ...state,
        search: action.value,
      }
    case types.HOMEPAGE.SET:
      return {
        ...state,
        homepage: action.value,
      }
    case types.QUERY.SET:
      return {
        ...state,
        query: action.value,
      }
    default:
      return state
  }
}

const setSearch = (dispatch, values) =>
  dispatch({
    type: types.SEARCH.SET,
    ...values,
  })

const setHomepage = (dispatch, values) =>
  dispatch({
    type: types.HOMEPAGE.SET,
    ...values,
  })

const setQuery = (dispatch, values) =>
  dispatch({
    type: types.QUERY.SET,
    ...values,
  })

export default {
  initialState,
  reducer,
  actions: {
    setSearch,
    setHomepage,
    setQuery,
  },
}
