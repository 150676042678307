import { useState, useEffect, useRef } from 'react'
import { throttle } from 'throttle-debounce'

/*
 * Returns the direction of the scroll and if passed the top threshold
 * @topThreshold: This is the offset on the top.
 * @scrollUpMin: Threshold to trigger up scroll
 * @scrollDownMin: Threshold to trigger down scroll
 */
const useScrollDirection = ({
  topThresholds = [],
  scrollUpMin = 200,
  scrollDownMin = 50,
}) => {
  const prevScroll = useRef()

  const [scrollDirection, setScrollDirection] = useState('down')
  const [passedThresholds, setPassedThresholds] = useState([])

  const scrollHandler = throttle(300, () => {
    if (!prevScroll.current) {
      prevScroll.current = 0
    }

    const currentScroll = typeof window !== 'undefined' ? window.pageYOffset : 0
    const movement = Math.abs(
      Math.abs(currentScroll) - Math.abs(prevScroll.current)
    )

    if (
      (prevScroll.current < currentScroll && movement > scrollDownMin) ||
      movement > scrollUpMin
    ) {
      const newScrollDirection =
        prevScroll.current === 0 || prevScroll.current < currentScroll
          ? 'down'
          : 'up'

      if (newScrollDirection !== scrollDirection) {
        setScrollDirection(newScrollDirection)
      }

      if (topThresholds.length > 0) {
        const newTopThresholds = topThresholds.map(
          (threshold) => currentScroll > threshold
        )

        if (
          JSON.stringify(newTopThresholds) !== JSON.stringify(passedThresholds)
        ) {
          setPassedThresholds(newTopThresholds)
        }
      }

      prevScroll.current = currentScroll
    }
  })

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler)
    return () => {
      window.removeEventListener('scroll', scrollHandler)
    }
  }, [scrollHandler])

  return [scrollDirection, passedThresholds]
}

export default useScrollDirection
