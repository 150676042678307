import React, { useEffect, useRef, useState } from 'react'
import { withTranslation, useTranslation } from 'react-i18next'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { useInView } from 'react-intersection-observer'

import {
  getKey,
  setActiveCountry,
  log,
  efo,
  isFullURLStructure,
  pushGlEvent,
} from '../../../../helpers'

import './styles.scss'

const CountryList = withTranslation()(
  ({
    countryList,
    countryListData,
    countrySelectorInput,
    countryListStatus,
    setCountryListData,
    countryItemHightlighted,
  }) => {
    const [t] = useTranslation()
    const countryListContainerRef = useRef()

    const [inViewRefTrack, inViewTrack] = useInView({
      threshold: 0.3,
    })
    const [noCountryFound, setNoCountryFound] = useState(false)

    // Tracking load
    useEffect(() => {
      let checkGDL = setInterval(() => {
        if (typeof window !== 'undefined' && !window.GDL) return
        clearInterval(checkGDL)
        if (typeof window !== 'undefined' && window.GDL) {
          pushGlEvent(
            false,
            'Country',
            [],
            'Country',
            '00',
            'Country',
            'country-list-00',
            'Country'
          )
        }
      }, 100)
    }, [])

    // Tracking in view
    useEffect(() => {
      if (inViewTrack && typeof window !== 'undefined' && window.GDL) {
        pushGlEvent(
          true,
          'Country',
          [],
          'Country',
          '00',
          'Country',
          'country-list-00',
          'Country'
        )
      }
    }, [inViewTrack])

    useEffect(() => {
      log('Country List', {
        countryList: { ...countryList },
        countrySelectorInput,
        countryListStatus,
        countryItemHightlighted,
      })
    }, [
      countryList,
      countrySelectorInput,
      countryListStatus,
      countryItemHightlighted,
    ])

    useEffect(() => {
      const getSortedCountries = (input) => {
        if (input === undefined || input === '') {
          return (
            countryList &&
            countryList.sort((a, b) => {
              const aText = a.text
              const bText = b.text

              if (aText < bText) {
                return -1
              }
              if (aText > bText) {
                return 1
              }
              return 0
            })
          )
        }
        const delimiterRegex = /[ ()&\\-]/
        const result =
          countryList &&
          countryList.filter((c) => {
            if (input.trim() === '') {
              return true // Return true to show all items when the input is empty or contains only spaces
            }

            const tagArraySplitWithDelimiter = efo(
              c.countryName,
              'value'
            ).split(delimiterRegex)

            if (input.includes(' ')) {
              // If input contains a space, check if the countryName includes the input as a whole
              return tagArraySplitWithDelimiter
                .join(' ')
                .toLowerCase()
                .includes(input.toLowerCase())
            } else {
              // If input does not contain a space, check for partial matches
              return tagArraySplitWithDelimiter.some((element) =>
                element.toLowerCase().startsWith(input.toLowerCase())
              )
            }
          })

        if (result && result.length) {
          setNoCountryFound(false)
          return result
        }
        setNoCountryFound(true)
      }

      setCountryListData(getSortedCountries(countrySelectorInput))
    }, [countrySelectorInput, countryList, setCountryListData])

    useEffect(() => {
      if (countryListContainerRef && countryListContainerRef.current) {
        if (countryListStatus) {
          setTimeout(
            () =>
              disableBodyScroll(countryListContainerRef.current, {
                allowTouchMove: (el) => {
                  while (el && el !== document.body) {
                    if (el.getAttribute('body-scroll-lock-ignore') !== null) {
                      return true
                    }
                    el = el.parentElement
                  }
                },
              }),
            500
          )
        } else {
          setTimeout(
            () => enableBodyScroll(countryListContainerRef.current),
            500
          )
        }
      }
    }, [countryListStatus])

    return (
      <div
        className="header2021-country-list-wrapper-container"
        ref={inViewRefTrack}
      >
        <div
          ref={countryListContainerRef}
          className="header2021-country-list-container"
          data-active={countryListStatus ? '' : undefined}
        >
          <div className="header2021-country-list-wrapper">
            <div
              className="header2021-country-list-subcontainer"
              body-scroll-lock-ignore="true"
            >
              {countryListData &&
                countryListData.map((c, i) => {
                  const target = efo(c, 'targetSite.fields.sitePath.value')
                  const defTarget = !isFullURLStructure()
                    ? target.replace(/^\/motorsport/gm, '')
                    : target

                  return (
                    <a
                      key={getKey(c.id, i)}
                      data-focused={
                        countryItemHightlighted === i ? '' : undefined
                      }
                      href={defTarget}
                      onClick={(event) => {
                        event.preventDefault()
                        event.stopPropagation()
                        setActiveCountry(c)
                        setTimeout(() => {
                          window.location.href = defTarget
                        }, 500)
                      }}
                    >
                      {c.countryName.value}
                    </a>
                  )
                })}
              {noCountryFound && <a>{t('tag-search-no-results')}</a>}
            </div>
          </div>
        </div>
      </div>
    )
  }
)

export default CountryList
