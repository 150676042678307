import React, { useState } from 'react'
import Icon from '../Icon'

import { NavLink } from 'react-router-dom'

import { Text } from '@sitecore-jss/sitecore-jss-react'

import { getCorrectURL } from '../../../helpers'

import './styles.scss'

const Button = (props) => {
  const {
    text,
    linkHref,
    linkAnchor,
    linkId,
    iconName,
    iconWidth,
    iconHeight,
  } = props

  const finalURL = linkHref || ''
  const finalID = linkId
  const isInternal = finalURL.startsWith('/')

  const [hovering, setHovering] = useState(false)

  const NavigationComponent = isInternal
    ? NavLink
    : React.createElement('a').type

  return (
    <NavigationComponent
      className="mospo-button"
      href={isInternal ? undefined : linkHref}
      target={isInternal ? undefined : '_blank'}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      to={
        isInternal
          ? {
              pathname: getCorrectURL(finalURL),
              hash: linkAnchor ? `#${linkAnchor}` : undefined,
              state: {
                layoutID: finalID,
              },
            }
          : undefined
      }
    >
      <Icon
        name={iconName}
        size={(iconWidth, iconHeight)}
        color={hovering ? '#000000' : '#ffffff'}
      />
      <Text className="mospo-button-link" tag="span" field={{ value: text }} />
    </NavigationComponent>
  )
}

export default Button
