import React, { useEffect } from 'react'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import { NavLink } from 'react-router-dom'

import Image from '../../shared/Image'
import Icon from '../../shared/Icon'

import { efo, getCorrectURL, getMediaList, log } from '../../../helpers'

import './styles.scss'

const NotFound = (props) => {
  const { fields } = props
  const { image, subline, headline, button, buttonURL } = fields
  const buttonAnchor = efo(buttonURL, 'value.anchor')

  useEffect(() => {
    log('Not Found', { ...fields })
  }, [fields])

  return (
    <div className="not-found-container">
      <div className="not-found-image">
        <Image
          fields={{
            image,
          }}
          mediaList={getMediaList()}
          cover
        />
      </div>
      <div className="not-found-subcontainer">
        <Text tag="h2" field={subline} />
        <Text tag="h1" field={headline} />
        <div className="not-found-separator" />
        <NavLink
          to={{
            pathname: getCorrectURL(efo(buttonURL, 'value.href') || '/'),
            hash: buttonAnchor ? `#${buttonAnchor}` : undefined,
          }}
          className="not-found-button"
        >
          <Icon className="not-found-icon" name="racingFlag" size={30} />
          <Text tag="span" field={button} />
        </NavLink>
      </div>
    </div>
  )
}

export default NotFound
