import React from 'react'

import Image from '../Image'

import { efo, getSitecoreImage, getMediaList } from '../../../helpers'

const MediaImage = (props) => {
  const { image, subtitle, title } = props

  return (
    <>
      <Image
        className="category-teaser-container-image"
        fields={{
          image: getSitecoreImage(
            efo(image, 'value.src'),
            efo(image, 'value.width'),
            efo(image, 'value.height'),
            { ...(efo(image, 'value') || {}) }
          ),
        }}
        mediaList={getMediaList(258, 258, 258, 258, 258)}
        cover
      />
      <div className="category-teaser-fade" />
      <div className="category-teaser-subcontainer">
        <span className="category-teaser-subtitle">
          {efo(subtitle, 'value')}
        </span>
        <span className="category-teaser-title">{efo(title, 'value')}</span>
      </div>
    </>
  )
}

export default MediaImage
