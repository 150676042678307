import React, { useEffect } from 'react'
import { Placeholder } from '@sitecore-jss/sitecore-jss-react'

import './styles.scss'

const SearchLayout = (props) => {
  const { rendering } = props

  useEffect(() => {
    // Access the parent element
    const parentElement = document.body
    // Remove the overflow: hidden property from the parent element
    parentElement.style.overflow = 'visible'
    // Cleanup: Revert the changes when the component unmounts
    return () => {
      parentElement.style.overflow = 'hidden'
    }
  }, [])

  return (
    <div className="search-result-layout-container">
      <Placeholder name="jss-motorsport-search-body" rendering={rendering} />
    </div>
  )
}

export default SearchLayout
