import React, { useContext, useState, useRef, useEffect } from 'react'
import { NavLink, useLocation, useHistory } from 'react-router-dom'
import { useLastLocation } from 'react-router-last-location'

import Icon from '../../shared/Icon'
import Image from '../../shared/Image'

import CountrySelector from './CountrySelector'
import CountryList from './CountryList'

import useScrollDirection from '../../../hooks/useScrollDirection'
import useSize from '../../../hooks/useSize'

import { actions, Store } from '../../../store'

import {
  getKey,
  getCorrectURL,
  efo,
  isMobile,
  log,
  getMediaList,
} from '../../../helpers'

import './styles.scss'

const Header = (props) => {
  const { fields, rendering, disableCountryRedirection } = props

  const logo = efo(fields, 'logo')
  const topLinks = efo(fields, 'topLinks')
  const navigationLinks = efo(fields, 'navigationLinks')
  const countryList = efo(fields, 'countries')

  const [scrollDirection, passedThresholds] = useScrollDirection({
    topThresholds: [300, 750],
  })
  const ref = useRef(null)
  const [, height] = useSize(ref, {
    initialHeight: 0,
  })

  const { state, dispatch } = useContext(Store)
  const {
    countrySelectorStatus,
    countryListStatus,
    tagSelectorStatus,
    sticky,
  } = state.header

  const setHeaderCountrySelectorStatus = (value) =>
    actions.setHeaderCountrySelectorStatus(dispatch, { value })
  const setHeaderCountryListStatus = (value) =>
    actions.setHeaderCountryListStatus(dispatch, { value })

  const [countryItemHightlighted, setCountryItemHightlighted] = useState(0)
  const [countrySelectorInput, setCountrySelectorInput] = useState('')
  const [countryListData, setCountryListData] = useState([])

  useEffect(() => {
    log('Header', {
      fields: { ...fields },
      rendering: { ...rendering },
    })
  }, [fields, rendering])

  return (
    <header
      ref={ref}
      className={`header-sticky ${
        !sticky
          ? 'down'
          : countrySelectorStatus || tagSelectorStatus
          ? 'up'
          : scrollDirection
      } ${passedThresholds[0] ? 'passed-first-threshold' : ''} ${
        passedThresholds[1] ? 'passed-second-threshold' : ''
      }`}
      style={{
        minHeight: passedThresholds[0] ? `${height}px` : null,
      }}
    >
      <div
        className="header-super-wrapper"
        style={{
          top: passedThresholds[0] ? `-${height}px` : null,
        }}
      >
        <div className="header-top-navigation-wrapper">
          <div className="header-top-navigation-container-wrapper">
            <div className="header-top-navigation-container">
              {topLinks
                ? topLinks.map((value, i) => (
                    <div
                      key={getKey(value.id, i)}
                      className="header-top-navigation-subcontainer"
                      data-active={i === 0 ? '' : undefined}
                    >
                      <a
                        href={value.fields.link.value.href}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {value.fields.link.value.text}
                      </a>
                    </div>
                  ))
                : undefined}
            </div>
          </div>
        </div>
        <div className="header-wrapper">
          <div className="header-bottom-navigation-container">
            <NavLink to="/" className="header-logo-container">
              {logo && (
                <Image
                  fields={{ image: logo }}
                  mediaList={getMediaList(150)}
                  originalSize
                  disableLazyLoad
                />
              )}
              <div className="header-bottom-navigation-shadow" />
            </NavLink>
            <CountrySelector
              countryList={countryList}
              countryListData={countryListData}
              countrySelectorStatus={countrySelectorStatus}
              setHeaderCountrySelectorStatus={setHeaderCountrySelectorStatus}
              countrySelectorInput={countrySelectorInput}
              setCountrySelectorInput={setCountrySelectorInput}
              countryListStatus={countryListStatus}
              disableCountryRedirection={disableCountryRedirection}
              setHeaderCountryListStatus={setHeaderCountryListStatus}
              onCountryItemHightlightedChanged={setCountryItemHightlighted}
            />
            <div className="header-bottom-navigation-subcontainer">
              <nav>
                {navigationLinks
                  ? navigationLinks.map((value, i) => (
                      <div
                        className="header-bottom-navigation-link-container"
                        key={getKey(value.id, i)}
                      >
                        <NavLink
                          to={{
                            pathname: getCorrectURL(
                              efo(value, 'fields.link.value.href') || '/'
                            ),
                            state: {
                              layoutID: efo(value, 'fields.link.value.id'),
                            },
                          }}
                        >
                          {efo(value, 'fields.link.value.text')}
                        </NavLink>
                      </div>
                    ))
                  : undefined}
              </nav>
              <CountrySelector
                countryList={countryList}
                countryListData={countryListData}
                countrySelectorStatus={countrySelectorStatus}
                setHeaderCountrySelectorStatus={setHeaderCountrySelectorStatus}
                countrySelectorInput={countrySelectorInput}
                setCountrySelectorInput={setCountrySelectorInput}
                countryListStatus={countryListStatus}
                disableCountryRedirection={disableCountryRedirection}
                setHeaderCountryListStatus={setHeaderCountryListStatus}
                onCountryItemHightlightedChanged={setCountryItemHightlighted}
              />
            </div>
          </div>
          <CountryList
            countryList={countryList}
            countryListData={countryListData}
            countrySelectorInput={countrySelectorInput}
            countryListStatus={countryListStatus}
            setCountryListData={setCountryListData}
            setHeaderCountrySelectorStatus={setHeaderCountrySelectorStatus}
            countryItemHightlighted={countryItemHightlighted}
          />
        </div>
      </div>
    </header>
  )
}

const HeaderClose = () => {
  const history = useHistory()
  const lastLocation = useLastLocation()

  const { state } = useContext(Store)
  const { sticky } = state.header

  const onCloseClick = () => {
    if (lastLocation) {
      history.goBack()
    } else {
      history.push('/')
    }
  }

  return (
    <div className="header-close" data-move-offset={!sticky ? '' : undefined}>
      <button className="header-close-button" onClick={onCloseClick}>
        <Icon name="close" size="20" color="black" />
      </button>
    </div>
  )
}

const HeaderWrapper = (props) => {
  const [isMobileView] = useState(isMobile())
  const location = useLocation()

  if ((efo(location, 'pathname') || '').includes('article') && isMobileView) {
    return <HeaderClose />
  }

  return <Header {...props} />
}

Header.displayName = 'Header'

export default HeaderWrapper
