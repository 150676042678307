import React, { useEffect, useState, useRef } from 'react'
import {
  Placeholder,
  Text,
  isEditorActive,
} from '@sitecore-jss/sitecore-jss-react'

import withMinimumRequirements from '../../../withMinimumRequirements'

import Swiper from 'swiper/swiper-bundle.esm.js'
import 'swiper/swiper-bundle.css'
import Icon from '../../shared/Icon'

import { efo, log, getDataSourceName } from '../../../helpers'

import '../../shared/Autographs/styles.scss'
import './styles.scss'

const getItems = (rendering) =>
  (
    efo(
      rendering,
      'placeholders.jss-motorsport-article-body-autograph-collection'
    ) || []
  ).filter((i) => !!efo(i, 'fields'))

const getSitecoreField = (rawField) => {
  try {
    if (typeof rawField === 'object' && 'value' in rawField) {
      return rawField
    } else {
      return { value: rawField }
    }
  } catch {
    return rawField
  }
}

const ArticleBodyAutographCollection = (props) => {
  const { fields, rendering } = props
  const items = getItems(rendering)
  const singleSlide = items.length === 1
  const [mounted, setMounted] = useState(false)
  const [loading, setLoading] = useState(isEditorActive() ? false : true)
  const [loadingAnimation, setLoadingAnimation] = useState(false)

  const swiper = useRef({ slider: null })

  const handleLoadingAnimationEnd = () => {
    setLoading(false)
  }

  useEffect(() => {
    swiper.current.slider = new Swiper('.swiper', {
      loop: true,
      autoHeight: true,
      shouldSwiperUpdate: true,
      observer: true,
      observeSlideChildren: true,
      navigation: {
        nextEl: '.general-autographs-slider-button-next',
        prevEl: '.general-autographs-slider-button-prev',
      },
    })
    setMounted(true)
    if (!isEditorActive()) {
      setLoadingAnimation(true)
    }
  }, [])

  useEffect(() => {
    if (mounted) {
      swiper.current.slider.allowTouchMove = !singleSlide
      swiper.current.slider.allowSlidePrev = !singleSlide
      swiper.current.slider.allowSlideNext = !singleSlide
    }
  }, [singleSlide, mounted])

  useEffect(() => {
    log('Article Body Autograph Collection', {
      fields: { ...fields },
      rendering: { ...rendering },
    })
  }, [fields, rendering])

  return (
    <div
      className="article-body-autograph-collection-container"
      id={getDataSourceName(efo(props, 'rendering.dataSource'))}
    >
      <div className="article-body-autograph-collection-subcontainer">
        <div
          className="general-autographs-container"
          data-single-slide={singleSlide ? '' : undefined}
        >
          <Text tag="span" field={getSitecoreField(efo(fields, 'headline'))} />
          <div className="swiper">
            <div className="swiper-wrapper">
              <Placeholder
                name="jss-motorsport-article-body-autograph-collection"
                rendering={rendering}
                fileLabel={efo(fields, 'fileDownloadLabel')}
              />
            </div>
            <div className="general-autographs-slider-button-prev general-autographs-slider-button-prev-article">
              <Icon name="arrowHeadLeft" size={20} />
            </div>
            <div className="general-autographs-slider-button-next general-autographs-slider-button-next-article">
              <Icon name="arrowHeadRight" size={20} />
            </div>
          </div>
        </div>
        {loading ? (
          <div
            className={`article-body-autograph-collection-loading ${
              loadingAnimation
                ? 'article-body-autograph-collection-loading-anim'
                : ''
            }`}
            onAnimationEnd={handleLoadingAnimationEnd}
          ></div>
        ) : undefined}
      </div>
    </div>
  )
}

export default withMinimumRequirements(ArticleBodyAutographCollection, [
  'fields.headline.value',
  'rendering.placeholders.jss-motorsport-article-body-autograph-collection.0',
])
