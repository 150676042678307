import React from 'react'
import AbstractCategoryTeaser from '../../shared/AbstractCategoryTeaser'
import Slider, { Slide } from '../../shared/Slider'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import withMinimumRequirements from '../../../withMinimumRequirements'

import '../ImageCategorySlider/styles.scss'

import { efo, log } from '../../../helpers'

const CategorySlider = (props) => {
  const {
    fields, // This is the parameter containing data from Sitecore
  } = props

  //log('CategorySlider', props)

  const { title, teasers, type } = fields

  return (
    <div
      className={`category-slider category-slider-${
        efo(type, 'value') || 'video'
      }`}
    >
      {efo(title, 'value') && (
        <div className="category-slider-title-container">
          <Text
            className="category-slider-title-container-title"
            tag="h2"
            field={title}
          />
        </div>
      )}
      <div className="category-slider-swiper-container">
        <div className="category-slider-swiper-center">
          <Slider
            className={`slider-wrapper-${efo(type, 'value') || 'video'}`}
            slidesMargin={
              efo(type, 'value') === 'square'
                ? 16
                : efo(type, 'value') === 'vertical'
                ? 32
                : 0
            }
            navigation
          >
            {(teasers || []).map((data, i) => (
              <Slide key={i}>
                <AbstractCategoryTeaser
                  UC_READY={props.UC_READY}
                  UC_PROCESSOR={props.UC_PROCESSOR}
                  {...data}
                  teaserComponentName={'VideoTeaser'}
                  index={i}
                />
              </Slide>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  )
}

export default withMinimumRequirements(CategorySlider, ['fields.teasers'])
