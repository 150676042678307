import React, { useEffect, useCallback, useRef } from 'react'
import { useInView } from 'react-intersection-observer'

import withMinimumRequirements from '../../../withMinimumRequirements'
import SketchfabViewer from '../../shared/SketchfabViewer'

import {
  efo,
  log,
  getModulePosition,
  pushGlEvent,
  getDataSourceName,
} from '../../../helpers'

import './styles.scss'

const ArticleHero3D = (props) => {
  const { fields } = props
  const ref = useRef()
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
  })
  const [inViewRefTrack, inViewTrack] = useInView({
    threshold: 0.3,
  })

  const setRefs = useCallback(
    (node) => {
      ref.current = node
      inViewRef(node)
      inViewRefTrack(node)
    },
    [inViewRef, inViewRefTrack]
  )

  useEffect(() => {
    log('Article Hero 3D', { ...fields })
  }, [fields])

  // Tracking load
  useEffect(() => {
    if (typeof window !== 'undefined' && window.GDL) {
      pushGlEvent(
        false,
        '3DHero',
        [],
        '3DHero',
        getModulePosition('article-hero-3d-container', ref),
        '3DHero',
        efo(props, 'rendering.uid'),
        '3DHero'
      )
    }
  }, [props])

  // Tracking in view
  useEffect(() => {
    if (inViewTrack && typeof window !== 'undefined' && window.GDL) {
      pushGlEvent(
        true,
        '3DHero',
        [],
        '3DHero',
        getModulePosition('article-hero-3d-container', ref),
        '3DHero',
        efo(props, 'rendering.uid'),
        '3DHero'
      )
    }
  }, [inViewTrack, props])

  return (
    <div
      className="article-hero-3d-container"
      ref={setRefs}
      id={getDataSourceName(efo(props, 'rendering.dataSource'))}
    >
      {inView ? (
        <SketchfabViewer
          modelID={efo(fields, 'modelID.value')}
          options={{
            animatedEntrance: efo(fields, 'animatedEntrance.value'),
            turnableAnimation: efo(fields, 'turnableAnimation.value'),
            preselectAnotation: efo(fields, 'preselectAnotation.value'),
            anotationAutopilot: efo(fields, 'anotationAutopilot.value'),
          }}
        />
      ) : (
        <span className="article-hero-3d-container-space-holder" />
      )}
    </div>
  )
}

export default withMinimumRequirements(ArticleHero3D, ['fields.modelID.value'])
