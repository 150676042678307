import React, { useRef, useState, useEffect } from 'react'
import fetch from 'isomorphic-unfetch'

import withMinimumRequirements from '../../../withMinimumRequirements'

import { efo, getKey, getConfig } from '../../../helpers'

import './styles.scss'

const getWidth = (viewerRef) => {
  if (viewerRef && viewerRef.current) {
    return viewerRef.current.clientWidth
  }

  return 0
}

const getAnswerData = (id, answersData) =>
  answersData.find((a) => a.id === id) || { votes: 0 }

const getTotalVotes = (answers, answersData, addVirtualVote) => {
  let totalVotes = 0

  for (let answer of answers) {
    totalVotes += getAnswerData(answer.id, answersData).votes
  }

  return addVirtualVote ? totalVotes + 1 : totalVotes
}

const getVotePercentage = (
  answer,
  answers,
  userAnswer,
  answersData,
  addVirtualVote
) => {
  if (!userAnswer) {
    return 0
  }

  const totalVotes = getTotalVotes(answers, answersData, addVirtualVote)

  let answerVotes = getAnswerData(answer.id, answersData).votes
  if (userAnswer && userAnswer.id === answer.id && addVirtualVote) {
    answerVotes += 1
  }

  return answerVotes / totalVotes
}

const getVoteWidth = (
  answer,
  answers,
  userAnswer,
  answersData,
  addVirtualVote,
  width
) =>
  getVotePercentage(answer, answers, userAnswer, answersData, addVirtualVote) *
  width

const getVotePercentageText = (
  answer,
  answers,
  userAnswer,
  answersData,
  addVirtualVote
) =>
  `${Math.round(
    getVotePercentage(
      answer,
      answers,
      userAnswer,
      answersData,
      addVirtualVote
    ) * 100
  )} %`

const getVotes = async (id, answerCount) => {
  let data

  try {
    const response = await fetch(
      `${getConfig(
        'website.pollEndpointGet'
      )}?id=${id}&answerCount=${answerCount}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )

    data = await response.json()
  } catch (error) {}

  return data
}

const updateVotes = async (id, answerId, answerCount) => {
  let data

  try {
    const response = await fetch(getConfig('website.pollEndpointUpdate'), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id,
        answerId,
        answerCount,
      }),
    })

    data = await response.json()
  } catch (error) {}

  return data
}

const Pool = (props) => {
  const { answers, randomGenerated, fields } = props

  const id = efo(fields, 'id')

  const pollId = `poll_${id}`

  const answerContainerRef = useRef()

  const [addVirtualVote, setAddVirtualVote] = useState(false)
  const [answersData, setAnswersData] = useState([])
  const [userAnswer, setUserAnswer] = useState(undefined)
  const [width, setWidth] = useState(0)
  const [localStorageChecked, setLocalStorageChecked] = useState(false)

  useEffect(() => {
    const listener = (answerContainerRef) => () => {
      setWidth(getWidth(answerContainerRef))
    }

    listener(answerContainerRef)()

    window.addEventListener('resize', listener(answerContainerRef))
    return () =>
      window.removeEventListener('resize', listener(answerContainerRef))
  }, [])

  useEffect(() => {
    if (!localStorageChecked) {
      if (!randomGenerated) {
        const oldData = JSON.parse(localStorage.getItem(pollId))
        if (oldData && JSON.stringify(oldData) !== JSON.stringify(userAnswer)) {
          setUserAnswer(oldData)
        }
      }
      setLocalStorageChecked(true)
    }
  }, [localStorageChecked, userAnswer, pollId, randomGenerated])

  useEffect(() => {
    if (typeof localStorage !== 'undefined' && !randomGenerated && userAnswer) {
      const oldData = localStorage.getItem(pollId)
      const newData = JSON.stringify(userAnswer)
      if (oldData !== newData) {
        try {
          localStorage.setItem(pollId, newData)
        } catch (error) {
          // Nothing to do here
        }
      }
    }
  }, [userAnswer, pollId, randomGenerated])

  useEffect(() => {
    getVotes(id, answers.length).then(
      (data) => data && setAnswersData(data.answers)
    )
  }, [id, answers.length])

  return (
    <div
      className="general-poll-container"
      data-answered={userAnswer ? '' : undefined}
    >
      <div ref={answerContainerRef} className="general-poll-answers-container">
        {answers.map((answer, index) => (
          <div
            key={getKey(answer.id, index)}
            className="general-poll-answer-container"
            data-user-answer={
              userAnswer && userAnswer.id === answer.id ? '' : undefined
            }
            onClick={() => {
              if (!userAnswer) {
                setUserAnswer(answer)
                setAddVirtualVote(true)
                updateVotes(id, answer.id, answers.length).then((data) => {
                  if (data) {
                    setAnswersData(data.answers)
                    setAddVirtualVote(false)
                  }
                })
              }
            }}
          >
            <div
              className="general-poll-answer-votes"
              style={{
                maxWidth: getVoteWidth(
                  answer,
                  answers,
                  userAnswer,
                  answersData,
                  addVirtualVote,
                  width
                ),
              }}
            />
            <span className="general-poll-answer-percentage">
              {getVotePercentageText(
                answer,
                answers,
                userAnswer,
                answersData,
                addVirtualVote
              )}
            </span>
            <span className="general-poll-answer-text">{answer.answer}</span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default withMinimumRequirements(Pool, [
  'id',
  'answers',
  'answers.0',
  'answers.1',
])
