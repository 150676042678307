import React, { useEffect, useRef, useState } from 'react'

import SwiperCore, { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import withMinimumRequirements from '../../../withMinimumRequirements'
import Icon from '../../shared/Icon'

// Import Swiper styles
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.scss'
// ----
import './styles.scss'

SwiperCore.use([Pagination, Navigation])

export { SwiperSlide as Slide }

const Slider = ({
  children,
  className = '',
  index = 0,
  onSlideChange = () => {},
  onIndexChange = () => {},
  wrapperStyle = {},
  navigation = false,
  navigationMini = false,
  pagination = false,
  centeredSlides = false,
  autoHeight = false,
  slidesMargin = 10,
  slidesPerView = 'auto',
  ...rest
}) => {
  const sliderRef = useRef()

  const [currentIndex, setCurrentIndex] = useState(index)
  const [firstIndexCheck, setFirstIndexCheck] = useState(false)

  useEffect(() => {
    if (!firstIndexCheck && sliderRef && sliderRef.current) {
      if (currentIndex !== 0) {
        const instance = sliderRef.current.swiper
        if (instance) {
          instance.slideTo(currentIndex)
        }
      }
      setFirstIndexCheck(true)
    }
  }, [currentIndex, firstIndexCheck])

  useEffect(() => {
    if (currentIndex !== index) {
      if (sliderRef && sliderRef.current) {
        const instance = sliderRef.current.swiper
        if (instance) {
          instance.slideTo(index)
        }
      }
      setCurrentIndex(index)
    }
    // eslint-disable-next-line
  }, [index, sliderRef])

  useEffect(() => {
    onIndexChange(currentIndex)
  }, [onIndexChange, currentIndex])

  useEffect(() => {
    if (sliderRef && sliderRef.current) {
      setTimeout(() => {
        const instance = sliderRef.current.swiper
        if (instance) {
          instance.update()
        }
      }, 1)
    }
  }, [])

  delete rest.sitecoreContext
  delete rest.updateSitecoreContext

  return (
    <div className={`slider-wrapper ${className}`} style={wrapperStyle}>
      <Swiper
        ref={sliderRef}
        className={`slider ${
          currentIndex < 3 && children.length >= 5
            ? 'slider-bullet-center-fix'
            : ''
        }`}
        data-pagination={pagination ? '' : undefined}
        spaceBetween={slidesMargin}
        slidesPerView={slidesPerView}
        navigation={
          navigation
            ? {
                prevEl: '.slider-button-prev',
                nextEl: '.slider-button-next',
              }
            : false
        }
        centeredSlides={centeredSlides}
        autoHeight={autoHeight}
        onSlideChange={(swiper) => {
          setCurrentIndex(swiper.activeIndex)
          onSlideChange()
        }}
        {...(pagination
          ? {
              pagination: {
                clickable: true,
                dynamicBullets: children.length >= 5 ? true : false,
                dynamicMainBullets: 1,
              },
            }
          : {})}
        {...rest}
      >
        {children}
        {navigation ? (
          <>
            <div className="swiper-button-prev slider-button-prev">
              <Icon name="arrowHeadLeft" size={navigationMini ? 50 : 70} />
            </div>
            <div className="swiper-button-next slider-button-next">
              <Icon name="arrowHeadRight" size={navigationMini ? 50 : 70} />
            </div>
          </>
        ) : undefined}
      </Swiper>
    </div>
  )
}

export default withMinimumRequirements(Slider, ['children'])
