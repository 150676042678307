const log = (measurement) => (data) => {
  console.groupCollapsed(`WebVitals "${measurement}" at ${Date.now()}`)
  console.log(data)
  console.groupEnd()
}

const reportWebVitals = () => {
  import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
    getCLS(log('CLS'))
    getFID(log('FID'))
    getFCP(log('FCP'))
    getLCP(log('LCP'))
    getTTFB(log('TTFB'))
  })
}

export default reportWebVitals
