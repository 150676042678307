import React from 'react'

import Image from '../Image'

import { getMediaList } from '../../../helpers'

const MediaImage = ({ image, ratio, modal, cover }) =>
  image ? (
    <Image
      className="teaser-image"
      src={image.src}
      thumb={image.base64String}
      width={image.width}
      height={image.height}
      alt={image.alt}
      ratio={ratio}
      mediaList={
        !modal ? getMediaList(400) : getMediaList(760, 1000, 1300, 1760, 1920)
      }
      cover={cover}
    />
  ) : (
    <div className="teaser-lazy-image">
      <svg width="160" height="90" />
    </div>
  )

export default MediaImage
