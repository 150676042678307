import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'

import { Swiper, SwiperSlide as Slide } from 'swiper/react'

import withMinimumRequirements from '../../../withMinimumRequirements'
import Icon from '../Icon'

import 'swiper/swiper.scss'

import './styles.scss'

import { getKey, navigate, efo } from '../../../helpers'
import { RACE_CALENDAR_STATUS_DEFAULT } from '../../../constants'

const missingDotGerman = (locale) => {
  if (locale === 'de-DE') {
    return '.'
  } else {
    return ''
  }
}

const getDateRange = (locale, rawStartDate, rawEndDate) => {
  const startDate = new Date(rawStartDate)
  const endDate = new Date(rawEndDate)

  const dayOptions = {
    day: 'numeric',
  }

  const dayMonthOptions = {
    ...dayOptions,
    month: 'short',
  }

  const fullOptions = {
    ...dayMonthOptions,
    year: 'numeric',
  }

  if (locale === 'en-US') {
    locale = 'en-GB'
  }

  if (startDate.getFullYear() === endDate.getFullYear()) {
    if (startDate.getMonth() === endDate.getMonth()) {
      if (startDate.getDate() === endDate.getDate()) {
        return endDate.toLocaleDateString(locale, fullOptions)
      }
      return `${startDate.toLocaleDateString(
        locale,
        dayOptions
      )}${missingDotGerman(locale)} - ${endDate.toLocaleDateString(
        locale,
        fullOptions
      )}`
    }
    return `${startDate.toLocaleDateString(
      locale,
      dayMonthOptions
    )} - ${endDate.toLocaleDateString(locale, fullOptions)}`
  }
  return `${startDate.toLocaleDateString(
    locale,
    fullOptions
  )} - ${endDate.toLocaleDateString(locale, fullOptions)}`
}

const isPastRace = (rawEndDate) => {
  const endDate = new Date(rawEndDate)
  const currentDate = new Date()
  if (currentDate.getTime() > endDate.getTime()) {
    return true
  }
  return false
}

const getEventTime = (date, language) => {
  const hours = date.getHours()
  const minutes = date.getMinutes()
  let parsedHours
  let parsedMinutes

  if (language === 'en-US') {
    const term = hours >= 12 ? 'PM' : 'AM'

    parsedHours = hours % 12
    parsedMinutes = minutes < 10 ? `0${minutes}` : minutes
    parsedHours = parsedHours ? parsedHours : 12
    parsedHours = parsedHours < 10 ? `0${parsedHours}` : parsedHours

    return `${parsedHours}:${parsedMinutes} ${term}`
  } else {
    parsedMinutes = minutes < 10 ? `0${minutes}` : minutes
    parsedHours = hours < 10 ? `0${hours}` : hours

    return `${parsedHours}:${parsedMinutes}`
  }
}

const getEventTimeInfo = (rawStartDate, rawEndDate, language) => {
  const startDate = new Date(rawStartDate)
  const endDate = new Date(rawEndDate)

  return `${getEventTime(startDate, language)} - ${getEventTime(
    endDate,
    language
  )}`
}

const checkTable = (raceDays, targetUrl) => {
  if (raceDays.length > 0 || targetUrl) {
    return true
  } else {
    return false
  }
}

const TableContent = withTranslation()((props) => {
  const { t, raceDays, targetURL, show, language } = props

  const history = useHistory()

  const finalURL = efo(targetURL, 'href') || ''
  const finalAnchor = efo(targetURL, 'anchor')

  return (
    <div
      className="general-race-calendar-item-table-content-container"
      data-show={show ? '' : undefined}
      onClick={(event) => {
        event.preventDefault()
        event.stopPropagation()
      }}
    >
      <div className="general-race-calendar-item-table-content-subcontainer">
        {raceDays &&
          raceDays.map((day, i) => (
            <div
              key={i}
              className="general-race-calendar-item-table-content-element-container"
            >
              <span>{day.title}</span>
              <div className="general-race-calendar-item-table-content-element-subcontainer">
                {day.raceDayEvents.map((event, j) => (
                  <div
                    key={j}
                    className="general-race-calendar-item-table-content-element-item-container"
                  >
                    <span>{event.title}</span>
                    <span>
                      {getEventTimeInfo(
                        event.startDate,
                        event.endDate,
                        language
                      )}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          ))}
        {finalURL !== '' ? (
          <span
            className="general-race-calendar-target-url"
            onClick={(event) => {
              event.preventDefault()
              event.stopPropagation()
              navigate(
                `${finalURL}${finalAnchor ? '#' + finalAnchor : ''}`,
                history
              )
            }}
          >
            {t('race-calendar-target-url')}
          </span>
        ) : undefined}
      </div>
    </div>
  )
})

const getRaceSeries = (items) => {
  const raceSeries = []

  for (let item of items) {
    for (let race of item.raceSeries) {
      if (raceSeries.indexOf(race.name) === -1) {
        raceSeries.push(race.name)
      }
    }
  }

  return raceSeries
}

const RaceCalendarItem = withTranslation()((props) => {
  const {
    t,
    item,
    index,
    language,
    showTableContent,
    setShowTableContent,
    setShowFullCalendar,
  } = props

  const ref = useRef()

  useEffect(() => {
    setShowTableContent(undefined)
  }, [item, setShowTableContent])

  return (
    <div
      ref={ref}
      className="general-race-calendar-item-container"
      data-active={showTableContent === index ? '' : undefined}
      data-notable={checkTable(item.raceDays, item.targetURL) ? undefined : ''}
      onClick={(event) => {
        event.preventDefault()
        event.stopPropagation()
        if (!checkTable(item.raceDays, item.targetURL)) {
          return
        }
        if (showTableContent === index) {
          setShowTableContent(undefined)
        } else {
          setShowTableContent(index)
          setShowFullCalendar(true)
          if (ref && ref.current) {
            setTimeout(
              () =>
                ref.current.scrollIntoView({
                  behavior: 'smooth',
                }),
              400
            )
          }
        }
      }}
    >
      <div className="general-race-calendar-item-head-container">
        {item.raceStatus !== RACE_CALENDAR_STATUS_DEFAULT ? (
          <Icon
            className="general-race-calendar-item-status-icon"
            data-status={item.raceStatus}
            name="racingFlag"
            size={14}
          />
        ) : isPastRace(item.endDate) ? (
          <Icon
            className="general-race-calendar-item-status-icon"
            name="racingFlag"
            size={14}
          />
        ) : undefined}
        <span
          className="general-race-calendar-item-head-title"
          data-status={item.raceStatus}
        >
          {item.title}
        </span>
        <span className="general-race-calendar-item-head-date">
          {getDateRange(language, item.startDate, item.endDate)}
        </span>
      </div>
      <div
        data-add-vertical-padding={
          item.raceStatus === RACE_CALENDAR_STATUS_DEFAULT ? '' : undefined
        }
        className="general-race-calendar-item-race-series-container"
      >
        {item.raceSeries.map((raceSeries, j) => (
          <span key={getKey(raceSeries.id, j)}>{raceSeries.name}</span>
        ))}
      </div>
      {item.raceStatus !== RACE_CALENDAR_STATUS_DEFAULT ? (
        <span
          data-status={item.raceStatus}
          className="general-race-calendar-item-status-tag"
        >
          {t(`race-calendar-status-${item.raceStatus}`)}
        </span>
      ) : undefined}
      {checkTable(item.raceDays, item.targetURL) ? (
        <TableContent
          raceDays={item.raceDays}
          targetURL={item.targetURL}
          show={showTableContent === index}
          language={language}
        />
      ) : undefined}
    </div>
  )
})

const RaceCalendar = withSitecoreContext()((props) => {
  const { t, items, scrollIntoTeaser, sitecoreContext } = props

  const language = sitecoreContext.language

  const containerRef = useRef()
  const sliderRef = useRef()
  const pickerRef = useRef()

  const [filteredItems, setFilteredItems] = useState(items)
  const [raceSeries, setRaceSeries] = useState(getRaceSeries(items))
  const [selectedRaceSeries, setSelectedRaceSeries] = useState([])
  const [showRaceSeriesSelector, setShowRaceSeriesSelector] = useState(false)
  const [showFullCalendar, setShowFullCalendar] = useState(false)
  const [showTableContent, setShowTableContent] = useState(undefined)

  useEffect(() => {
    setSelectedRaceSeries([])
    setRaceSeries(getRaceSeries(items))
    setFilteredItems(items)
  }, [items])

  useEffect(() => {
    if (sliderRef && sliderRef.current) {
      sliderRef.current.swiper.update()
    }
  }, [showFullCalendar, showTableContent, filteredItems])

  useEffect(() => {
    const list = selectedRaceSeries.length ? selectedRaceSeries : raceSeries

    setFilteredItems(
      items.filter((i) => {
        const values = i.raceSeries.map((r) => r.name)

        for (let value of values) {
          if (list.includes(value)) {
            return true
          }
        }

        return false
      })
    )
  }, [items, raceSeries, selectedRaceSeries])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        pickerRef.current &&
        !pickerRef.current.contains(event.target) &&
        showRaceSeriesSelector
      ) {
        setShowRaceSeriesSelector(false)
      } else if (
        pickerRef.current &&
        pickerRef.current.contains(event.target) &&
        !showRaceSeriesSelector
      ) {
        setShowRaceSeriesSelector(true)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [pickerRef, showRaceSeriesSelector, setShowRaceSeriesSelector])

  return (
    <div ref={containerRef} className="general-race-calendar-container">
      <div
        ref={pickerRef}
        className="general-race-calendar-series-picker-container"
      >
        <div className="general-race-calendar-series-picker-subcontainer">
          {selectedRaceSeries.map((race) => (
            <span key={race}>
              {race}
              <Icon
                name="close"
                size={15}
                onClick={(event) => {
                  event.preventDefault()
                  event.stopPropagation()
                  setSelectedRaceSeries(
                    selectedRaceSeries.filter((r) => r !== race)
                  )
                }}
              />
            </span>
          ))}
          {raceSeries.length > 1 ? (
            <span data-hide={selectedRaceSeries.length ? '' : undefined}>
              {t('race-calendar-input-placeholder')}
            </span>
          ) : (
            <span data-hide={selectedRaceSeries.length ? '' : undefined}>
              {raceSeries[0]}
            </span>
          )}
        </div>
        <div
          className="general-race-calendar-series-picket-list-container"
          data-show={showRaceSeriesSelector ? '' : undefined}
        >
          <div className="general-race-calendar-series-picket-list-subcontainer">
            {raceSeries.length > 1 &&
              raceSeries.map((race) => (
                <span
                  key={race}
                  data-selected={
                    selectedRaceSeries.includes(race) ? '' : undefined
                  }
                  onClick={() => {
                    if (!selectedRaceSeries.includes(race)) {
                      const newRaceSeries = [...selectedRaceSeries, race]
                      setSelectedRaceSeries([...selectedRaceSeries, race])

                      if (newRaceSeries.length === raceSeries.length) {
                        setShowRaceSeriesSelector(false)
                      }
                    }
                  }}
                >
                  {race}
                </span>
              ))}
          </div>
        </div>
      </div>
      <div
        className="general-race-calendar-arrow"
        onClick={() => {
          if (sliderRef && sliderRef.current) {
            sliderRef.current.swiper.slidePrev()
          }
        }}
      >
        <Icon name="arrowHeadUp" size={20} />
      </div>
      <div
        className="general-race-calendar-subcontainer"
        data-show-full-calendar={showFullCalendar ? '' : undefined}
      >
        <Swiper
          ref={sliderRef}
          className="slider general-race-calendar-slider"
          data-disable-padding
          direction="vertical"
          slidesPerView="auto"
        >
          {filteredItems.map((item, i) => (
            <Slide key={getKey(item.id, i)}>
              <RaceCalendarItem
                item={item}
                index={i}
                language={language}
                showTableContent={showTableContent}
                setShowTableContent={setShowTableContent}
                setShowFullCalendar={setShowFullCalendar}
              />
            </Slide>
          ))}
        </Swiper>
      </div>
      <div
        className="general-race-calendar-arrow"
        onClick={() => {
          if (sliderRef && sliderRef.current) {
            sliderRef.current.swiper.slideNext()
          }
        }}
      >
        <Icon name="arrowHeadDown" size={20} />
      </div>
      <div
        className="general-race-calendar-show-button"
        onClick={() => {
          if (scrollIntoTeaser && showFullCalendar) {
            setTimeout(() => scrollIntoTeaser(), 100)
          }
          setShowFullCalendar(!showFullCalendar)
        }}
      >
        <span>
          {t(
            showFullCalendar
              ? 'race-calendar-hide-full-calendar'
              : 'race-calendar-show-full-calendar'
          )}
        </span>
      </div>
    </div>
  )
})

export default withTranslation()(
  withMinimumRequirements(RaceCalendar, ['items', 'items.0'])
)
