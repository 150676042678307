import React, { useState } from 'react'

import Icon from '../Icon'

import { efo } from '../../../helpers'

const BottomFanboost = (props) => {
  const { fanboostLink, fanboostText, fanboostThankText } = props

  const [operated, setOperated] = useState(false)

  return !operated ? (
    <a
      className="teaser-bottom-fanboost-container"
      href={efo(fanboostLink, 'href')}
      target="_blank"
      rel="noopener noreferrer"
      onClick={() => setOperated(true)}
    >
      <Icon name="arrowDoubleRight" />
      <span>{fanboostText}</span>
    </a>
  ) : (
    <div className="teaser-bottom-fanboost-thank-container">
      <span>{fanboostThankText}</span>
    </div>
  )
}

export default BottomFanboost
