import React from 'react'
import { NavLink } from 'react-router-dom'
import Video from '../Video'

import { efo, getCorrectURL, isMobile, getTeaserAnchor } from '../../../helpers'

const truncate = (str, n) => {
  return str.length > n ? str.substr(0, n - 1) + ' ...' : str
}

const VideoPlayer = (props) => (
  <Video
    UC_READY={props.UC_READY}
    UC_PROCESSOR={props.UC_PROCESSOR}
    url={efo(props, 'videourl.value.href') || efo(props, 'videofile.value.src')}
    image={efo(props, 'image.value')}
    options={{
      aspectRatio: efo(props, 'aspectRatio.value'),
      autoplay: false, // Always false on sliders
      playOnHover: false, // Always false on sliders
      loop: efo(props, 'loop.value'),
      muted: efo(props, 'muted.value'),
      livestream: efo(props, 'livestream.value'),
    }}
    isHovering={efo(props, 'isHovering')}
    isTeaser
  />
)

const MediaVideo = (props) => {
  const { targeturl } = props

  if (efo(targeturl, 'value') && efo(targeturl, 'value.href')) {
    const finalURL = efo(targeturl, 'value.href') || ''
    const finalID = efo(targeturl, 'value.id')
    const finalAnchor = efo(targeturl, 'value.anchor')
    const isInternal = finalURL.startsWith('/')

    const NavigationComponent = isInternal
      ? NavLink
      : React.createElement('a').type

    return (
      <>
        <VideoPlayer {...props} />
        <div className="category-teaser-video-title">
          <NavigationComponent
            href={isInternal ? undefined : finalURL}
            target={isInternal ? undefined : '_blank'}
            to={
              isInternal
                ? {
                    pathname: getCorrectURL(finalURL),
                    hash: getTeaserAnchor(finalAnchor)
                      ? `#${finalAnchor}`
                      : undefined,
                    state: {
                      layoutID: finalID,
                    },
                  }
                : undefined
            }
          >
            <span>{efo(props, 'title.value')}</span>
          </NavigationComponent>
        </div>
      </>
    )
  }
  return (
    <>
      <VideoPlayer {...props} />
      <div className="category-teaser-video-title">
        <span>{truncate(efo(props, 'title.value'), isMobile() ? 44 : 72)}</span>
      </div>
    </>
  )
}

export default MediaVideo
