import React, { useState } from 'react'
import Icon from '../Icon'
import { usePagination, DOTS } from '../../../hooks/usePagination'
import './styles.scss'

const Pagination = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
  } = props

  const [hoveringRight, setHoveringRight] = useState(false)
  const [hoveringLeft, setHoveringLeft] = useState(false)

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  })

  if (currentPage === 0 || paginationRange.length < 2) {
    return null
  }

  const onNext = () => {
    onPageChange(currentPage + 1)
  }

  const onPrevious = () => {
    onPageChange(currentPage - 1)
  }

  let lastPage = paginationRange[paginationRange.length - 1]

  return (
    <ul className={`pagination-container ${className}`}>
      <li
        className={`pagination-item ${currentPage === 1 ? 'disabled' : ''}`}
        onClick={onPrevious}
      >
        <div
          className="arrow left"
          onMouseEnter={() => {
            setHoveringLeft(true)
          }}
          onMouseLeave={() => {
            setHoveringLeft(false)
          }}
        >
          <Icon
            name={'arrowHeadLeft'}
            size={(10, 18)}
            color={hoveringLeft ? '#000000' : '#96989A'}
          />
        </div>
      </li>
      {paginationRange.map((pageNumber, index) => {
        if (pageNumber === DOTS) {
          return (
            <li key={index} className="pagination-item dots">
              &#8230;
            </li>
          )
        }

        return (
          <li
            key={index}
            className={`pagination-item ${
              pageNumber === currentPage ? 'selected' : ''
            }`}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </li>
        )
      })}
      <li
        className={`pagination-item ${
          currentPage === lastPage ? 'disabled' : ''
        }`}
        onClick={onNext}
      >
        <div
          className="arrow right"
          onMouseEnter={() => {
            setHoveringRight(true)
          }}
          onMouseLeave={() => {
            setHoveringRight(false)
          }}
        >
          <Icon
            name={'arrowHeadRight'}
            size={(10, 18)}
            color={hoveringRight ? '#000000' : '#96989A'}
          />
        </div>
      </li>
    </ul>
  )
}

export default Pagination
