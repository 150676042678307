import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import { File, Text } from '@sitecore-jss/sitecore-jss-react'

import withMinimumRequirements from '../../../withMinimumRequirements'
import Slider, { Slide } from '../Slider/SliderMin'
import Image from '../Image'
import Icon from '../Icon'

import { efo, getKey, getMediaList } from '../../../helpers'

import './styles.scss'

const getSitecoreField = (rawField) =>
  efo(rawField, 'value') ? rawField : { value: rawField }

const getItem = (items, index) => {
  if (index > items.lenght) {
    return items[0]
  }

  return items[index - 1]
}

const Autographs = (props) => {
  const { t, headline, fileLabel, items } = props

  const [mainSlider, setMainSlider] = useState(null)
  const [raceSlider, setRaceSlider] = useState(null)
  const [index, setIndex] = useState(1)

  const singleSlide = items.length === 1

  return (
    <div
      className="general-autographs-container"
      data-single-slide={singleSlide ? '' : undefined}
    >
      <Text tag="span" field={getSitecoreField(headline)} />
      <Slider
        controller={{ control: raceSlider }}
        navigation={{
          prevEl: '.general-autographs-slider-button-prev',
          nextEl: '.general-autographs-slider-button-next',
        }}
        allowTouchMove={!singleSlide}
        allowSlidePrev={!singleSlide}
        allowSlideNext={!singleSlide}
        onSwiper={setMainSlider}
        onSlideChange={(swiper) => {
          setIndex(swiper.activeIndex)
        }}
        observer
        observeSlideChildren
        observeParents
        loop
      >
        {items.map((item, i) => (
          <Slide key={getKey(item.uid, i)}>
            <div className="general-autographs-item-name-container">
              <Text
                tag="span"
                field={getSitecoreField(efo(item, 'fields.name'))}
              />
            </div>
            <Image
              fields={{ image: getSitecoreField(efo(item, 'fields.image')) }}
              mediaList={getMediaList(350)}
            />
          </Slide>
        ))}
        <div className="general-autographs-slider-button-prev">
          <Icon name="arrowHeadLeft" size={20} />
        </div>
        <div className="general-autographs-slider-button-next">
          <Icon name="arrowHeadRight" size={20} />
        </div>
      </Slider>
      <div className="general-autographs-race-slider-container">
        <div className="general-autographs-race-slider-shadow general-autographs-race-slider-shadow-left" />
        <div className="general-autographs-race-slider-shadow general-autographs-race-slider-shadow-right" />
        <Slider
          controller={{ control: mainSlider }}
          allowTouchMove={!singleSlide}
          allowSlidePrev={!singleSlide}
          allowSlideNext={!singleSlide}
          onSwiper={setRaceSlider}
          observer
          observeSlideChildren
          observeParents
          loop={!singleSlide}
          slidesPerView={1}
        >
          {items.map((item, i) => (
            <Slide key={getKey(item.uid, i)}>
              <div className="general-autographs-race-container">
                <div className="general-autographs-race-separator-item general-autographs-race-separator-item-article" />
                <div className="general-autographs-race-subcontainer general-autographs-race-subcontainer-article">
                  <div className="general-autographs-race-text-container ">
                    <span className="general-autographs-race-text-container-racenumber">
                      {i + 1 > 9 ? i + 1 : `0${i + 1}`}
                    </span>
                    <Text
                      tag="span"
                      field={getSitecoreField(efo(item, 'fields.race'))}
                    />
                  </div>
                </div>
              </div>
            </Slide>
          ))}
        </Slider>
      </div>
      <div
        className="general-autographs-file-container"
        data-show={
          !!getSitecoreField(efo(getItem(items, index), 'fields.file')).value
            ? ''
            : undefined
        }
      >
        <File
          className="general-autographs-file-subcontainer"
          field={getSitecoreField(efo(getItem(items, index), 'fields.file'))}
          href={efo(
            getSitecoreField(efo(getItem(items, index), 'fields.file')),
            'value'
          )}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="general-autographs-file-download-button">
            <Icon name="download" size={25} />
          </div>
          <Text
            tag="span"
            field={getSitecoreField(fileLabel || t('autograph-download'))}
          />
        </File>
      </div>
    </div>
  )
}

export default withTranslation()(
  withMinimumRequirements(Autographs, [
    'headline',
    'items',
    'items.0',
    'items.0.fields.name',
    'items.0.fields.image',
    'items.0.fields.race',
  ])
)
