import React, { useState, useEffect, useRef, useContext } from 'react'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { useHistory } from 'react-router-dom'

import { Store } from '../../../../store'

import SearchInput from '../../../shared/SearchInput'
import Icon from '../../../shared/Icon'

import { getCorrectURL } from '../../../../helpers'
import { SEARCH_TEMPLATE_ID } from '../../../../constants'

import './styles.scss'

const Search = (props) => {
  const {
    setHeaderSearchStatus,
    searchStatus,
    inHeader,
    isMobile,
    searchResultPage,
    templateId,
    itemId,
  } = props

  const { state } = useContext(Store)
  const { homepage, query } = state.search
  const history = useHistory()
  const [isSearchPage, setIsSearchPage] = useState(false)
  const [withAnimations, setWithAnimations] = useState(false)
  const [searchInputActive, setSearchInputActive] = useState()
  const ref = useRef()

  const toggleSearchInput = () => {
    if (!isMobile && isSearchPage) {
      if (homepage.pathname) {
        history.push({
          pathname: getCorrectURL(homepage.pathname),
          state: {
            layoutID: homepage.id,
          },
        })
      } else {
        history.push('/')
      }
    } else {
      setHeaderSearchStatus(!searchStatus)
      setSearchInputActive(!searchInputActive)
    }
  }

  useEffect(() => {
    setWithAnimations(true)
  }, [])

  useEffect(() => {
    if (templateId === SEARCH_TEMPLATE_ID) {
      setIsSearchPage(true)
      if (!isMobile) {
        setSearchInputActive(false)
      } else {
        setSearchInputActive(true)
        setWithAnimations(false)
        setTimeout(() => setWithAnimations(true), 1000)
      }
    } else {
      setIsSearchPage(false)
    }
  }, [templateId, isMobile, setSearchInputActive])

  useEffect(() => {
    if (searchStatus) {
      setTimeout(() => disableBodyScroll(ref.current), 500)
    } else {
      setTimeout(() => enableBodyScroll(ref.current), 500)
    }
  }, [searchStatus])

  return (
    <div className="header-search-container">
      <div
        className={`header-search-icon ${
          withAnimations ? 'header-search-icon-animations' : ''
        }`}
        data-active={
          searchInputActive || (isSearchPage && !isMobile) ? true : undefined
        }
      >
        <button onClick={toggleSearchInput}>
          <div className="header-search-icon-close">
            <Icon name={'close'} size={30} color={'#000000'} />
          </div>
          <div className="header-search-icon-search">
            <Icon name={'search'} size={(18, 26)} color={'#000000'} />
          </div>
        </button>
      </div>
      <div
        className={`header-search-input ${
          withAnimations ? 'header-search-input-animations' : ''
        }`}
        data-active={searchInputActive ? true : undefined}
        ref={ref}
      >
        <SearchInput
          setFocus={searchStatus}
          inHeader={inHeader}
          setHeaderSearchStatus={setHeaderSearchStatus}
          searchStatus={searchStatus}
          setSearchInputActive={setSearchInputActive}
          searchInputActive={searchInputActive}
          searchResultPage={searchResultPage}
          isMobile={isMobile}
          queryValue={query && isMobile ? query : ''}
          itemId={itemId}
          templateId={templateId}
          isSearchPage={isSearchPage}
        />
      </div>
    </div>
  )
}

export default Search
