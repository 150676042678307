import React from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { useLastLocation } from 'react-router-last-location'

import Icon from '../Icon'

import { efo, getCorrectURL } from '../../../helpers'

import './styles.scss'

const BackToOverview = (props) => {
  const { masterCategory } = props
  const history = useHistory()
  const lastLocation = useLastLocation()
  const onBackClick = () => {
    if (lastLocation) {
      const historyUrl = lastLocation.pathname + lastLocation.search
      history.push(historyUrl)
    }
  }

  return (
    <>
      {lastLocation ? (
        <div className="back-to-overview">
          {lastLocation || !masterCategory ? (
            <button onClick={onBackClick}>
              <Icon name="back" size={18} color="#000000" />
            </button>
          ) : (
            <NavLink
              to={{
                pathname: getCorrectURL(
                  efo(masterCategory, 'fields.categoryPage.value.href')
                ),
                state: {
                  layoutID: efo(masterCategory, 'fields.categoryPage.value.id'),
                },
              }}
            >
              <Icon name="back" size={18} color="#000000" />
            </NavLink>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

export default BackToOverview
