import React, { useEffect, useRef, useCallback } from 'react'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import { useInView } from 'react-intersection-observer'

import withMinimumRequirements from '../../../withMinimumRequirements'
import Icon from '../../shared/Icon'

import {
  efo,
  isMobile,
  log,
  getModulePosition,
  pushGlEvent,
  getDataSourceName,
} from '../../../helpers'

import './styles.scss'

const ArticleBodyQuote = (props) => {
  const { fields } = props
  const { appearance, quoteText, author, authorOccupation } = fields

  const ref = useRef()
  const [inViewRefTrack, inViewTrack] = useInView({
    threshold: 0.3,
  })

  const setRefs = useCallback(
    (node) => {
      ref.current = node
      inViewRefTrack(node)
    },
    [inViewRefTrack]
  )

  useEffect(() => {
    log('Article Body Quote', { ...fields })
  }, [fields])

  // Tracking load
  useEffect(() => {
    if (typeof window !== 'undefined' && window.GDL) {
      pushGlEvent(
        false,
        'Quote',
        [],
        'Quote',
        getModulePosition('article-body-quote-container', ref),
        'Quote',
        efo(props, 'rendering.uid'),
        'Quote'
      )
    }
  }, [props])

  // Tracking in view
  useEffect(() => {
    if (inViewTrack && typeof window !== 'undefined' && window.GDL) {
      pushGlEvent(
        true,
        'Quote',
        [],
        'Quote',
        getModulePosition('article-body-quote-container', ref),
        'Quote',
        efo(props, 'rendering.uid'),
        'Quote'
      )
    }
  }, [inViewTrack, props])

  return (
    <div
      className="article-body-quote-container"
      id={getDataSourceName(efo(props, 'rendering.dataSource'))}
      ref={setRefs}
    >
      <div
        className="article-body-quote-subcontainer"
        appearance={efo(appearance, 'value')}
      >
        <div className="article-body-quote-main">
          <Text tag="h2" field={quoteText} />
          {author ? (
            <div className="article-body-quote-author">
              <Text tag="span" field={author} />
              {authorOccupation ? (
                <Text
                  tag="span"
                  field={authorOccupation}
                  className="article-body-quote-author-occupation"
                />
              ) : undefined}
            </div>
          ) : undefined}
        </div>
        <div className="article-body-quote-icon">
          <Icon
            name="quote"
            size={
              efo(appearance, 'value') === 'large'
                ? isMobile()
                  ? 35
                  : 100
                : isMobile()
                ? 35
                : 50
            }
          />
        </div>
      </div>
    </div>
  )
}

export default withMinimumRequirements(ArticleBodyQuote, [
  'fields.appearance.value',
  'fields.quoteText.value',
])
