import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { RichText, Text, Link } from '@sitecore-jss/sitecore-jss-react'
import { useTranslation } from 'react-i18next'
import { useInView } from 'react-intersection-observer'

import withMinimumRequirements from '../../../withMinimumRequirements'
import Image from '../../shared/Image'
import Icon from '../../shared/Icon'
import Drivers from '../../shared/Drivers'

import {
  efo,
  getKey,
  getMediaList,
  log,
  getModulePosition,
  pushGlEvent,
  getDataSourceName,
} from '../../../helpers'

import './styles.scss'

const getTeamStars = (team) => {
  const races = efo(team, 'fields.races') || []
  let stars = 0

  for (let race of races) {
    for (let subrace of efo(race, 'fields.races')) {
      if (efo(subrace, 'fields.star.value')) {
        stars += 1
      }
    }
  }

  return stars
}

const ArticleBodyTeamList = (props) => {
  const { fields } = props
  const { title, layout, teams } = fields
  // extract #teamId from url
  const location = useLocation()
  const teamId = location.hash.substring(1)
  const [t] = useTranslation()
  // extract teamsId in array
  const teamsIdList = teams.map((team) => team.id)

  const [teamActive, setTeamActive] = useState(undefined)
  // check if no team is click
  // receive teamId with query parameter and teamId is a valid id
  if (teamId && teamActive === undefined && teamsIdList.indexOf(teamId) !== -1)
    setTeamActive(teamId)

  const ref = useRef()
  const [inViewRefTrack, inViewTrack] = useInView({
    threshold: 0.3,
  })

  const handleClick = (key, teamActive) => {
    if (key === teamActive) {
      setTeamActive('click')
      window.history.pushState(null, '', ` `)
    } else {
      setTeamActive(key)
      // stop scrolling from the top
      window.history.pushState(null, '', `#${key}`)
    }
  }

  const setRefs = useCallback(
    (node) => {
      ref.current = node
      inViewRefTrack(node)
    },
    [inViewRefTrack]
  )

  useEffect(() => {
    log('Article Body Team List', { ...fields })
  }, [fields])

  // Tracking load
  useEffect(() => {
    if (typeof window !== 'undefined' && window.GDL) {
      pushGlEvent(
        false,
        'TeamList',
        [],
        'TeamList',
        getModulePosition('article-body-team-list-container', ref),
        'TeamList',
        efo(props, 'rendering.uid'),
        'TeamList'
      )
    }
  }, [props])

  // Tracking in view
  useEffect(() => {
    if (inViewTrack && typeof window !== 'undefined' && window.GDL) {
      pushGlEvent(
        true,
        'TeamList',
        [],
        'TeamList',
        getModulePosition('article-body-team-list-container', ref),
        'TeamList',
        efo(props, 'rendering.uid'),
        'TeamList'
      )
    }
  }, [inViewTrack, props])

  return (
    <div
      className="article-body-team-list-container"
      id={getDataSourceName(efo(props, 'rendering.dataSource'))}
      ref={setRefs}
    >
      <Text tag="h2" field={title} />
      <div className="article-body-team-list-subcontainer">
        {teams.map((team, teamIndex) => {
          const key = getKey(team.id, teamIndex)
          const logoImage = efo(team, 'fields.logo')
          const teamListHistory = efo(team, 'fields.races')

          return (
            <div
              key={key}
              className="article-body-team-list-element-container"
              data-active={key === teamActive ? '' : undefined}
            >
              <div
                className="article-body-team-list-element-header-container"
                onClick={(e) => handleClick(key, teamActive)}
              >
                <div className="article-body-team-list-element-header-image-container">
                  <Image
                    fields={{
                      image: efo(team, 'fields.nationality'),
                    }}
                    ratio="1:1"
                    mediaList={getMediaList(50)}
                  />
                </div>
                <div className="article-body-team-list-element-header-title-container">
                  <Text tag="span" field={efo(team, 'fields.name')} />
                  <Icon
                    className="arrow-button-position"
                    name={key === teamActive ? 'arrowUp' : 'arrowDown'}
                    size={(18, 18)}
                    color={'#000000'}
                  />
                  <div className="article-body-team-list-element-header-stars-container">
                    {[...new Array(getTeamStars(team))].map(
                      (nothing, starIndex) => (
                        <Icon key={starIndex} name="star" />
                      )
                    )}
                  </div>
                </div>
              </div>
              <div className="article-body-team-list-element-data-container">
                <div className="article-body-team-list-element-data-subcontainer">
                  {logoImage ? (
                    <div className="article-body-team-list-element-data-logo-container article-body-team-list-element-data-padding-container">
                      <div className="article-body-team-list-element-data-logo-subcontainer">
                        <Image
                          fields={{
                            image: efo(team, 'fields.logo'),
                          }}
                          mediaList={getMediaList(200, 400, 400, 400, 400)}
                        />
                      </div>
                    </div>
                  ) : undefined}
                  <div
                    className="article-body-team-list-element-data-drivers-container"
                    data-layout={layout.value}
                  >
                    <Drivers
                      layout={layout}
                      headline={efo(team, 'fields.driversHeadline')}
                      drivers={efo(team, 'fields.drivers', [])}
                      mini
                      disableAutoFormat
                    />
                  </div>
                  <div className="article-body-team-list-element-data-bio-container article-body-team-list-element-data-padding-container">
                    <RichText tag="div" field={efo(team, 'fields.bio')} />
                  </div>
                  {teamListHistory && teamListHistory.length ? (
                    <div className="article-body-team-list-element-data-race-container article-body-team-list-element-data-padding-container">
                      <h3>{t('team-listing-history')}</h3>
                      {efo(team, 'fields.races').map((race, raceIndex) => (
                        <div
                          className="article-body-team-list-element-data-race"
                          key={getKey(race.id, raceIndex)}
                        >
                          <h4 className="article-body-team-list-element-data-race-year">
                            {efo(race, 'fields.year.value')}
                          </h4>
                          {(efo(race, 'fields.races') || []).map(
                            (subrace, subraceIndex) => (
                              <div
                                className="article-body-team-list-element-data-race-text"
                                key={getKey(subrace.id, subraceIndex)}
                              >
                                {efo(subrace, 'fields.placement.value') && (
                                  <span className="article-body-team-list-element-data-race-placement">
                                    <Text
                                      tag="span"
                                      field={efo(subrace, 'fields.placement')}
                                    />
                                    <span className="article-body-team-list-element-data-race-placement-sep">
                                      .
                                    </span>
                                    <span>
                                      {t('race-history-race-placement')}
                                    </span>
                                  </span>
                                )}
                                <Text
                                  tag="span"
                                  className="article-body-team-list-element-data-subrace-race"
                                  field={efo(subrace, 'fields.race')}
                                />
                              </div>
                            )
                          )}
                        </div>
                      ))}
                    </div>
                  ) : undefined}
                  <div className="article-body-team-list-element-data-share-container article-body-team-list-element-data-padding-container">
                    {efo(team, 'fields.twitterURL.value.href') ? (
                      <Link
                        className="article-body-team-list-element-data-share-element"
                        field={efo(team, 'fields.twitterURL')}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Icon name="logoTwitter" />
                      </Link>
                    ) : undefined}
                    {efo(team, 'fields.facebookURL.value.href') ? (
                      <Link
                        className="article-body-team-list-element-data-share-element"
                        field={efo(team, 'fields.facebookURL')}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Icon name="logoFacebook" />
                      </Link>
                    ) : undefined}
                    {efo(team, 'fields.instagramURL.value.href') ? (
                      <Link
                        className="article-body-team-list-element-data-share-element"
                        field={efo(team, 'fields.instagramURL')}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Icon name="logoInstagram" />
                      </Link>
                    ) : undefined}
                    {efo(team, 'fields.websiteURL.value.href') ? (
                      <Link
                        className="article-body-team-list-element-data-share-element"
                        field={efo(team, 'fields.websiteURL')}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Icon name="globe" />
                      </Link>
                    ) : undefined}
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default withMinimumRequirements(ArticleBodyTeamList, [
  'fields.layout.value',
  'fields.teams',
  'fields.teams.0',
  'fields.teams.0.fields',
  'fields.teams.0.fields.name.value',
  'fields.teams.0.fields.nationality.value',
  'fields.teams.0.fields.drivers',
  'fields.teams.0.fields.drivers.0',
])
