import React, { useEffect, useRef, useCallback } from 'react'
import { useInView } from 'react-intersection-observer'

import withMinimumRequirements from '../../../withMinimumRequirements'
import Image from '../../shared/Image'
import Video from '../../shared/Video'

import {
  efo,
  log,
  getSitecoreRatio,
  getMediaList,
  getModulePosition,
  pushGlEvent,
  getDataSourceName,
} from '../../../helpers'

const ArticleHeroMedia = (props) => {
  const { fields } = props
  const {
    image,
    videourl,
    videofile,
    poster,
    aspectRatio,
    autoplay,
    loop,
    livestream,
    muted,
  } = fields
  const ref = useRef()
  const [inViewRefTrack, inViewTrack] = useInView({
    threshold: 0.3,
  })

  const setRefs = useCallback(
    (node) => {
      ref.current = node
      inViewRefTrack(node)
    },
    [inViewRefTrack]
  )

  useEffect(() => {
    log('Article Hero Media', { ...fields })
  }, [fields])

  // Tracking load
  useEffect(() => {
    if (typeof window !== 'undefined' && window.GDL) {
      pushGlEvent(
        false,
        'MediaHero',
        [],
        'MediaHero',
        getModulePosition('article-hero-media-container', ref),
        'MediaHero',
        efo(props, 'rendering.uid'),
        'MediaHero'
      )
    }
  }, [fields, props])

  // Tracking in view
  useEffect(() => {
    if (inViewTrack && typeof window !== 'undefined' && window.GDL) {
      pushGlEvent(
        true,
        'MediaHero',
        [],
        'MediaHero',
        getModulePosition('article-hero-media-container', ref),
        'MediaHero',
        efo(props, 'rendering.uid'),
        'MediaHero'
      )
    }
  }, [inViewTrack, props])

  const video =
    efo(videofile, 'value.src') ||
    efo(videourl, 'value.url') ||
    efo(videourl, 'value.href')

  return (
    <div
      className="article-hero-media-container"
      id={getDataSourceName(efo(props, 'rendering.dataSource'))}
      ref={setRefs}
    >
      {video && video.length ? (
        <Video
          UC_READY={props.UC_READY}
          UC_PROCESSOR={props.UC_PROCESSOR}
          url={video}
          image={efo(poster, 'value') || efo(image, 'value')}
          options={{
            aspectRatio: getSitecoreRatio(aspectRatio),
            autoplay: efo(autoplay, 'value'),
            loop: efo(loop, 'value'),
            muted: efo(muted, 'value'),
            livestream: efo(livestream, 'value'),
          }}
        />
      ) : (
        <Image
          fields={{
            image,
          }}
          mediaList={getMediaList()}
        />
      )}
    </div>
  )
}

export default withMinimumRequirements(ArticleHeroMedia, [
  {
    mode: 'one-of',
    structure: [
      'fields.videofile.value.src',
      'fields.videourl.value.url',
      'fields.videourl.value.href',
      'fields.image.value',
    ],
  },
])
