import React, { useEffect, useState } from 'react'

import withMinimumRequirements from '../../../withMinimumRequirements'
import Slider, { Slide as ExternalSlide } from './'

export const Slide = ExternalSlide

const SliderOnPortal = (props) => {
  const { children } = props

  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  return <>{mounted && <Slider {...props}>{children}</Slider>}</>
}

export default withMinimumRequirements(SliderOnPortal, ['children'])
