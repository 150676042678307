import React from 'react'

import Video from '../Video'

const MediaVideo = (props) => (
  <Video
    UC_READY={props.UC_READY}
    UC_PROCESSOR={props.UC_PROCESSOR}
    url={props.url || props.fileURL}
    image={props.image}
    options={{
      ...props,
    }}
    isHovering={props.isHovering}
    isTeaser
  />
)

export default MediaVideo
