import { useState, useEffect, useMemo } from 'react'
import { log } from '../helpers'

const checkApprovalConsent = (psEle, processorList) => {
  //log('+++++__useApprovalConsent->checkApprovalConsent+++++', [
  //  psEle,
  //  processorList,
  //])
  return new Promise((resolve) => {
    window.ucPrivacyShield.checkConsent({
      mapContainer: psEle,
      processorId: processorList,
      timeoutVar: {},
      callbacks: {
        success: () => {
          log(
            '+++++__useApprovalConsent->ucPrivacyShield.checkConsent::success+++++'
          )
          resolve(true)
        },
        p: undefined,
      },
    })
  })
}

const useApprovalConsent = (url, psEle, processorList, ucIsReady) => {
  //const { href: finalURL = url } = url
  const [validateConsent, setValidateConsent] = useState(false)
  const [templateId, setTemplateId] = useState(undefined)
  const [isYoutubeVideo, setIsYoutubeVideo] = useState(
    url.includes('youtube.com') || url.includes('youtu.be')
  )
  const [isVimeoVideo, setIsVimeoVideo] = useState(url.includes('vimeo.com'))

  useEffect(() => {
    //setIsYoutubeVideo(url.includes('youtube.com') || url.includes('youtu.be'))
    //setIsVimeoVideo(url.includes('vimeo.com'))

    log('+++++__useApprovalConsent::useEffect__+++++', [
      ucIsReady,
      processorList,
      psEle,
      validateConsent,
      url,
      templateId,
      isYoutubeVideo,
      isVimeoVideo,
    ])

    if (validateConsent === true) {
      return
    }

    // when it is neither a yourube nor vimeo url then resolve true as no consent is needed
    if (isYoutubeVideo === false && isVimeoVideo === false) {
      setValidateConsent(true)
      log('+++++__useApprovalConsent::useEffect::return #1', [
        isYoutubeVideo,
        isVimeoVideo,
      ])
      return true
    }

    if (
      processorList.length === 0 ||
      (!processorList[0].youtube && !processorList[0].vimeo)
    ) {
      log('+++++__useApprovalConsent::useEffect::return #2', [processorList])
      setValidateConsent(true)
      return
    }

    if (ucIsReady === false || psEle === null) {
      log('+++++__useApprovalConsent::useEffect::return #3', [ucIsReady, psEle])
      return
    }

    setTemplateId(
      isYoutubeVideo
        ? processorList[0].youtube
        : isVimeoVideo
        ? processorList[0].vimeo
        : undefined
    )

    if (templateId === undefined) {
      log('+++++__useApprovalConsent::useEffect::return #4', [templateId])
      return true
    }

    const getConsent = async () => {
      const consentSuccess = await checkApprovalConsent(psEle, [
        templateId,
      ]).then(() => {
        setValidateConsent(true)
      })
    }

    getConsent()
  }, [
    ucIsReady,
    processorList,
    psEle,
    validateConsent,
    url,
    templateId,
    isYoutubeVideo,
    isVimeoVideo,
  ])
  return useMemo(() => validateConsent, [validateConsent])
}

export default useApprovalConsent
