import React from 'react'
import PropTypes from 'prop-types'

import withMinimumRequirements from '../../../withMinimumRequirements'
import Image from '../../shared/Image'

import { getKey, getMediaList } from '../../../helpers'

import './styles.scss'

const PARTNER_THEMES = ['light', 'dark']

const Partner = (props) => {
  const { data, theme, disableLazyLoad, showSeparator } = props

  return (
    <div className="partner-container" data-theme={theme}>
      <div
        className="partner-subcontainer"
        data-show-separator={showSeparator ? '' : undefined}
      >
        {data &&
          data.map((value, i) => (
            <a
              key={getKey(value.id, i)}
              className="partner-link"
              href={value.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image
                fields={{
                  image: value.logo,
                }}
                mediaList={getMediaList(150)}
                disableLazyLoad={disableLazyLoad}
              />
            </a>
          ))}
      </div>
    </div>
  )
}

Partner.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      logo: PropTypes.object.isRequired,
    })
  ),
  theme: PropTypes.oneOf(PARTNER_THEMES),
  disableLazyLoad: PropTypes.bool,
  showSeparator: PropTypes.bool,
}

Partner.defaultProps = {
  theme: 'dark',
  disableLazyLoad: false,
  showSeparator: false,
}

export default withMinimumRequirements(Partner, [
  'data',
  'data.0',
  'data.0.link',
  'data.0.logo',
])
