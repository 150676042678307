import React from 'react'
import { useEffect } from 'react'
import { getLanguageFromLocation, efo } from '../../../helpers'

import './styles.scss'

const createPrivacyShieldSettingsJS = (ucSettings: any) => {
  // inline script to add privacy shield window object settigs

  return (
    'window.ucPrivacyShield =' +
    JSON.stringify({
      bgimage: efo(ucSettings, 'background_image.value.src') || '',
      consentunavailable: {
        text: efo(ucSettings, 'consent_unavailable_text.value'),
      },
      consentservice: {
        headline: efo(ucSettings, 'consent_service_headline.value'),
        text: efo(ucSettings, 'consent_service_text.value'),
        buttons: {
          consent: {
            label: efo(ucSettings, 'consent_accept_label.value') || '',
          },
          privacy: {
            label: efo(ucSettings, 'consent_privacy.value.text'),
            link: efo(ucSettings, 'consent_privacy.value.href') || '',
          },
        },
      },
    }) +
    ';'
  )
}

const addPrivacyShieldettings = (ucSettings: any) => {
  return new Promise((resolve, reject) => {
    const scriptID = 'privacyShieldInit'
    const scriptTag = document.getElementById(scriptID)
    // check if script exists before injecting it
    if (scriptTag) {
      return
    }
    const script = document.createElement('script')
    script.id = scriptID
    script.type = 'text/javascript'
    script.text = createPrivacyShieldSettingsJS(ucSettings)
    script.onload = resolve
    script.onerror = reject
    document.body.appendChild(script)
  })
}

const UserCentrics = (props) => {
  const ucSettings = props?.rendering?.fields

  useEffect(() => {
    // Function to load a script
    const loadScript = (src, attributes = {}) => {
      return new Promise((resolve, reject) => {
        const scriptTag = document.getElementById(attributes.id)
        // check if script exists before injecting it
        if (scriptTag) {
          return
        }
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = src

        // Set additional attributes
        Object.keys(attributes).forEach((key) => {
          script.setAttribute(key, attributes[key])
        })

        script.onload = resolve
        script.onerror = reject

        document.body.appendChild(script)
      })
    }

    // Add sitecore privacy shield settings
    addPrivacyShieldettings(ucSettings)

    // Load the UC IP bridge and SDK
    const ucSettingsID = efo(ucSettings, 'settings_id.value')
    loadScript('https://cookie.porsche.com/?settingsId=' + ucSettingsID, {
      'data-custom-sdk': true,
      id: 'ucCookieScript',
    })
      .then(() => {
        // First script has loaded, now load the second script
        const lang = (getLanguageFromLocation() || '').split('-')[0]
        return loadScript(
          'https://www.porsche.com/all/usercentrics/udg-uc-sdk.min.js',
          { language: lang, id: ucSettingsID }
        )
      })
      .catch((error) => {
        console.error('Error loading scripts:', error)
      })

    // Clean up (optional)
    return () => {}
  }, [ucSettings])

  return <div></div>
}

export default UserCentrics
