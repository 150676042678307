import React, {
  useEffect,
  useRef,
  useState,
  useContext,
  useCallback,
} from 'react'
import { withTranslation } from 'react-i18next'
import { useLocation, useHistory } from 'react-router-dom'
import { isEditorActive } from '@sitecore-jss/sitecore-jss-react'
import { useInView } from 'react-intersection-observer'

import Icon from '../../shared/Icon'
import TagList from './TagList'

import { actions, Store } from '../../../store'

import { efo, log, getModulePosition, pushGlEvent } from '../../../helpers'

import './styles.scss'

const TagSelector = withTranslation()((props) => {
  const { t, fields, params, rendering } = props
  const { countrySelectorStatus: rawCountrySelectorStatus } = params

  const { tags } = fields

  const tagList = efo(tags, 'value') || tags

  const [tagSelectorInput, setTagSelectorInput] = useState('')
  const [tagListData, setTagListData] = useState([])
  const [showShadow, setShowShadow] = useState(false)

  const location = useLocation()
  const history = useHistory()

  const inputRef = useRef()

  const { state, dispatch } = useContext(Store)
  const { tagSelectorStatus, tagListStatus } = state.header
  const { tagSelected } = state.tag

  const setTagSelected = useCallback(
    (tag) => actions.setTagSelected(dispatch, { tag }),
    [dispatch]
  )
  const setHeaderTagSelectorStatus = useCallback(
    (value) => actions.setHeaderTagSelectorStatus(dispatch, { value }),
    [dispatch]
  )
  const setHeaderTagListStatus = useCallback(
    (value) => actions.setHeaderTagListStatus(dispatch, { value }),
    [dispatch]
  )

  const countrySelectorStatus = rawCountrySelectorStatus === 'true'

  const ref = useRef()
  const [inViewRefTrack, inViewTrack] = useInView({
    threshold: 0.3,
  })

  const setRefs = useCallback(
    (node) => {
      ref.current = node
      inViewRefTrack(node)
    },
    [inViewRefTrack]
  )

  useEffect(() => {
    log('Tag Selector', { ...fields })
  }, [fields])

  useEffect(() => {
    if (inputRef && inputRef.current) {
      if (tagSelectorStatus) {
        setHeaderTagListStatus(true)
      } else {
        inputRef.current.value = ''
        setTagSelectorInput('')
        setHeaderTagListStatus(false)
      }
    }
  }, [tagSelectorStatus, setHeaderTagListStatus, setTagSelectorInput])

  useEffect(() => {
    const getSortedTags = (input) => {
      if (input === undefined || input === '') {
        return (
          tagList &&
          tagList.sort((a, b) => {
            const aText = efo(a, 'name')
            const bText = efo(b, 'name')

            if (aText < bText) {
              return -1
            }
            if (aText > bText) {
              return 1
            }
            return 0
          })
        )
      }
      const result =
        tagList &&
        tagList.filter((c) => {
          if (input.trim() === '') {
            return true // Return true to show all items when the input is empty or contains only spaces
          }
          const tagArraySplitWithSpace = efo(c, 'name').split(' ')

          if (input.includes(' ')) {
            // If input contains a space, check if the name includes the input as a whole
            return tagArraySplitWithSpace
              .join(' ')
              .toLowerCase()
              .includes(input.toLowerCase())
          } else {
            // If input does not contain a space, check for partial matches
            return tagArraySplitWithSpace.some((element) =>
              element.toLowerCase().startsWith(input.toLowerCase())
            )
          }
        })
      if (result && result.length) {
        return result
      }
      return []
    }

    setTagListData(
      getSortedTags(tagSelectorInput).filter(
        (c) => efo(c, 'hideTagFromSearch') === false
      )
    )
  }, [tagSelectorInput, tagList])

  useEffect(() => {
    const query = new URLSearchParams(
      location.search || (location.pathname && location.pathname.substr(1))
    )
    const tagName = query.get('filter')
    const tag = tagList.find((t) => efo(t, 'name') === tagName)

    if (tag) {
      setTagSelected(tag)
    } else if (!isEditorActive()) {
      setHeaderTagSelectorStatus(false)
      setTagSelected(undefined)
    }
  }, [location, tagList, setTagSelected, setHeaderTagSelectorStatus])

  useEffect(() => {
    if (showShadow && !countrySelectorStatus) {
      setTimeout(() => setShowShadow(false), 200)
    } else if (!showShadow && countrySelectorStatus) {
      setShowShadow(true)
    }
  }, [showShadow, countrySelectorStatus])

  // Tracking load
  useEffect(() => {
    let checkGDL = setInterval(() => {
      if (typeof window !== 'undefined' && !window.GDL) return
      clearInterval(checkGDL)
      if (typeof window !== 'undefined' && window.GDL) {
        pushGlEvent(
          false,
          'Search',
          [],
          'Search',
          getModulePosition('tag-selector-container', ref),
          'Search',
          efo(rendering, 'uid'),
          'Search'
        )
      }
    }, 100)
  }, [rendering])

  // Tracking in view
  useEffect(() => {
    if (inViewTrack && typeof window !== 'undefined' && window.GDL) {
      pushGlEvent(
        true,
        'Search',
        [],
        'Search',
        getModulePosition('tag-selector-container', ref),
        'Search',
        efo(rendering, 'uid'),
        'Search'
      )
    }
  }, [inViewTrack, rendering])

  return (
    <div
      className="tag-selector-container"
      ref={setRefs}
      data-shadow={showShadow ? '' : undefined}
    >
      <div className="tag-selector-shadow" />
      <div className="tag-selector-subcontainer-wrapper">
        <div
          className="tag-selector-subcontainer"
          data-selected={tagSelected ? '' : undefined}
        >
          <div className="tag-selector-icon">#</div>
          <input
            ref={inputRef}
            className="tag-selector-input"
            type="search"
            placeholder={t('tag-selector-input-placeholder')}
            disabled={!!tagSelected}
            value={
              (tagSelected && efo(tagSelected, 'name')) || tagSelectorInput
            }
            onChange={(event) => setTagSelectorInput(event.target.value)}
            onFocus={() => setHeaderTagSelectorStatus(true)}
            onBlur={() =>
              setTimeout(() => setHeaderTagSelectorStatus(false), 200)
            }
          />
          <div
            className="tag-selector-input-clear"
            data-show={
              (tagSelected || tagSelectorInput) !== '' ? '' : undefined
            }
            onClick={() => {
              const p = efo(location, 'pathname')

              if (p) {
                history.push(p)
                setHeaderTagSelectorStatus(false)
              }
            }}
          >
            <Icon name="close" size={20} />
          </div>
        </div>
      </div>
      <TagList
        tagListData={tagListData}
        tagListStatus={tagListStatus}
        setHeaderTagSelectorStatus={setHeaderTagSelectorStatus}
        setTagSelectorInput={setTagSelectorInput}
        setHeaderTagListStatus={setHeaderTagListStatus}
        searchInput={tagSelectorInput}
        searchResultLength={tagListData.length}
      />
    </div>
  )
})

export default TagSelector
