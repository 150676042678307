import React, { useContext, useEffect, useRef, useState } from 'react'
import { Placeholder, Text } from '@sitecore-jss/sitecore-jss-react'
import { useInView } from 'react-intersection-observer'

import withMinimumRequirements from '../../../withMinimumRequirements'

import { actions, Store } from '../../../store'
import { getKey, efo, log, getDataSourceName } from '../../../helpers'

import './styles.scss'

const getSections = (sections) => sections.filter((section) => !!section.fields)

const ArticleBodyScrollSectionsRouter = (props) => {
  const { rendering, active: activeList, inViewContainer, setMove } = props
  const active = activeList[activeList.length - 1]

  const sections = efo(
    rendering,
    'placeholders.jss-motorsport-article-body-scroll-section-items'
  )

  useEffect(() => {
    log('Article Body Scroll Sections > Router', { ...rendering })
  }, [rendering])

  if (!sections) {
    return null
  }

  return (
    <div className="article-body-scroll-sections-route-container">
      <div className="article-body-scroll-sections-route-subcontainer">
        <div className="article-body-scroll-sections-route-sepleft" />
        {getSections(sections).map((section, index) => (
          <ArticleBodyScrollSectionsRouterElement
            key={getKey(section.uid, index)}
            id={getKey(section.uid, index)}
            section={section}
            active={active}
            setMove={setMove}
            inViewContainer={inViewContainer}
            last={getSections(sections).length === index + 1}
          />
        ))}
        <div className="article-body-scroll-sections-route-sepright" />
      </div>
    </div>
  )
}

const ArticleBodyScrollSectionsRouterElement = (props) => {
  const { id, section, active, setMove, inViewContainer, last } = props

  const elementRef = useRef()

  const [width, setWidth] = useState(0)
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    if (inViewContainer && elementRef && elementRef.current) {
      const { width } = elementRef.current.getBoundingClientRect()

      setWidth(width)
    }
  }, [inViewContainer])

  useEffect(() => {
    let timeout

    if (!isActive && active === id) {
      if (elementRef && elementRef.current) {
        elementRef.current.scrollIntoView()
      }

      timeout = setTimeout(() => setIsActive(true), 100)
    } else if (isActive && active !== id) {
      setIsActive(false)
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [id, active, isActive])

  return (
    <div
      ref={elementRef}
      className="article-body-scroll-sections-route-element"
      data-last={last ? '' : undefined}
      onClick={() => setMove(id)}
    >
      <Text tag="span" field={efo(section, 'fields.title')} />
      <div
        className="article-body-scroll-sections-route-active-container"
        style={{
          maxWidth: `${isActive ? width : 0}px`,
        }}
      />
    </div>
  )
}

const ArticleBodyScrollSections = (props) => {
  const { rendering } = props

  const [inViewRef, inView] = useInView()

  const { dispatch } = useContext(Store)

  const [active, setActive] = useState([])
  const [move, setMove] = useState(undefined)

  useEffect(() => {
    actions.setHeaderSticky(dispatch, { value: !inView })
  }, [inView, dispatch])

  return (
    <div
      ref={inViewRef}
      className="article-body-scroll-sections-container"
      id={getDataSourceName(efo(props, 'rendering.dataSource'))}
    >
      <ArticleBodyScrollSectionsRouter
        rendering={rendering}
        active={active}
        inViewContainer={inView}
        setActive={setActive}
        setMove={setMove}
      />
      <Placeholder
        UC_READY={props.UC_READY}
        UC_PROCESSOR={props.UC_PROCESSOR}
        name="jss-motorsport-article-body-scroll-section-items"
        rendering={rendering}
        params={{
          active,
          move,
          setActive,
          setMove,
        }}
      />
    </div>
  )
}

export default withMinimumRequirements(ArticleBodyScrollSections, [
  'rendering.placeholders.jss-motorsport-article-body-scroll-section-items.0',
])
