import { useState, useEffect, useMemo } from 'react'
import { log } from '../helpers'

const checkApprovalConsent = (processorList, psEle) => {
  return new Promise((resolve) => {
    window.ucPrivacyShield.checkConsent({
      mapContainer: psEle,
      processorId: processorList,
      timeoutVar: {},
      callbacks: {
        success: () => {
          resolve(true)
        },
        p: undefined,
      },
    })
  })
}

const usePrivacyShield = (processorList, psEle, ucIsReady) => {
  const [validateConsent, setValidateConsent] = useState(false)

  useEffect(() => {
    log('+++++__usePrivacyShield::useEffect__+++++', [
      processorList,
      psEle,
      ucIsReady,
    ])

    if (processorList.length === 0) {
      setValidateConsent(true)
      return
    }

    if (ucIsReady === false || psEle === null || !processorList.length) {
      return
    }

    const getConsent = async () => {
      const consentSuccess = await checkApprovalConsent(
        processorList,
        psEle
      ).then(() => {
        log('+++++__usePrivacyShield::useEffect::getConsent::then__+++++')
        setValidateConsent(consentSuccess)
      })
    }

    getConsent()
  }, [ucIsReady, processorList, psEle, validateConsent])
  return useMemo(() => validateConsent, [validateConsent])
}

export default usePrivacyShield
