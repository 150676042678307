import React from 'react'

import './styles.scss'

const Loading = (props) => {
  const { size } = props
  return (
    <div className="loader-container">
      <div
        className="loader"
        style={{ width: size + 'px', height: size + 'px' }}
      >
        <svg className="loader-circular" viewBox="25 25 50 50">
          <circle
            className="loader-path"
            cx="50"
            cy="50"
            r="20"
            fill="none"
            stroke="#d5001c"
            strokeWidth="2"
          />
        </svg>
      </div>
    </div>
  )
}

export default Loading
