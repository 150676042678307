import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'

const NotFound = () => {
  const [mount, setMount] = useState(false)

  useEffect(() => {
    setMount(true)
  }, [])

  if (mount) {
    return <Redirect to="/404" />
  }

  return <div />
}

export default NotFound
