import React, {
  useEffect,
  useContext,
  useState,
  useRef,
  useCallback,
} from 'react'

import { useInView } from 'react-intersection-observer'

import withMinimumRequirements from '../../../withMinimumRequirements'
import Slider, { Slide } from '../../shared/Slider'
import Image from '../../shared/Image'
import Icon from '../../shared/Icon'
import { Portal } from '../../shared/Modal'

import { actions, Store } from '../../../store'
import {
  getKey,
  efo,
  log,
  isMobile,
  getMediaList,
  getModulePosition,
  pushGlEvent,
  isPortrait,
  getDataSourceName,
} from '../../../helpers'

import './styles.scss'

const Gallery = (props) => {
  const { images, index, allow, modal, setShowModal } = props

  const { dispatch } = useContext(Store)

  const [portrait, setPortrait] = useState(isPortrait())

  useEffect(() => {
    const handleResize = () => {
      setPortrait(isPortrait())
    }
    typeof window !== 'undefined' &&
      window.addEventListener('resize', handleResize)
    return () => {
      typeof window !== 'undefined' &&
        window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <Slider
      pagination
      index={index}
      slidesPerView={modal ? 1 : undefined}
      slidesMargin={18}
    >
      {(images || []).map((image = {}, i) => (
        <Slide key={getKey(image.id, i)}>
          <div
            className="article-body-gallery-slide"
            onClick={() => {
              if (allow) {
                actions.setModalShow(dispatch, {
                  index: i,
                })
                setShowModal(true)
              }
            }}
          >
            {image && (
              <Image
                className="article-body-gallery-image"
                fields={{
                  image: efo(image, 'fields.image') || {
                    value: image,
                  },
                }}
                mediaList={!modal ? getMediaList(400) : undefined}
                thumb={image.base64String}
                cover={!modal ? false : portrait ? false : true}
              />
            )}
          </div>
        </Slide>
      ))}
    </Slider>
  )
}

const Modal = (props = {}) => {
  const { setShowModal } = props

  const { dispatch } = useContext(Store)

  return (
    <>
      <div className="article-body-gallery-modal-close-container">
        <div
          className="article-body-gallery-modal-close-icon"
          onClick={() => {
            setShowModal(false)
            actions.setModalHide(dispatch)
          }}
        >
          <Icon name="close" size={isMobile ? 20 : 25} />
        </div>
      </div>
      <div className="article-body-gallery-modal-container">
        <Gallery {...props} modal />
      </div>
    </>
  )
}

const ArticleBodyGallery = (props) => {
  const { fields } = props
  const { images, allowImageFullScreen } = fields

  const { state } = useContext(Store)
  const { data: modalData } = state.modal

  const [showModal, setShowModal] = useState(false)

  const allow = efo(allowImageFullScreen, 'value')

  const ref = useRef()
  const [inViewRefTrack, inViewTrack] = useInView({
    threshold: 0.3,
  })

  const setRefs = useCallback(
    (node) => {
      ref.current = node
      inViewRefTrack(node)
    },
    [inViewRefTrack]
  )

  useEffect(() => {
    log('Article Body Gallery', { ...fields })
  }, [fields])

  // Tracking load
  useEffect(() => {
    if (typeof window !== 'undefined' && window.GDL) {
      pushGlEvent(
        false,
        'Gallery',
        [],
        'Gallery',
        getModulePosition('article-body-gallery-container', ref),
        'Gallery',
        efo(props, 'rendering.uid'),
        'Gallery'
      )
    }
  }, [props])

  // Tracking in view
  useEffect(() => {
    if (inViewTrack && typeof window !== 'undefined' && window.GDL) {
      pushGlEvent(
        true,
        'Gallery',
        [],
        'Gallery',
        getModulePosition('article-body-gallery-container', ref),
        'Gallery',
        efo(props, 'rendering.uid'),
        'Gallery'
      )
    }
  }, [inViewTrack, props])

  return (
    <div
      className="article-body-gallery-container"
      data-allow-fullscreen={allow ? '' : undefined}
      ref={setRefs}
      id={getDataSourceName(efo(props, 'rendering.dataSource'))}
    >
      <Gallery images={images} allow={allow} setShowModal={setShowModal} />
      {allow && modalData && showModal ? (
        <Portal>
          <Modal
            images={images}
            index={modalData.index}
            setShowModal={setShowModal}
          />
        </Portal>
      ) : undefined}
    </div>
  )
}

export default withMinimumRequirements(ArticleBodyGallery, [
  'fields.images',
  'fields.images.0',
  {
    mode: 'one-of',
    structure: ['fields.images.0.fields.image.value', 'fields.images.0.src'],
  },
])
