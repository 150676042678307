import React, { useEffect, useRef, useCallback } from 'react'
import { useInView } from 'react-intersection-observer'

import withMinimumRequirements from '../../../withMinimumRequirements'
import Countdown from '../../shared/Countdown'

import {
  efo,
  log,
  getModulePosition,
  pushGlEvent,
  getDataSourceName,
} from '../../../helpers'

const ArticleHeroCountdown = (props) => {
  const { fields } = props
  const {
    image,
    title,
    startDate,
    endDate,
    partner,
    subtitle,
    eventTitle,
    eventDescription,
    eventLocation,
    showSeconds,
  } = fields
  const ref = useRef()
  const [inViewRefTrack, inViewTrack] = useInView({
    threshold: 0.3,
  })

  const setRefs = useCallback(
    (node) => {
      ref.current = node
      inViewRefTrack(node)
    },
    [inViewRefTrack]
  )

  useEffect(() => {
    log('Article Hero Countdown', { ...fields })
  }, [fields])

  // Tracking load
  useEffect(() => {
    if (typeof window !== 'undefined' && window.GDL) {
      pushGlEvent(
        false,
        'CountdownHero',
        [],
        'CountdownHero',
        getModulePosition('countdown-hero', ref),
        'CountdownHero',
        efo(props, 'rendering.uid'),
        efo(fields, 'title.value')
      )
    }
  }, [fields, props])

  // Tracking in view
  useEffect(() => {
    if (inViewTrack && typeof window !== 'undefined' && window.GDL) {
      pushGlEvent(
        true,
        'CountdownHero',
        [],
        'CountdownHero',
        getModulePosition('countdown-hero', ref),
        'CountdownHero',
        efo(props, 'rendering.uid'),
        efo(fields, 'title.value')
      )
    }
  }, [inViewTrack, fields, props])

  return (
    <div
      className="countdown-hero"
      id={getDataSourceName(efo(props, 'rendering.dataSource'))}
      ref={setRefs}
    >
      <Countdown
        poster={
          efo(image, 'value.src')
            ? {
                field: image,
                value: efo(image, 'value'),
                ...efo(image, 'value'),
              }
            : undefined
        }
        title={efo(title, 'value')}
        startDate={efo(startDate, 'value')}
        endDate={efo(endDate, 'value')}
        countdownPartner={
          efo(partner, 'value.src')
            ? {
                field: partner,
                value: efo(partner, 'value'),
                ...efo(partner, 'value'),
              }
            : undefined
        }
        countdownSubtitle={efo(subtitle, 'value')}
        eventTitle={efo(eventTitle, 'value')}
        eventDescription={efo(eventDescription, 'value')}
        eventLocation={efo(eventLocation, 'value')}
        showSeconds={efo(showSeconds, 'value')}
        isArticleHero
      />
    </div>
  )
}

export default withMinimumRequirements(ArticleHeroCountdown, [
  'fields.image.value',
  'fields.startDate.value',
])
