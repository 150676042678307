import React, { useContext } from 'react'

import withMinimumRequirements from '../../../withMinimumRequirements'
import Video from '../Video'

import { Store } from '../../../store'

const MediaVideoSeries = (props) => {
  const { id, videos: videoSeries } = props

  const { state } = useContext(Store)
  const { videoSeriesSelected } = state.teaser

  const video = videoSeriesSelected[id] || videoSeries[0]

  return (
    <Video
      UC_READY={props.UC_READY}
      UC_PROCESSOR={props.UC_PROCESSOR}
      url={video.url || video.videoURL}
      image={video.image}
      options={{
        ...video,
      }}
      isTeaser
    />
  )
}

export default withMinimumRequirements(MediaVideoSeries, ['videos', 'videos.0'])
