import React, { useEffect, useRef } from 'react'
import { NavLink } from 'react-router-dom'
import { withTranslation, useTranslation } from 'react-i18next'

import { getKey, efo, log, getCorrectURL } from '../../../../helpers'

import './styles.scss'

const TagList = withTranslation()((props) => {
  const {
    tagListData,
    tagListStatus,
    setHeaderTagSelectorStatus,
    setTagSelectorInput,
    setHeaderTagListStatus,
    searchInput,
    searchResultLength,
  } = props
  const [t] = useTranslation()
  const tagListContainerRef = useRef()

  useEffect(() => {
    log('Tag List', { tagListData: { ...tagListData }, tagListStatus })
  }, [tagListData, tagListStatus])

  return (
    <div className="tag-list-wrapper">
      <div
        className="tag-list-container"
        data-active={tagListStatus && tagListData.length ? '' : undefined}
      >
        <div ref={tagListContainerRef} className="tag-list-subcontainer">
          {tagListData &&
            tagListData.map((c, i) => (
              <NavLink
                key={getKey(c.id, i)}
                to={getCorrectURL(`/?filter=${efo(c, 'name')}`)}
                onClick={() => {
                  setTagSelectorInput('')
                  setHeaderTagListStatus(false)
                  setHeaderTagSelectorStatus(false)
                }}
              >
                {efo(c, 'name')}
              </NavLink>
            ))}
        </div>
      </div>
      {searchInput && !searchResultLength && (
        <div className="tag-list-wrapper">
          <div className="tag-list-container" data-active={true}>
            <div className="tag-list-subcontainer-not-found">
              <NavLink to="#" onClick={(e) => e.preventDefault()}>
                {t('tag-search-no-results')}
              </NavLink>
            </div>
          </div>
        </div>
      )}
    </div>
  )
})

export default TagList
