import React, { useEffect, useState } from 'react'
import Slider, { Slide } from '../../shared/Slider'
import Image from '../../shared/Image'
import Button from '../../shared/Button'
import Loading from '../../shared/Loading'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import withMinimumRequirements from '../../../withMinimumRequirements'
import { efo, log, getMediaList, isMobile } from '../../../helpers'

import './styles.scss'

const HomeHighlightSlide = (props) => {
  const { fields } = props

  const { alignment, headline, link, image, mobileImage } = fields

  const [mobile, setMobile] = useState(isMobile())

  const setCustomVh = () => {
    if (typeof window !== 'undefined') {
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }
  }

  useEffect(() => {
    const checkMobile = () => {
      setMobile(isMobile())
    }
    setCustomVh()
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', setCustomVh)
      window.addEventListener('resize', checkMobile)
    }
    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', checkMobile)
        window.removeEventListener('resize', setCustomVh)
      }
    }
  }, [])

  return (
    <>
      <Image
        className="home-highlight-image"
        fields={{
          image: mobile ? mobileImage : image,
        }}
        mediaList={getMediaList()}
        cover={true}
      />
      <div
        className={`home-highlight-text-container home-highlight-text-container-${efo(
          alignment,
          'value'
        )}`}
      >
        <div
          className={`home-highlight-text-featured ${
            !efo(link, 'value.text') && 'home-highlight-text-featured-nobutton'
          }`}
        >
          <Text tag="h2" className="home-highlight-tit" field={headline} />
          {efo(link, 'value') && efo(link, 'value.href') && (
            <div className="home-highlight-button-container">
              <Button
                text={efo(link, 'value.text')}
                linkHref={efo(link, 'value.href')}
                linkAnchor={efo(link, 'value.anchor')}
                linkId={efo(link, 'value.id')}
                iconName={'buttonarrow'}
                iconWith={8}
                iconHeight={16}
              ></Button>
            </div>
          )}
          <div className="home-highlight-text-border horizontal top"></div>
          <div className="home-highlight-text-border vertical top"></div>
          <div className="home-highlight-text-border horizontal bottom"></div>
          <div className="home-highlight-text-border vertical bottom"></div>
        </div>
      </div>
      <div
        className={`home-highlight-image-overlay home-highlight-image-overlay-${efo(
          alignment,
          'value'
        )}`}
      />
      <div className="home-highlight-navigation-shadow"></div>
    </>
  )
}

const HomeHighlight = (props) => {
  const {
    fields, // This is the parameter containing data from Sitecore
  } = props
  const [swiper, setSwiper] = useState(null)
  const [slideIndex, setSlideIndex] = useState(0)
  const [loading, setLoading] = useState(true)
  const [loadingAnimation, setLoadingAnimation] = useState(false)

  useEffect(() => {
    log('Home Highlight', { ...fields })
  }, [fields])

  useEffect(() => {
    if (swiper !== null && loading) {
      setLoadingAnimation(true)
    }
  }, [swiper, loading])

  if (typeof fields === 'undefined') return null

  const { articleItems } = fields

  const slideTo = (index) => {
    if (swiper) {
      swiper.slideTo(index)
      setSlideIndex(index)
    }
  }

  const navigationClass = (index) => {
    if (index === slideIndex) {
      return 'active'
    } else {
      return ''
    }
  }

  const handleLoadingAnimationEnd = () => {
    setLoading(false)
  }

  return (
    <section className="home-highlight-container">
      <Slider
        onSwiper={setSwiper}
        slidesPerView={1}
        speed={1200}
        loop={false}
        slidesMargin={0}
        simulateTouch={false}
        autoplay={{
          delay: 7000,
        }}
        keyboard={{
          enabled: false,
          onlyInViewport: false,
        }}
        onSlideChange={(e) => {
          if (swiper) {
            setSlideIndex(swiper.realIndex)
          }
        }}
      >
        {fields &&
          (articleItems || []).map((article, index) => (
            <Slide key={index}>
              <HomeHighlightSlide fields={article.fields} />
            </Slide>
          ))}
      </Slider>
      <div className="home-highlight-navigation-container">
        <div className="home-highlight-navigation">
          <div className="home-highlight-navigation-items">
            {articleItems.map((article, index) => (
              <button
                key={index}
                onClick={(event) => slideTo(index)}
                tabIndex={index}
                className={`home-highlight-navigation-bullet ${navigationClass(
                  index
                )}`}
              >
                <Text
                  tag="span"
                  className="home-highlight-navigation-bullet-text"
                  field={efo(article, 'fields.headline')}
                />
              </button>
            ))}
          </div>
        </div>
      </div>
      {loading && (
        <div
          className={`home-highlight-loading ${
            loadingAnimation ? 'home-highlight-loading-animation' : ''
          }`}
          onAnimationEnd={handleLoadingAnimationEnd}
        >
          <Loading size={100} />
        </div>
      )}
    </section>
  )
}

export default withMinimumRequirements(HomeHighlight, ['fields.articleItems'])
