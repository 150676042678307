import React, { useContext, useState, useEffect } from 'react'

import MediaImage from './MediaImage'
import Icon from '../Icon'
import Slider, { Slide } from '../Slider'
import { Portal } from '../Modal'

import { actions, Store } from '../../../store'
import { isMobile, isPortrait } from '../../../helpers'

const Gallery = ({
  images,
  image,
  fullScreenIndex,
  title,
  allowImageFullScreen,
  showModal,
  setShowModal,
  id,
}) => {
  const { dispatch } = useContext(Store)

  const [portrait, setPortrait] = useState(isPortrait())

  useEffect(() => {
    const handleResize = () => {
      setPortrait(isPortrait())
    }
    typeof window !== 'undefined' &&
      window.addEventListener('resize', handleResize)
    return () => {
      typeof window !== 'undefined' &&
        window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <Slider
      className="teaser-slider"
      index={fullScreenIndex}
      pagination
      autoHeight={showModal ? false : true}
    >
      {(images ? images : image ? [image] : []).map((image, i) => (
        <Slide key={image + i}>
          <div
            className="teaser-slide-container"
            data-allow-full-screen={
              allowImageFullScreen && !showModal ? '' : undefined
            }
            onClick={() => {
              if (allowImageFullScreen && !showModal) {
                actions.setModalShow(
                  dispatch,
                  {
                    fullScreenIndex: i,
                  },
                  { id: id }
                )
                setShowModal(true)
              }
            }}
          >
            <MediaImage
              image={image}
              title={title}
              modal={showModal}
              cover={!showModal ? false : portrait ? false : true}
            />
          </div>
        </Slide>
      ))}
    </Slider>
  )
}

const Modal = (props = {}) => {
  const { setShowModal } = props

  const { dispatch } = useContext(Store)

  return (
    <>
      <div className="teaser-gallery-modal-close-container">
        <div
          className="teaser-gallery-modal-close-icon"
          onClick={() => {
            setShowModal(false)
            actions.setModalHide(dispatch)
          }}
        >
          <Icon name="close" size={isMobile ? 20 : 25} />
        </div>
      </div>
      <div className="teaser-gallery-modal-container">
        <Gallery {...props} modal />
      </div>
    </>
  )
}

const MediaGallery = (props) => {
  const { allowImageFullScreen, id } = props

  const { state } = useContext(Store)
  const { data: modalData } = state.modal
  const { show } = state.modal

  const [showModal, setShowModal] = useState(false)

  return (
    <div
      className="teaser-gallery-container"
      data-allow-fullscreen={allowImageFullScreen ? '' : undefined}
    >
      <Gallery {...props} showModal={showModal} setShowModal={setShowModal} />
      {allowImageFullScreen && modalData && show.id === id ? (
        <Portal>
          <Modal
            {...props}
            fullScreenIndex={modalData.fullScreenIndex}
            showModal={showModal}
            setShowModal={setShowModal}
          />
        </Portal>
      ) : undefined}
    </div>
  )
}

export default MediaGallery
