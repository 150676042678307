import React, { useEffect, useRef, useCallback } from 'react'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import { useHistory } from 'react-router-dom'
import { useInView } from 'react-intersection-observer'

import withMinimumRequirements from '../../../withMinimumRequirements'
import Image from '../../shared/Image'
import Icon from '../../shared/Icon'

import {
  navigate,
  log,
  efo,
  getTable,
  getMediaList,
  getModulePosition,
  pushGlEvent,
  getDataSourceName,
} from '../../../helpers'

import './styles.scss'

const ArticleBodyVehicleUSP = (props) => {
  const { fields } = props
  const { headline, specs, image, buttonURL, buttonText } = fields

  const history = useHistory()

  const ref = useRef()
  const [inViewRefTrack, inViewTrack] = useInView({
    threshold: 0.3,
  })

  const setRefs = useCallback(
    (node) => {
      ref.current = node
      inViewRefTrack(node)
    },
    [inViewRefTrack]
  )

  useEffect(() => {
    log('Article Body Vehicle USP', { ...fields })
  }, [fields])

  // Tracking load
  useEffect(() => {
    if (typeof window !== 'undefined' && window.GDL) {
      pushGlEvent(
        false,
        'VehicleUSP',
        [],
        'VehicleUSP',
        getModulePosition('article-body-vehicle-usp-container', ref),
        'VehicleUSP',
        efo(props, 'rendering.uid'),
        'VehicleUSP'
      )
    }
  }, [props])

  // Tracking in view
  useEffect(() => {
    if (inViewTrack && typeof window !== 'undefined' && window.GDL) {
      pushGlEvent(
        true,
        'VehicleUSP',
        [],
        'VehicleUSP',
        getModulePosition('article-body-vehicle-usp-container', ref),
        'VehicleUSP',
        efo(props, 'rendering.uid'),
        'VehicleUSP'
      )
    }
  }, [inViewTrack, props])

  return (
    <div
      className="article-body-vehicle-usp-container"
      id={getDataSourceName(efo(props, 'rendering.dataSource'))}
      ref={setRefs}
    >
      <div className="article-body-vehicle-usp-subcontainer">
        {headline ? <Text tag="h2" field={headline} /> : undefined}
        <div className="article-body-vehicle-usp-body-container">
          <div className="article-body-vehicle-usp-data-container">
            {getTable(specs).map((tableData, index) => (
              <div
                key={index}
                className="article-body-vehicle-usp-data-spec-container"
              >
                <Text tag="span" field={tableData.description} />
                <Text tag="span" field={tableData.text} />
              </div>
            ))}
            {buttonURL && buttonText ? (
              <div className="article-body-vehicle-usp-data-button-container">
                <div
                  className="article-body-vehicle-usp-data-button-subcontainer"
                  onClick={() => {
                    navigate(
                      `${buttonURL.value.href}${
                        efo(buttonURL, 'value.anchor')
                          ? '#' + efo(buttonURL, 'value.anchor')
                          : ''
                      }`,
                      history
                    )
                  }}
                >
                  <Icon name="arrowHeadRight" />
                  <Text tag="span" field={buttonText} />
                </div>
              </div>
            ) : undefined}
          </div>
          <div className="article-body-vehicle-usp-desktop-image-container">
            <div className="article-body-vehicle-usp-desktop-image-subcontainer">
              <Image
                fields={{
                  image,
                }}
                mediaList={getMediaList()}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="article-body-vehicle-usp-mobile-image-container">
        <Image
          fields={{
            image,
          }}
          mediaList={getMediaList(400)}
        />
      </div>
    </div>
  )
}

export default withMinimumRequirements(ArticleBodyVehicleUSP, [
  'fields.image.value',
  'fields.specs.value',
])
